<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <q-form ref="formArticle" class="q-gutter-md" @submit="onSubmitForm">
      <div class="q-mt-md" style="display: flex; flex-direction: row">
        <q-tabs
          v-if="!splitMode"
          v-model="tabArticleContent"
          align="left"
          active-color="primary"
          indicator-color="primary"
          dense
          narrow-indicator
          inline-label
        >
          <q-tab
            v-for="(
              articleContent, indexArticleContent
            ) in articleForm.contents"
            :key="'tab' + indexArticleContent"
            :label="$t(`languages.${articleContent.language}`)"
            :name="articleContent.language"
            :icon="iconForLanguage(articleContent.language)"
          >
            <q-tooltip
              v-if="iconForLanguage(articleContent.language) == 'lock'"
            >
              {{ lockTooltip(languagesLocked[articleContent.language]) }}
            </q-tooltip>
          </q-tab>
        </q-tabs>
        <q-space />

        <q-btn
          v-if="canSendMail"
          :label="$q.screen.lt.md ? '' : $t('article.labels.btnGenerateEmail')"
          color="primary"
          flat
          icon="mail"
          @click="generateEmail"
        >
          <q-tooltip v-if="sendingInfos.nbSendings > 0">
            {{
              $t("article.labels.btnGenerateMailToolTip", {
                dateSend: sendingInfos.tooltipInfo,
              })
            }}
          </q-tooltip>
          <q-badge color="red" class="q-badge--left" transparent floating>{{
            sendingInfos.nbSendings
          }}</q-badge>
        </q-btn>
        <q-btn
          v-if="canDuplicate"
          :label="$q.screen.lt.md ? '' : $t('article.labels.btnDuplicate')"
          color="primary"
          flat
          icon="file_copy"
        >
          <q-menu>
            <q-list style="min-width: 100px">
              <q-item
                v-for="(option, idx) in publications"
                :key="'publication' + idx"
                v-close-popup
                :disable="articleForm.publication === option.id"
                clickable
                @click="duplicate(option.id)"
              >
                <q-item-section>{{ option.name }}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <q-btn
          v-if="articleForm.contents.length == 2"
          :label="$t('article.labels.btnSplitView')"
          flat
          icon="horizontal_distribute"
          class="mobile-hide"
          @click="splitMode = !splitMode"
        />
        <q-btn
          v-show="!isHistory && !splitMode"
          color="primary"
          flat
          icon="print"
          @click="print"
          ><q-tooltip>{{ $t("preview.labels.btnPrint") }}</q-tooltip></q-btn
        >
        <q-btn
          v-show="!isHistory && !splitMode"
          color="primary"
          flat
          icon="visibility"
          @click="preview"
          ><q-tooltip>{{ $t("article.labels.btnPreview") }}</q-tooltip></q-btn
        >
        <div v-if="canAddLang">
          <q-btn-dropdown color="primary" icon="translate" flat>
            <q-list>
              <q-item
                v-close-popup
                clickable
                :disable="!!messageLangDisabled"
                @click="showDialogAddLanguage = true"
              >
                <q-item-section>
                  <q-item-label>{{
                    $t("article.labels.btnAddLang")
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-tooltip v-if="messageLangDisabled" max-width="200px">{{
                messageLangDisabled
              }}</q-tooltip>
              <q-item
                v-close-popup
                clickable
                :disable="!!messageLangDisabled"
                @click="changeMainLanguage"
              >
                <q-item-section>
                  <q-item-label>{{
                    $t("article.labels.switchMainLanguage")
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <q-tooltip max-width="200px">{{
            $t("article.labels.manageLanguage")
          }}</q-tooltip>
        </div>
      </div>
      <q-separator class="q-mt-none" />
      <q-tab-panels v-if="!splitMode" v-model="tabArticleContent" animated>
        <q-tab-panel
          v-for="(articleContent, indexArticleContent) in articleForm.contents"
          :key="'tabPanel' + indexArticleContent"
          :name="articleContent.language"
          class="q-px-none q-px-md-md"
        >
          <FormArticleContent
            :default-article-content="articleContent"
            :publication="articleForm.publication"
            :readonly="!!languagesLocked[articleContent.language]"
            :show-comment-sidebar="!splitMode"
          />
        </q-tab-panel>
      </q-tab-panels>

      <q-splitter v-else v-model="splitterModel">
        <template #before>
          <q-card :class="!Dark.isActive ? 'bg-grey-2' : null">
            <q-card-section>
              <q-chip
                square
                outline
                color="primary"
                class="q-mb-lg bg-white"
                :icon="iconForLanguage(articleForm.contents[0].language)"
              >
                {{ $t(`languages.${articleForm.contents[0].language}`) }}
                <q-tooltip
                  v-if="
                    iconForLanguage(articleForm.contents[0].language) == 'lock'
                  "
                >
                  {{
                    $t(
                      `article.locks.reasons.${
                        languagesLocked[articleForm.contents[0].language]
                      }`
                    )
                  }}
                </q-tooltip>
              </q-chip>
              <div
                class="alert-important-left"
                :style="positionAlertBannerLeft"
              >
                <q-banner
                  v-if="
                    !isHistory &&
                    isVersionObsolete[articleForm.contents[0].language] &&
                    getLastModificationIdAuthor != authService.currentUser?.id
                  "
                  class="q-mb-lg bg-red text-white"
                  @click="refreshArticle"
                >
                  <template #avatar>
                    <q-icon name="error" color="white" />
                  </template>
                  <span style="font-size: 20px">{{
                    titleCartoucheVersionModified
                  }}</span>
                </q-banner>
              </div>
              <FormArticleContent
                :default-article-content="articleForm.contents[0]"
                :publication="articleForm.publication"
                :readonly="!!languagesLocked[articleForm.contents[0].language]"
                :show-comment-sidebar="!splitMode"
                :split-mode="splitMode"
              />
            </q-card-section>
          </q-card>
        </template>

        <template #separator>
          <q-avatar
            color="primary"
            text-color="white"
            size="40px"
            icon="drag_indicator"
          />
        </template>

        <template #after>
          <q-card :class="!Dark.isActive ? 'bg-grey-2' : null">
            <q-card-section>
              <q-chip
                square
                outline
                color="primary"
                class="q-mb-lg bg-white"
                :icon="iconForLanguage(articleForm.contents[1].language)"
              >
                {{ $t(`languages.${articleForm.contents[1].language}`) }}
                <q-tooltip
                  v-if="
                    iconForLanguage(articleForm.contents[1].language) == 'lock'
                  "
                >
                  {{
                    lockTooltip(
                      languagesLocked[articleForm.contents[1].language]
                    )
                  }}
                </q-tooltip>
              </q-chip>
              <div class="alert-important-right">
                <q-banner
                  v-if="
                    !isHistory &&
                    isVersionObsolete[articleForm.contents[1].language] &&
                    getLastModificationIdAuthor != authService.currentUser?.id
                  "
                  class="q-mb-lg bg-red text-white"
                  @click="refreshArticle"
                >
                  <template #avatar>
                    <q-icon name="error" color="white" />
                  </template>
                  <span style="font-size: 20px">{{
                    titleCartoucheVersionModified
                  }}</span>
                </q-banner>
              </div>
              <FormArticleContent
                :default-article-content="articleForm.contents[1]"
                :publication="articleForm.publication"
                :readonly="!!languagesLocked[articleForm.contents[1].language]"
                :show-comment-sidebar="!splitMode"
                :split-mode="splitMode"
              />
            </q-card-section>
          </q-card>
        </template>
      </q-splitter>
    </q-form>
    <DialogAddLanguage
      v-model="showDialogAddLanguage"
      :article="articleForm"
      @add:language="onNewLanguage"
    />
  </div>
</template>
<script lang="ts" setup>
import type { PropType } from "vue";
import type { QNotifyCreateOptions } from "quasar";
import { useQuasar, EventBus, date, Dark } from "quasar";
import {
  ref,
  onMounted,
  onUnmounted,
  onUpdated,
  inject,
  computed,
  reactive,
  watch,
} from "vue";
import { storeToRefs } from "pinia";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { ArticleDto, RightReference } from "@/types/api";
import { createArticle, articleService } from "@/services/ArticleService";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { i18n } from "@/i18n";
import { FrenchLanguage, EnglishLanguage } from "@/constants";

// Components
import FormArticleContent from "@/components/Article/FormArticleContent.vue";
import DialogAddLanguage from "@/components/Article/DialogAddLanguage.vue";

import { useFormArticle } from "@/composables/useFormArticle";
import {
  getModificationTypeFromLanguage,
  modificationType,
} from "@/components/Article/constant";
import { useArticleActions } from "@/composables/useArticleActions";
import { usePage } from "@/composables/usePage";
import { authService } from "@/services/AuthService";
import router from "@/router";
import { RoutesName } from "@/router/routesName";
import DialogEmailArticle from "./DialogEmailArticle.vue";
import { usePublicationStore } from "@/stores/publication";
import { useKeyboardListener } from "@/composables/useKeyboardListener";
import { mailService } from "@/services/MailService";
import { formatErrorMessage, useLocale } from "@/utils";

const { isFr } = useLocale();

const {
  startListening: startListeningPrint,
  stopListening: stopListeningPrint,
} = useKeyboardListener(["Alt+Control+Shift+P", "Alt+Control+Shift+p"], () => {
  print();
});

const {
  startListening: startListeningChangeLang,
  stopListening: stopListeningChangeLang,
} = useKeyboardListener(["Control+Shift+>"], () => {
  if (articleForm.value.contents.length == 1) return;

  if (splitMode.value) {
    splitMode.value = false;
    tabArticleContent.value = articleForm.value.idMainLanguage;
  } else if (articleForm.value.idMainLanguage == tabArticleContent.value) {
    tabArticleContent.value =
      tabArticleContent.value == FrenchLanguage
        ? EnglishLanguage
        : FrenchLanguage;
  } else {
    splitMode.value = true;
  }
});

const { publications } = usePublicationStore();

let notifyContext;
const props = defineProps({
  defaultArticle: {
    type: Object as PropType<ArticleDto>,
    default() {
      return createArticle();
    },
  },
});
const emits = defineEmits<{
  (
    e: "submit",
    article: ArticleDto,
    deleteDraft: boolean,
    callback: () => void
  ): void;
}>();

const $q = useQuasar();
const route = useRoute();
const { showPanelLeft, panelLeftMini } = usePage();
const { submitting, actions } = useFormArticle();
const { actions: articleActions } = useArticleActions();

const currentArticleStore = useCurrentArticleStore();

const {
  currentArticle: articleForm,
  modifExist,
  lockType,
  lang: tabArticleContent,
  languagesLocked,
  locks,
  isHistory,
  isVersionObsolete,
  isDraft,
  getLastModificationAuthor,
  getLastModificationIdAuthor,
} = storeToRefs(currentArticleStore);
if (
  JSON.stringify(props.defaultArticle) !== JSON.stringify(articleForm.value)
) {
  currentArticleStore.initArticle(props.defaultArticle);
}

const formArticle = ref<HTMLFormElement | null>(null);
const splitterModel = ref<number>(50);
const splitMode = ref<boolean>(false);

const showDialogAddLanguage = ref<boolean>(false);
const bus = inject<EventBus>("bus");
const canAddLang = computed(
  () =>
    articleForm.value.publication !== "LLA" &&
    articleForm.value.contents.length < 2 &&
    authService.hasRight(
      RightReference.PUBLICATION_ARTICLE_CREATE_TRANSLATION,
      articleForm.value.publication
    )
);
const canSendMail = computed(() => authService.hasRight("SEND_MAIL_ARTICLE"));
const sendingInfos = reactive({ nbSendings: 0, tooltipInfo: "" });

const canDuplicate = computed(() => authService.hasRight("ARTICLE_DUPLICATE"));
const messageLangDisabled = computed(() => {
  if (modifExist.value != modificationType.None)
    return i18n.t("article.messages.addLangDisabledModifiedByYou");

  if (isDraft.value) return i18n.t("article.messages.addLangDisabledDraft");

  const usersWithLock = locks.value
    .filter((l) => l.userId != authService.getUserId())
    .map((l) => l.userName);

  if (usersWithLock.length > 0)
    return i18n.t("article.messages.addLangDisabledModifiedByAnother", {
      users: usersWithLock.join(", "),
    });

  return "";
});
const titleCartoucheVersionModified = computed(() => {
  return i18n.t("article.dialogs.titles.notifyArticleMustBeRefresh", {
    userName: getLastModificationAuthor.value,
    interpolation: { escapeValue: false },
  });
});
const positionAlertBannerLeft = computed(() => {
  if (panelLeftMini.value) {
    return "left: 100px; transform: translateX(0)";
  }
  if (showPanelLeft.value) {
    return "left: 250px; transform: translateX(0)";
  }
  return undefined;
});

window.onbeforeunload = function () {
  if (!submitting.value && modifExist.value) {
    currentArticleStore.saveCurrentArticleInDraft();
    return i18n.t("messages.quit");
  }
};

window.onunload = function () {
  if (lockType.value | modifExist.value) {
    currentArticleStore.syncDeleteLock();
  }
};

onBeforeRouteLeave(() => {
  if (submitting.value || !modifExist.value || isDraft.value) {
    articleActions.initArticle();
    return true;
  }
  return new Promise((resolve) => {
    $q.dialog({
      title: i18n.t("messages.quitTitle"),
      message: i18n.t("messages.quit"),
      cancel: true,
      persistent: true,
    })
      .onOk(() => {
        currentArticleStore.saveCurrentArticleInDraft();
        modifExist.value = modificationType.None;
        articleActions.initArticle();
        resolve(true);
      })
      .onCancel(() => resolve(false));
  });
});
onMounted(async () => {
  if ("lang" in route.query) {
    const lang = (route.query.lang as string).startsWith("en")
      ? EnglishLanguage
      : FrenchLanguage;
    if (articleForm.value.contents.some((c) => c.language === lang)) {
      tabArticleContent.value = lang;
    }
  }
  getInfosMailing(tabArticleContent.value ?? FrenchLanguage);
  handleMessagesForDraft();
  startListeningPrint();
  startListeningChangeLang();
  if (!bus) return;
  bus.on("FormArticle.submit", () => onSubmitForm());
});
onUnmounted(() => {
  if (notifyContext) notifyContext();
  stopListeningPrint();
  stopListeningChangeLang();
  if (!bus) return;
  bus.off("FormArticle.submit");
});
onUpdated(() => {
  handleMessagesForDraft();
});

watch(
  () => tabArticleContent.value,
  (lang) => {
    getInfosMailing(lang);
  }
);

/**
 *
 */
function getInfosMailing(lang: string) {
  const content = articleForm.value.contents.find((c) => c.language === lang);
  mailService.getInfosSendingMail(content?.id ?? 0).then((infos) => {
    sendingInfos.nbSendings = infos.nbSendings;
    sendingInfos.tooltipInfo = date.formatDate(
      infos.lastSendDate?.replace("Z", ""),
      isFr.value ? "dddd D MMMM YYYY à HH:mm" : "dddd, D MMMM YYYY h:mm A"
    );
  });
}

/**
 * Fonction appelé lors du choix de la nouvelle langue à rajouter à l'article
 */
function onNewLanguage() {
  window.location.reload();
}

/**
 * Fonction appelé pour lancer la preview d'un article.
 */
async function preview() {
  await articleService.createPreview(
    articleForm.value.id,
    tabArticleContent.value
  );
}

/**
 * Fonction appelé pour dupliquer un article dans une autre publication.
 */
async function duplicate(publication: string) {
  const newId = await articleService.duplicate(
    articleForm.value.id,
    publication
  );

  if (!newId) return;

  const routeData = router.resolve({
    name: RoutesName.EditArticle,
    params: { id: newId },
  });

  window.open(routeData.href, "_blank");
}

/**
 * Fonction appelé pour lancer la vue impression de l'article.
 */
async function print() {
  const routeData = router.resolve({
    name: RoutesName.Preview,
    params: { articleIds: articleForm.value.id },
    query: { lang: tabArticleContent.value },
  });
  window.open(routeData.href, "_blank");
}

/** Change la langue principale de l'article */
async function changeMainLanguage() {
  try {
    currentArticleStore.loading = true;
    submitting.value = true;
    await articleService.switchMainLanguage(articleForm.value.id);
    window.location.reload();
  } catch (e: unknown) {
    currentArticleStore.loading = false;
    const message = formatErrorMessage(e, "article.errors.onSave");
    $q.notify({
      type: "warning",
      message,
    });
  }
}

/**
 * Fonction appelé lorsque l'article est sauvegardé
 */
function onSubmitForm() {
  actions.onSubmit(() => {
    emits("submit", currentArticleStore.exportArticle(), true, () => {
      // On utilise un timer car est présent un débounce (300ms) sur le formulaire article content qui est mis à jour suite à la sauvegarde.
      setTimeout(() => {
        modifExist.value = modificationType.None;
        if (notifyContext) notifyContext();
      }, 400);
    });
  });
}

/**
 * Utilisé par la chip qui indique que le français a bougé
 */
async function refreshArticle() {
  if (modifExist.value > 0) await currentArticleStore.updateArticle();
  else await currentArticleStore.refreshArticle();
}

/**
 * Gestion des messages d'alerte pour un article brouillon chargé
 */
function handleMessagesForDraft() {
  if (!isDraft.value || $q.screen.lt.sm) return;
  let notifConfig: Partial<QNotifyCreateOptions>;
  if (isHistory.value) {
    notifConfig = {
      type: "negative",
      message: i18n.t("article.messages.draftNoEditable"),
    };
  } else {
    notifConfig = {
      type: "warning",
      message: i18n.t("article.messages.draftEditable"),
    };
  }
  notifyContext = $q.notify({
    timeout: 0,
    position: "bottom",
    classes: "gt-xs q-py-sm text-subtitle2",
    multiLine: false,
    ...notifConfig,
  });
}
/** Fonction pour connaitre l'icone a faire apparaitre a cote de la langue */
function iconForLanguage(language: string) {
  if (languagesLocked.value[language]) return "lock";
  const typeOfModification = getModificationTypeFromLanguage(language);

  if ((currentArticleStore.modifExist & typeOfModification) != 0) return "edit";

  return undefined;
}

/** Fonction pour connaitre le tooltip accompagnant l'icone de verrouillage */
function lockTooltip(lockInfo: string) {
  const splitInfo = lockInfo.split("|");
  return i18n.t(`article.locks.reasons.${splitInfo[0]}`, splitInfo.slice(1));
}

/** Génère l'email */
function generateEmail() {
  $q.dialog({
    component: DialogEmailArticle,
    componentProps: {
      content: articleForm.value.contents.find(
        (c) => c.language === tabArticleContent.value
      ),
      sections: articleForm.value.sections,
      publishedOn: articleForm.value.publishedOn,
    },
  }).onOk(() => {
    getInfosMailing(tabArticleContent.value);
  });
}
</script>
<style scoped>
.alert-important-left,
.alert-important-right {
  position: fixed;
  top: 100px;
  z-index: 9999;
}
.alert-important-left > *,
.alert-important-right > * {
  max-width: 500px;
}
.alert-important-left {
  left: 25%;
  transform: translateX(-50%);
}
.alert-important-right {
  right: 25%;
  transform: translateX(50%);
}
@media screen and (max-width: 1599px) {
  .alert-important-left > *,
  .alert-important-right > * {
    max-width: 350px;
    height: auto;
  }
}
</style>
