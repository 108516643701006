<template>
  <q-item class="q-px-sm">
    <q-item-section top>
      <div class="row">
        <div class="q-gutter-sm">
          <q-chip
            size="sm"
            dense
            icon="event"
            color="grey-5"
            style="font-size: 0.8rem"
            square
          >
            {{ $t(`${date.formatDate(article.publishedOn, mask)}`) }}
          </q-chip>
          <q-chip
            size="sm"
            text-color="white"
            square
            dense
            style="font-size: 0.8rem; color: white"
            :style="
              'background-color:' +
              getThemeByPublication(article.publication)?.primary
            "
          >
            {{ $t(`${article.publication}`) }}
          </q-chip>
          <q-chip
            size="sm"
            dense
            text-color="white"
            style="font-size: 0.8rem; color: white"
            outlined
            square
            :style="
              'background-color:' +
              getThemeByPublication(article.publication)?.primary
            "
          >
            {{ $t(`${article.countries.map((c) => c.labelFr).join(" / ")}`) }}
          </q-chip>
          <q-chip
            v-if="isFeuilleton"
            size="sm"
            outlined
            square
            dense
            text-color="white"
            style="font-size: 0.8rem"
            :style="
              'background-color:' +
              getThemeByPublication(article.publication)?.primary
            "
          >
            {{
              $t(
                `${
                  article.contents.find((c) => c.language === language)?.format
                }`
              )
            }}
          </q-chip>
        </div>
      </div>
      <div class="row q-mt-xs">
        <router-link
          :to="{
            name: RoutesName.Preview,
            params: { articleIds: article.id },
            query: { lang: language },
          }"
          target="_blank"
          class="text-black text-weight-medium"
          style="text-decoration: none"
        >
          {{
            $t(
              `${article.contents.find((c) => c.language === language)?.title}`
            )
          }}
        </router-link>
      </div>
    </q-item-section>
    <q-item-section side>
      <slot name="actions" :article="article" />
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import type { ArticleDto } from "@/types/api";
import { computed } from "vue";
import { date } from "quasar";
import { RoutesName } from "@/router/routesName";
import { useLocale } from "@/utils";
import { getThemeByPublication } from "@/theme";
const { language } = useLocale();
const props = defineProps<{
  article: ArticleDto;
}>();

const isFeuilleton = computed(() => {
  return (
    props.article.contents.find((c) => c.language === language.value)?.format ==
    "Feuilleton"
  );
});
const mask = "DD/MM/YYYY";
</script>
