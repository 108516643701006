<template>
  <!-- Search -->
  <q-card style="display: flex; flex-direction: column">
    <q-inner-loading :showing="loading">
      <q-spinner-cube color="orange" size="5.5em" />
    </q-inner-loading>
    <slot name="actions" />
    <q-card-section class="q-pb-sm">
      <q-form class="searchform" @submit="onSubmit">
        <div class="q-mb-sm row q-gutter-sm">
          <div class="col">
            <SearchSelectInput
              ref="selectOptionRecherche"
              v-model="searches[0]"
              @on-submit="onSubmit"
            />
          </div>
          <div class="col-auto q-gutter-x-xs">
            <q-btn
              color="primary"
              icon="add"
              style="height: 100%; max-height: 50px"
              @click="
                searches.push([]);
                expandSearch = true;
              "
            />
            <q-btn
              :loading="loading"
              color="primary"
              icon="manage_search"
              :disable="search.length === 0"
              :label="$q.screen.gt.xs ? searchButtonLabel : undefined"
              style="height: 100%; max-height: 50px"
              @click="onSubmit"
            >
              <q-tooltip>
                {{ tooltipSearch }}
              </q-tooltip>
            </q-btn>
          </div>
        </div>
        <div v-if="searches.length > 1" class="col text-center">
          <q-icon
            :name="expandSearch ? 'unfold_less_double' : 'unfold_more_double'"
            size="16px"
            @click="expandSearch = !expandSearch"
          />
        </div>
        <template v-for="(s, idx) in searches">
          <div
            v-if="expandSearch && idx > 0"
            :key="idx"
            class="q-mb-sm row q-gutter-sm"
          >
            <SearchSelectInput
              v-model="searches[idx]"
              class="col"
              @on-submit="onSubmit"
            >
            </SearchSelectInput>
            <div class="col-auto">
              <q-btn
                color="primary"
                icon="remove"
                style="height: 100%; max-height: 50px"
                @click="searches.splice(idx, 1)"
              />
            </div>
          </div>
        </template>
        <div class="q-gutter-sm gt-xs">
          <q-toggle
            v-model="filterOnlyMyArticle"
            icon="person"
            size="md"
            dense
            color="purple"
            :label="$t('search.labels.tgOnlyMyArticle')"
            :val="false"
          />
          <q-toggle
            v-model="filterOnToday"
            size="md"
            dense
            icon="event"
            color="deep-orange-5"
            :label="$t('search.labels.tgTodayOnly')"
            :val="false"
          />
          <q-toggle
            v-model="filterOnlyOnTitleAndStandFirst"
            icon="subtitles_off"
            dense
            size="md"
            color="blue"
            :label="$t('search.labels.tgOnlyTitleStandFirst')"
            :val="false"
          />
          <q-toggle
            v-model="showOnlyTitleAndStandFirst"
            icon="visibility"
            dense
            size="md"
            color="blue"
            :label="$t('search.labels.tgOnlyShowTitleStandFirst')"
            :val="false"
          />
          <q-toggle
            v-model="filterOnlyImported"
            icon="newspaper"
            dense
            size="md"
            color="indigo-5"
            :label="$t('search.labels.tgOnlyImported')"
            :val="false"
          />
          <q-toggle
            v-if="localeIsFr"
            v-model="filterOnlyEnglish"
            icon="translate"
            dense
            size="md"
            color="yellow-9"
            :label="$t('search.labels.tgOnlyEnglish')"
            :val="false"
          />
          <q-toggle
            v-if="!localeIsFr"
            v-model="filterOnlyFrench"
            icon="translate"
            dense
            size="md"
            color="yellow-9"
            :label="$t('search.labels.tgOnlyFrench')"
            :val="false"
          />
          <q-toggle
            v-model="filterSpotlights"
            icon="visibility"
            dense
            size="md"
            color="blue"
            :label="$t('search.labels.tgOnlySpotlights')"
            :val="false"
          />
        </div>
      </q-form>
    </q-card-section>
    <q-separator />
    <q-card-section
      v-if="searchResultArticle != null"
      class="q-pa-none bg-grey-3"
      :style="`height: calc(100vh - ${resultAdjustingHeight}px)`"
    >
      <!-- Résultat de la recherche -->
      <q-scroll-area ref="scrollArea" class="results" :style="'height: 100%'">
        <div class="header q-my-md">
          <b>{{ searchResultArticle?.totalResult }}</b>
          {{ $t("search.fields.searchResult") }}
        </div>
        <SearchResultItem
          v-for="article in searchResultArticle.data"
          :key="article.id"
          :article="article"
          :locale-selected="languageSearch"
          :only-title-and-stand-first="showOnlyTitleAndStandFirst"
          :article-selectable="articleSelectable"
          @click-on-country="addFilterCountryToSelect"
          @click-on-section="addFilterSectionToSelect"
        />
      </q-scroll-area>
      <q-separator />
    </q-card-section>
    <q-card-section v-if="searchResultArticle != null" class="q-pa-xs">
      <div class="flex flex-center" style="height: 30px">
        <!-- Ecrit une pagination qui déclanche le submit du form  -->
        <q-pagination
          v-model="currentPage"
          :max="searchResultArticle.totalPage"
          :max-pages="10"
          :input-icon="'search'"
          :direction-links="true"
          size="sm"
          @update:model-value="onSubmit"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<script setup lang="ts">
import { searchService } from "@/services/SearchService";
import { computed, ref, onMounted } from "vue";
import { QSelectOption, useQuasar, scroll, QScrollArea } from "quasar";
import {
  ArticleDto,
  ArticleSearch,
  ContributionType,
  SearchResult,
  WorkflowState,
} from "@/types/api";
import SearchResultItem from "@/components/Article/Search/SearchArticleResult.vue";
import { authService } from "@/services/AuthService";
import { EnglishLanguage, FrenchLanguage } from "@/constants";
import { storeToRefs } from "pinia";
import { useConfigStore } from "@/stores/config";
import { i18n } from "@/i18n";
import SearchSelectInput from "./SearchSelectInput.vue";
import { SearchPart, extractSearchParamFomSelectOption } from "./utils";

//Déclaration des variables
const $q = useQuasar();
const loading = ref<boolean>(false);

const currentPage = ref<number>(1);
const languageSearch = ref<string[]>([FrenchLanguage, EnglishLanguage]);
const configStore = useConfigStore();
const { config, localeIsFr } = storeToRefs(configStore);
let lastSearch = "";

const filterOnlyOnTitleAndStandFirst = ref<boolean>(false);
const showOnlyTitleAndStandFirst = ref<boolean>(false);
const selectOptionRecherche = ref<object | null>(null);
const scrollArea = ref<QScrollArea | null>(null);
const { getScrollTarget, setVerticalScrollPosition } = scroll;

const resultAdjustingHeight = computed(() => {
  if (searches.value.length < 2) return 187;
  const base = 187 + 21;
  if (expandSearch.value) return base + (searches.value.length - 1) * 53;
  return base;
});

const expandSearch = ref(true);

const props = withDefaults(
  defineProps<{ labelSearchButton?: string; articleSelectable?: boolean }>(),
  {
    labelSearchButton: "titles.Search",
    articleSelectable: false,
  }
);

const searchButtonLabel = computed(() => {
  if (props.labelSearchButton !== undefined)
    return i18n.t(props.labelSearchButton);
  return $q.screen.gt.md ? i18n.t("titles.Search") : undefined;
});

const searches = ref<QSelectOption<SearchPart>[][]>([[]]);
const search = computed(() => {
  return searches.value[0] ?? [];
});

const tooltipSearch = computed(() => {
  if (searches.value.length === 1 && searches.value[0].length === 0) return "";

  const and = i18n.t("search.fields.and");
  const or = i18n.t("search.fields.or");

  const groupByType = function (options: QSelectOption<SearchPart>[]) {
    return options.reduce(
      (acc, curr) => {
        if (!acc[curr.value.type]) acc[curr.value.type] = [];
        acc[curr.value.type].push(curr.value);
        return acc;
      },
      {} as { [key: string]: SearchPart[] }
    );
  };

  const groupSearch = searches.value.reduce((acc, curr) => {
    const groups = groupByType(curr);

    Object.keys(groups).forEach((key) => {
      const group = groups[key];
      const groupValue = group.map((g) => g.label).join(` ${or} `);

      if (!acc[key]) acc[key] = [];

      if (group.length > 1) acc[key].push(`(${groupValue})`);
      else acc[key].push(groupValue);
    });

    return acc;
  }, {});

  const label: string[] = [];

  Object.keys(groupSearch).forEach((key) => {
    const group = groupSearch[key];
    const groupValue = group.join(` ${and} `);

    console.log(key, group, groupValue);

    if (group.length > 1) label.push(`(${groupValue})`);
    else label.push(groupValue);
  });

  return label.join(` ${and} `);
});

const searchResultArticle = ref<SearchResult<ArticleDto>>();

const filterOnToday = computed<boolean>({
  get() {
    const todayDate = new Date().toLocaleDateString(FrenchLanguage, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const valueToSearch = "PublishedAt:" + todayDate;
    return search.value.some(
      (s) => s.value.type == "PublishedAt" && s.value.value == valueToSearch
    );
  },
  set(newValue): void {
    addOrRemoveFilterToday(newValue);
  },
});

const filterOnlyImported = computed<boolean>({
  get() {
    const stateFilter = search.value.filter((s) => s.value.type == "State");
    if (stateFilter.length == 0 || stateFilter.length == 2) return false;

    return stateFilter.some(
      (s) => s.value.type == "State" && s.value.value == WorkflowState.Importe
    );
  },
  set(newValue): void {
    addOrRemoveFilterStateImported(newValue);
  },
});

const filterSpotlights = computed<boolean>({
  get() {
    const stateFilter = search.value.filter((s) => s.value.type == "Format");
    if (stateFilter.length == 0 || stateFilter.length == 2) return false;

    return stateFilter.some(
      (s) => s.value.type == "Format" && s.value.value == "Feuilleton"
    );
  },
  set(newValue): void {
    addOrRemoveFilterSpotlights(newValue);
  },
});

const filterOnlyEnglish = computed<boolean>({
  get() {
    const stateFilter = search.value.filter((s) => s.value.type == "Language");
    if (stateFilter.length == 0 || stateFilter.length == 2) return false;

    return stateFilter.some(
      (s) => s.value.type == "Language" && s.value.value == EnglishLanguage
    );
  },
  set(newValue): void {
    addOrRemoveFilterLanguage(newValue ? EnglishLanguage : null);
  },
});

const filterOnlyFrench = computed<boolean>({
  get() {
    const stateFilter = search.value.filter((s) => s.value.type == "Language");
    if (stateFilter.length == 0 || stateFilter.length == 2) return false;

    return stateFilter.some(
      (s) => s.value.type == "Language" && s.value.value == FrenchLanguage
    );
  },
  set(newValue): void {
    addOrRemoveFilterLanguage(newValue ? FrenchLanguage : null);
  },
});

const filterOnlyMyArticle = computed<boolean>({
  get() {
    return search.value.some(
      (s) =>
        s.value.type == "User" &&
        (s.value.value as string).split(":")[1] ==
          authService.currentUser?.id &&
        (s.value.value as string).split(":")[0] == ContributionType.Unknown
    );
  },
  set(newValue): void {
    addOrRemoveFilterCurrentUser(newValue);
  },
});

onMounted(() => {
  addFilterToSelect("Publication", config.value.publication);
  addFilterToSelect("State", WorkflowState.Importe);
});

/**
 *
 */
function addOrRemoveFilterToday(valueToSet: boolean) {
  if (!selectOptionRecherche.value) return;
  selectOptionRecherche.value["addOrRemoveFilterToday"](valueToSet);
}

/**
 *
 */
function addOrRemoveFilterStateImported(valueToSet: boolean) {
  if (!selectOptionRecherche.value) return;
  selectOptionRecherche.value["addOrRemoveFilterStateImported"](valueToSet);
}

/**
 *
 */
function addOrRemoveFilterSpotlights(valueToSet: boolean) {
  if (!selectOptionRecherche.value) return;
  selectOptionRecherche.value["addOrRemoveFilterSpotlights"](valueToSet);
}

/**
 *
 */
function addOrRemoveFilterLanguage(valueToSet: string | null) {
  if (!selectOptionRecherche.value) return;
  selectOptionRecherche.value["addOrRemoveFilterLanguage"](valueToSet);
}

/**
 * Permet d'ajouter un filtre sur les articles de l'utilisateur courant
 */
function addOrRemoveFilterCurrentUser(valueToSet: boolean) {
  if (!selectOptionRecherche.value) return;
  selectOptionRecherche.value["addOrRemoveFilterCurrentUser"](valueToSet);
}

/**
 * Permet de sélectionner une option de recherche sur un pays
 */
function addFilterCountryToSelect(value: number) {
  addFilterToSelect("Country", value);
}
/**
 * Permet de sélectionner une option de recherche sur un rubrique
 */
function addFilterSectionToSelect(value: number) {
  addFilterToSelect("Section", value);
}

/**
 * Permet d'ajouter de nouveau élements dans la liste des options de recherche.
 * Utiliser pour enrichir la recherche à partir de clic sur les résulats de recherche
 */
function addFilterToSelect(type: string, value: unknown) {
  if (!selectOptionRecherche.value) return;
  selectOptionRecherche.value["addFilterToSelect"](type, value);
}

/**
 * Lance la recherche.
 */
async function onSubmit() {
  const params: ArticleSearch[] = [];

  searches.value.forEach((s, i) => {
    params.push(
      extractSearchParamFomSelectOption(
        s,
        currentPage.value,
        filterOnlyOnTitleAndStandFirst.value,
        i === 0
      )
    );
  });

  const param = params[0];

  // params serialisé pour comparaison sans la propriété currentPage
  const serializedParams = JSON.stringify(
    params.map((p) => ({ ...p, page: 0 }))
  );

  if (lastSearch !== serializedParams) {
    currentPage.value = 1;
    param.page = 1;
  }

  lastSearch = serializedParams;

  //Si param.language est vide ou null, alors on ajoute 'fr-FR" et "en-EN" dans searchLanguage.value
  if (!param.language || param.language.length === 0) {
    if (localeIsFr.value)
      languageSearch.value = [FrenchLanguage, EnglishLanguage];
    else languageSearch.value = [EnglishLanguage, FrenchLanguage];
  } else {
    languageSearch.value = [param.language];
  }

  loading.value = true;
  //Creation d'un object SearchResult<ArticleDto> avec 0 résultat
  let result: SearchResult<ArticleDto> = {
    totalResult: 0,
    totalPage: 0,
    data: [],
    currentPage: 0,
  };

  try {
    result = await searchService.searchArticle(params);
    scrollArea.value?.setScrollPosition("vertical", 0);
  } catch (e) {
    console.error(e);
    $q.notify({
      type: "negative",
      message: i18n.t("search.errors.serverError"),
      position: "top",
    });
    loading.value = false;
    searchResultArticle.value = result;
    return;
  }

  //Si aucun résultat créé une notification indiquant aucun résutlat et créer un object SearchResult<ArticleDto> avec 0 résultat
  if (result.totalResult === 0 && currentPage.value !== 1) {
    currentPage.value = 1;
    await onSubmit();
  } else if (result.totalResult === 0 && currentPage.value === 1) {
    $q.notify({
      type: "info",
      message: i18n.t("search.errors.noResult"),
      position: "top",
    });
  }

  scrollToElement(document.getElementsByClassName("selectOptionRecherche"));
  loading.value = false;
  searchResultArticle.value = result;
}

/**
 * Permet de définir la position verticale de la page.
 */
function scrollToElement(el) {
  if (el == null || el.length == 0) return;
  const target = getScrollTarget(el[0]);
  const offset = el.offsetTop;
  const duration = 1000;
  setVerticalScrollPosition(target, offset, duration);
}
</script>

<style lang="scss" scoped>
.body--dark {
  .results {
    background-color: #454545;
  }
}

.results {
  .header {
    text-align: center;
    font-family: "CooperHewitt-Book", sans-serif;
    font-size: 2rem;

    b {
      font-family: "CooperHewitt-Heavy", sans-serif;
      font-size: 2rem;
    }
  }
}

:deep(.q-scrollarea__content) {
  max-width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

:deep(.article) {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .results .header {
    font-size: 1.2rem;
  }
  .results .header b {
    font-family: "CooperHewitt-Heavy", sans-serif;
    font-size: 1.2rem;
  }
}
</style>
