<template>
  <q-dialog ref="dialogRef" persistent @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <div class="text-h6">{{ title }}</div>
      </q-card-section>
      <q-card-actions class="q-pa-md q-gutter-sm" align="center">
        <q-btn
          color="grey-7"
          class="full-width"
          size="sm"
          icon="reply"
          :label="$t('article.labels.btnNonValid')"
          @click="onDialogOK(RoutesName.CreateArticle)"
        />
        <q-separator vertical spaced="true" />
        <q-btn
          class="full-width"
          color="green-6"
          icon="edit_square"
          :label="$t('article.labels.btnValid')"
          @click="onDialogOK(RoutesName.EditArticle)"
        />
        <q-separator vertical />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { RoutesName } from "@/router/routesName";
import { useDialogPluginComponent } from "quasar";

defineProps<{
  title: string;
}>();
defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent();
</script>
