<template>
  <q-dialog ref="dialogRef" persistent @hide="onDialogHide">
    <q-card style="width: 1000px; max-width: 80vw">
      <q-bar
        :style="
          'background-color:' +
          getThemeByPublication(config.publication)?.primary
        "
        class="text-white"
      >
        <div>
          {{ $t("articleSetting.dialogs.titles.barAutoInterest") }}
        </div>
        <q-space />
        <q-btn v-close-popup dense flat icon="close" />
      </q-bar>
      <q-card-section v-if="suggestedInterests.length > 0">
        <q-list>
          <q-chip
            v-for="r in suggestedInterests"
            :key="r.id"
            removable
            @remove="deleteInterestFromList(r.id)"
            >{{ r.labelFr }}</q-chip
          >
        </q-list>
      </q-card-section>
      <q-card-section v-else class="q-gutter-md" align="center">
        <div>
          <q-icon name="warning" size="3em" color="warning" />
          {{ $t("associatedArticles.dialogs.noSuggestedInterest") }}
        </div>
      </q-card-section>
      <q-card-actions align="center" class="q-pb-md">
        <q-btn :label="labelBtnClose" color="black" @click="onDialogCancel()" />
        <q-btn
          v-if="suggestedInterests.length > 0"
          :label="$t('associatedArticles.dialogs.btnAdd')"
          color="primary"
          @click="onDialogOK(suggestedInterests)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent } from "quasar";
import type { ArticleSettingDto } from "@/types/api";
import { ref } from "vue";
import { useConfigStore } from "@/stores/config";
import { getThemeByPublication } from "@/theme";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  interests: ArticleSettingDto[];
}>();
defineEmits([...useDialogPluginComponent.emits]);

const i18n = useI18n();

const { dialogRef, onDialogCancel, onDialogOK, onDialogHide } =
  useDialogPluginComponent();

const suggestedInterests = ref<ArticleSettingDto[]>(props.interests);
const { config } = useConfigStore();

const labelBtnClose =
  suggestedInterests.value.length > 0
    ? i18n.t("associatedArticles.dialogs.btnCancel")
    : i18n.t("associatedArticles.dialogs.btnClose");

/**
 * Supprime l'intéret choisi.
 */
function deleteInterestFromList(id: number) {
  let indexToSlice = suggestedInterests.value.findIndex((p) => p.id == id);
  suggestedInterests.value.splice(indexToSlice, 1);
}
</script>
