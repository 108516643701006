import { ArticleLockDto, RightReference, type ArticleDto } from "@/types/api";
import { useQuasar } from "quasar";
import { formatErrorMessage, useLocale } from "@/utils";
import { useArticleActions } from "@/composables/useArticleActions";
import {
  getNextEditionDay,
  getPreviousEditionDay,
  needToBeTranslated,
} from "@/helpers/article";
import { authService } from "@/services/AuthService";

/** Interface des paramètres pour les actions article */
interface ArticleActionMenuParam {
  onLoading: (value: boolean) => void;
  onReload: () => void;
  onShowDialogChangePublishedOn?: (value: {
    article: ArticleDto;
    date: string;
  }) => void;
  onShowDialogAddLanguage?: () => void;
}

/** Format la date de manière inteligente pour l'affichage */
function formatDateForOption(isFr: boolean, date: Date) {
  return Intl.DateTimeFormat(isFr ? "fr-FR" : "en-US", {
    month: "numeric",
    day: "numeric",
    weekday: "long",
  }).format(date);
}

/** L'utilisateur peut ajouter une langue */
function canAddLang(article: ArticleDto) {
  return authService.hasRight(
    RightReference.PUBLICATION_ARTICLE_CREATE_TRANSLATION,
    article.publication
  );
}
/** L'utilisateur peut modifier les dates de parution */
function canChangePublishedOn(article: ArticleDto) {
  return authService.hasRight(
    RightReference.CHANGE_PUBLISHED_ON,
    article.publication
  );
}
/** L'utilisateur peut modifier l'article */
function canModifyParameter(
  article: ArticleDto,
  articleLocks: ArticleLockDto[]
) {
  const locks = articleLocks.filter((l) => l.articleId === article.id);
  if (
    locks.some(
      (l) =>
        1 === (l.modificationType & 1) && l.userId != authService.getUserId()
    )
  )
    return false;

  return authService.canModifyAllLanguages(article);
}

/**
 * Fonction servant à interagir sur les différentes vues article
 */
export function useArticleActionMenu(param: ArticleActionMenuParam) {
  const $q = useQuasar();
  const { actions } = useArticleActions();
  const { isFr } = useLocale();

  /** Change la date de publication d'un article */
  const handleChangeDate = async function (
    article: ArticleDto,
    date: Date,
    languageFilter: string
  ) {
    param.onLoading(true);
    const mainLanguage = article.idMainLanguage == languageFilter;

    try {
      const result = await actions.updatePublishedOn(
        article.id,
        mainLanguage,
        date
      );
      param.onLoading(false);

      if (result) param.onReload();
    } catch (e) {
      console.error(e);
      $q.notify({
        type: "negative",
        message: formatErrorMessage(
          e,
          "exceptions.ArticleSaveException.SaveError"
        ),
      });

      param.onLoading(false);
    }
  };

  /** Gère la création de la traduction */
  const handleCreateTranslation = function (article: ArticleDto) {
    actions.findArticle(article.id, false, false, () => {
      if (param.onShowDialogAddLanguage) param.onShowDialogAddLanguage();
    });
  };

  /** Recupère les option disponible pour changer la date de publication */
  function getChangePublicationDateOptions(
    article: ArticleDto,
    languageFilter: string
  ) {
    const nbBefore = 4;
    let publishedOn = article.publishedOn;
    if (
      languageFilter != article.idMainLanguage &&
      article.publishedOnTranslation
    )
      publishedOn = article.publishedOnTranslation;

    if (!publishedOn) return [];

    const date = new Date(publishedOn);
    let option = date;
    for (let i = 0; i < nbBefore; i++) {
      option = getPreviousEditionDay(option);
    }
    const options = [] as { date: Date; label: string }[];
    const today = new Date();

    while (options.length < nbBefore * 2) {
      if (option > today && option.getDate() != date.getDate()) {
        options.push({
          date: option,
          label: formatDateForOption(isFr.value, option),
        });
      }
      option = getNextEditionDay(option);
    }

    return options;
  }

  /** Demande l'ouverture de la dialog permettant le changement de date de parution */
  const handleShowDialogChangePublishedOn = function (
    article: ArticleDto,
    languageFilter: string
  ) {
    const mainLanguage = article.idMainLanguage == languageFilter;

    let publishedOn = article.publishedOn ?? "";
    if (!mainLanguage && article.publishedOnTranslation)
      publishedOn = article.publishedOnTranslation;

    if (param.onShowDialogChangePublishedOn)
      param.onShowDialogChangePublishedOn({
        article,
        date: publishedOn,
      });
  };

  /** Permet de savoir si l'article doit être traduit */
  const getActions = function (
    article: ArticleDto,
    articleLocks: ArticleLockDto[]
  ) {
    const actions = {
      canTranslate: needToBeTranslated(article) && canAddLang(article),
      changeDate:
        !article.contents.some((c) => c.online) &&
        (canChangePublishedOn(article) ||
          canModifyParameter(article, articleLocks)),
      noAction: false,
    };
    actions.noAction = !Object.values(actions).some((a) => a);

    return actions;
  };

  return {
    handleChangeDate,
    handleCreateTranslation,
    getChangePublicationDateOptions,
    handleShowDialogChangePublishedOn,
    getActions,
  };
}
