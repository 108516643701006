import { UserDto, RightReference, ArticleDto } from "@/types/api";
import type { KeycloakInstance, KeycloakProfile } from "keycloak-js";
import type { AxiosResponse } from "axios";
import type { AuthServiceInstance } from "@/types/auth";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { setupInterceptorsTo } from "@/services/ElcanoAPIInterceptor";
import { conf_front_only } from "@/constants";

/**
 * Ajoute un style css au DOM de la page pour les mises en avant des commentaires.
 */
function addUserStyle(userId: string) {
  const style = document.createElement("style");

  style.textContent = `
span[data-user-id|="${userId}"] {
  color: cornflowerblue !important;
  font-weight: bold !important;
}

div.ck-comment:has(span[data-user-id|="${userId}"]) {
  background-color: #e9e8e8 !important;
}
`;
  document.head.appendChild(style);
}

/**
 * Service authentification servant à s'authentifier à l'application et à récupérer les informations du profile.
 */
export class AuthService extends AxiosRequest implements AuthServiceInstance {
  keycloakInstance: KeycloakInstance | null;
  currentUser: UserDto | null;
  rights: Record<string, string[]> | null;

  /**
   *
   */
  constructor() {
    super();
    this.keycloakInstance = null;
    this.currentUser = null;
    this.rights = null;
  }

  /**
   *
   */
  getUserId(): string {
    if (this.currentUser == null) return "";
    return this.currentUser.id;
  }

  /**
   *
   */
  getKeycloakId(): string {
    if (this.keycloakInstance == null) return "";
    const id = this.keycloakInstance.idTokenParsed?.sub;

    return id === undefined ? "" : id;
  }

  /**
   * initialisation du service avec keycloak
   * @param keycloakInstance - instance du service keycloak
   */
  async setup(keycloakInstance: KeycloakInstance) {
    if (conf_front_only) {
      return Promise.resolve();
    }
    setupInterceptorsTo(this.axiosInstance, keycloakInstance);
    this.keycloakInstance = keycloakInstance;
    this.currentUser = await this.renewToken();
    this.rights = this.currentUser.allRights;

    if (this.currentUser) {
      addUserStyle(this.currentUser.id);
    }
  }

  /**
   * Récupération du profile utilisateur
   */
  getUserProfile() {
    if (this.keycloakInstance === null) return Promise.resolve(null);
    return this.keycloakInstance.loadUserProfile() as Promise<KeycloakProfile>;
  }

  /**
   * Récupération des assignments du profile
   */
  getAssignments() {
    if (this.keycloakInstance === null) return [];

    return (this.keycloakInstance?.tokenParsed?.assignments as string[]) ?? [];
  }

  /**
   * Test si l'utilisateur a un droit spécifique, correspond à la même méthode dans UserDto.cs
   */
  hasRight(right: string, publication = "ALL") {
    if (!this.rights) return false;

    if (this.isFullAdmin()) return true;

    let comparator = (str: string) => str === right;
    if (right.endsWith("_")) {
      comparator = (str: string) => str.startsWith(right);
    }

    if (right.startsWith("PUBLICATION_") && publication == "ALL") {
      //  Sans publication de spécifier pour un droit qui peut le nécessité
      //  on vérifie s'il est présent pour n'importe laquelle des publications.
      for (const key in this.rights) {
        if (this.rights[key].some(comparator)) {
          return true;
        }
      }
    }

    if (this.rights["ALL"] && this.rights["ALL"].some(comparator)) return true;

    if (!this.rights[publication]) {
      return false;
    }

    return this.rights[publication].some(comparator);
  }

  /**
   * Check si l'utilisateur a le droit de sauvegardé
   * @param state Etat de l'article
   * @param publication publication
   * @returns si l'utilisateur a le droit de sauvegardé
   */
  checkRightToSave(state: string, publication: string) {
    return authService.hasRight(
      `${RightReference.PUBLICATION_ARTICLE_STATE_SAVE_}${state}`,
      publication
    );
  }

  /**
   * Verifie si l'utilisateur a le droit d'édition sur toutes les langues.
   * @param article article
   */
  canModifyAllLanguages(article: ArticleDto) {
    return article.contents
      .map((c) => this.checkRightToSave(c.state, article.publication))
      .every((b) => b);
  }

  /**
   * Détermine si l'utilisateur est redacteur pour la publication.
   */
  isRedactor(publication: string) {
    if (!this.currentUser) return false;
    if (this.isFullAdmin()) return true;

    return this.currentUser.redactor.some(
      (p) => p === publication || p === "ALL"
    );
  }

  /**
   * Détermine si l'utilisateur est full admin
   * @returns true si l'utilisateur est full admin
   * @returns false si l'utilisateur n'est pas full admin
   */
  isFullAdmin() {
    if (this.rights == null) return false;
    return this.rights["ALL"] && this.rights["ALL"].indexOf("ADMIN_FULL") >= 0;
  }
  /**
   * Détermine si l'utilisateur est editeur pour la publication.
   */
  isEditor(publication: string) {
    if (!this.currentUser) return false;
    if (this.isFullAdmin()) return true;
    return this.currentUser.editor.some(
      (p) => p === publication || p === "ALL"
    );
  }

  /**
   * Détermine si l'utilisateur est traducteur pour la publication.
   */
  isTranslator(publication: string) {
    if (!this.currentUser) return false;
    if (this.isFullAdmin()) return true;

    return this.currentUser.translator.some(
      (p) => p === publication || p === "ALL"
    );
  }

  /**
   * Détermine si l'utilisateur est seulement traducteur pour la publication.
   */
  isTranslatorOnly(publication: string) {
    if (!this.currentUser) return false;

    return (
      this.isTranslator(publication) &&
      !this.isEditor(publication) &&
      !this.isRedactor(publication) &&
      !this.isFreelancer(publication)
    );
  }

  /**
   * Détermine si l'utilisateur est pigiste pour la publication.
   */
  isFreelancer(publication: string) {
    if (!this.currentUser) return false;

    return this.currentUser.freelance.some(
      (p) => p === publication || p === "ALL"
    );
  }

  /**
   * Détermine si l'utilisateur à les droits de consultation sur une publication.
   */
  checkCanAccessPublication(publication: string) {
    if (this.hasRight(RightReference.ACCESS_ALL_PUBLICATIONS)) return true;

    return this.hasRight(RightReference.PUBLICATION_ACCESS, publication);
  }

  /**
   * Appel au serveur pour déclarer le nouveau token keycloak
   */
  renewToken() {
    return this.axiosInstance
      .post(`/users/auth`)
      .then((response: AxiosResponse<UserDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const authService = new AuthService();
