<template>
  <div>
    <q-inner-loading :showing="loading">
      <q-spinner-cube color="orange" size="5.5em" />
    </q-inner-loading>
    <div class="row q-gutter-sm q-mb-md items-center">
      <span class="q-ml-md rounded-chip text-primary" size="lg">1</span>
      <q-btn
        color="primary"
        :disable="baseIsLocked"
        :label="$t('articleSetting.labels.btnAutoInterest')"
        :loading="loadingInterest"
        @click="getInterestsFromText"
      />
      <q-select
        ref="selectOptionInterest"
        v-model="articleForm.settings"
        :disable="baseIsLocked"
        :options="artSettingInterest"
        :label="$t('articleSetting.fields.interest')"
        class="col-grow"
        emit-value
        map-options
        filled
        multiple
        use-chips
        use-input
        input-debounce="0"
        stack-label
        autocomplete="mainLabel"
        @filter="filterFn"
        @add="onAdd"
        @remove="onRemove"
      />
    </div>
    <q-separator />
    <div class="row q-gutter-sm items-center q-pt-sm q-pb-sm">
      <span class="q-ml-md rounded-chip text-primary" size="lg">2</span>
      <span class="q-ml-md"> {{ $t("associatedArticles.labelStep2") }}.</span>
    </div>
    <q-separator />
    <div class="row q-gutter-sm q-mt-sm q-mb-lg">
      <span class="q-ml-md rounded-chip text-primary">3</span>
      <q-btn
        color="primary"
        :disable="noFrenchContent || baseIsLocked"
        :label="$t('associatedArticles.autoAssociation')"
        @click="getAssociatedArticlesFromAutoAssociation"
      />
      <q-tooltip
        v-if="noFrenchContent"
        class="bg-primary"
        :offset="[10, 10]"
        style="font-size: 13px"
        >{{ $t("associatedArticles.tooltipNoFrenchContent") }}
      </q-tooltip>

      <q-btn
        color="primary"
        :label="$t('associatedArticles.labelBtnSearchArticles')"
        :disable="noFrenchContent || baseIsLocked"
        @click="openDialogSearchArticle"
      />
      <q-tooltip
        v-if="noFrenchContent"
        class="bg-primary"
        :offset="[10, 10]"
        style="font-size: 13px"
        >{{ $t("associatedArticles.tooltipNoFrenchContent") }}</q-tooltip
      >
    </div>
    <q-banner dense rounded class="bg-brown text-white">
      {{ $t("associatedArticles.addAssociatedArticlesTitle") }}
    </q-banner>
    <div v-show="chosenArticles.length == 0" class="q-my-sm">
      <q-chip>
        {{ $t("associatedArticles.labelChipNoChosenArticles") }}
      </q-chip>
    </div>
    <div v-if="chosenArticles.length">
      <q-list padding separator>
        <AssociatedArticleItem
          v-for="r in chosenArticles"
          :key="r.id"
          :article="r"
        >
          <template #actions="{ article }">
            <q-btn
              :icon="'delete'"
              flat
              @click.stop="deleteChosenArticle(article.id)"
            />
          </template>
        </AssociatedArticleItem>
      </q-list>
    </div>
    <q-banner dense rounded class="bg-brown text-white">
      {{ $t("associatedArticles.currentAssociatedArticlesTitle") }}
    </q-banner>
    <div>
      <q-list padding separator>
        <AssociatedArticleItem
          v-for="r in associatedArticles"
          :key="r.id"
          :article="r"
        >
          <template #actions="{ article }">
            <q-btn
              :icon="'delete'"
              flat
              @click.stop="deleteAssociatedArticle(article.id)"
            />
          </template>
        </AssociatedArticleItem>
      </q-list>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { articleService } from "@/services/ArticleService";
import { ArticleDto, ArticleSettingDto, SettingsType } from "@/types/api";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { useQuasar, QSelectOption, QSelect } from "quasar";
import DialogAutoAssociation from "@/components/Article/DialogAutoAssociation.vue";
import DialogSearchArticle from "@/components/Article/DialogSearchArticle.vue";
import AssociatedArticleItem from "@/components/Article/AssociatedArticleItem.vue";
import { FrenchLanguage } from "@/constants";
import { useLocale, removeDiacritics } from "@/utils";
import { useArticleActions } from "@/composables/useArticleActions";
import { getArticleSettingsOptions } from "@/helpers/article";

const { actions } = useArticleActions();

const { language } = useLocale();
const {
  currentArticle: articleForm,
  modifExist,
  baseIsLocked,
} = storeToRefs(useCurrentArticleStore());

const loading = ref<boolean>(true);
const loadingInterest = ref<boolean>(false);
const $q = useQuasar();

const filter = ref<string>("");
const selectOptionInterest = ref<QSelect | null>(null);

const noFrenchContent = computed<boolean>(() => {
  if (articleForm && articleForm.value && articleForm.value.contents)
    return !articleForm.value.contents.some(
      (c) => c.language == FrenchLanguage
    );

  return false;
});

const artSettingInterest = computed<QSelectOption<ArticleSettingDto>[]>(() => {
  const needle = filter.value;

  return getArticleSettingsOptions(SettingsType.Interest, false).filter((s) => {
    if (needle === "") return true;
    const label = removeDiacritics(s.label);
    return label.toLowerCase().indexOf(needle) > -1;
  });
});

let associatedArticles = ref<ArticleDto[]>([]);
let chosenArticles = ref<ArticleDto[]>([]);

onMounted(() => {
  loadAssociatedArticles();
});

watch(modifExist, (newValue) => {
  if (newValue === 0) {
    chosenArticles.value.length = 0;
    loadAssociatedArticles();
  }
});

/**
 * Ajoute un intéret à l'article.
 */
function onAdd(selectedInterest) {
  articleForm.value.settings.push(selectedInterest.value);
  selectOptionInterest.value?.updateInputValue("");
}

/**
 * Retire un intéret à l'article.
 */
function onRemove(selectedInterest) {
  const item = articleForm.value.settings.find(
    (aS) => aS.id == selectedInterest.value.id
  );

  if (item == null) return;

  const index = articleForm.value.settings.indexOf(item);
  articleForm.value.settings.splice(index, 1);
}

/**
 *  Permet de trigger le filtrage des intérêts pour simplifier l'utilisation.
 */
function filterFn(val, update) {
  update(() => {
    filter.value = val
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .toLowerCase();
  });
}

/**
 * Ajoute les nouveaux settings qui ont pu être créés par l'intéret auto.
 */
function addNewSettingsToListIfNeeded(interestsReturned: ArticleSettingDto[]) {
  const settingsToAdd = interestsReturned.filter(
    (set) => !artSettingInterest.value.map((s) => s.value).includes(set)
  );
  if (settingsToAdd.length > 0) {
    artSettingInterest.value.push(
      ...settingsToAdd.map((setting) => ({
        value: setting,
        label: language.value ? setting.labelFr : setting.labelEn,
      }))
    );
  }
}

/**
 * Gère le retour de choix des intérets à ajouter.
 */
function handleReturnedInterests(interestsReturned: ArticleSettingDto[]) {
  if (interestsReturned) {
    addNewSettingsToListIfNeeded(interestsReturned);
    articleForm.value.settings.push(...interestsReturned);
  }
}

/**
 * Récupère les intérets depuis le texte l'article.
 */
async function getInterestsFromText() {
  loadingInterest.value = true;
  await actions.getInterestsFromText(
    articleForm.value.id,
    articleForm.value.settings,
    handleReturnedInterests
  );
  loadingInterest.value = false;
}

/**
 * Récupère les enrichissements.
 */
async function loadAssociatedArticles() {
  loading.value = true;
  articleService
    .getArticles(articleForm.value.associatedArticles.map((p) => p.idToArticle))
    .then((p) => {
      var sorted = p.sort(sortDateByDesc);
      associatedArticles.value = sorted;
      loading.value = false;
    });
}

/**
 * Récupère les enrichissements via l'enrichissement auto.
 */
async function getAssociatedArticlesFromAutoAssociation() {
  loading.value = true;
  let articles = await articleService.getAssociatedArticlesFromAutoAssociation(
    articleForm.value.id
  );

  let articlesFiltered = articles
    .filter(
      (p) =>
        chosenArticles.value.findIndex((aa) => aa.id == p.id) < 0 &&
        p.id != articleForm.value.id
    )
    .sort(sortDateByDesc);

  loading.value = false;
  $q.dialog({
    component: DialogAutoAssociation,
    componentProps: {
      articles: articlesFiltered,
    },
  }).onOk((articlesReturned) => {
    if (articlesReturned) addArticleToChosenArticles(articlesReturned);
  });
}

/**
 * Trie par date antéchronologique
 */
function sortDateByDesc(aa1: ArticleDto, aa2: ArticleDto) {
  let aa1Format = aa1.contents.find((c) => c.language === language.value)
    ?.format;
  let aa2Format = aa2.contents.find((c) => c.language === language.value)
    ?.format;

  if (aa1Format == "Feuilleton" && aa2Format != "Feuilleton") return -1;
  if (aa1Format != "Feuilleton" && aa2Format == "Feuilleton") return 1;
  if (aa1.publishedOn === undefined && aa2.publishedOn === undefined) return 0;
  if (aa1.publishedOn === undefined) return 1;
  if (aa2.publishedOn === undefined) return -1;

  return (
    new Date(aa2.publishedOn).getTime() - new Date(aa1.publishedOn).getTime()
  );
}

/**
 * Ouvre une modal avec la recherche d'articles
 */
function openDialogSearchArticle() {
  $q.dialog({
    component: DialogSearchArticle,
  }).onOk((articles) => {
    if (articles) {
      addArticleToChosenArticles(articles);
    }
  });
}

/**
 * Ajout les enrichissements choisis à l'article
 */
function addArticleToChosenArticles(articles: ArticleDto[]) {
  let articlesFiltered = articles
    .filter(
      (p) =>
        chosenArticles.value.findIndex((aa) => aa.id == p.id) < 0 &&
        associatedArticles.value.findIndex((aa) => aa.id == p.id) < 0 &&
        p.id != articleForm.value.id
    )
    .sort(sortDateByDesc);

  chosenArticles.value.push(...articlesFiltered);
  articlesFiltered.forEach((aa) => {
    articleForm.value.associatedArticles.push({
      idFromArticle: articleForm.value.id,
      idToArticle: aa.id,
    });
  });
}

/**
 * Supprime un enrichissement proposé.
 */
function deleteChosenArticle(id: number) {
  let indexToSlice = chosenArticles.value.findIndex((p) => p.id == id);
  chosenArticles.value.splice(indexToSlice, 1);

  removeFromArticleStore(id);
}

/**
 * Supprime un enrichissement de l'article.
 */
function deleteAssociatedArticle(id: number) {
  let indexToSlice = associatedArticles.value.findIndex((p) => p.id == id);
  associatedArticles.value.splice(indexToSlice, 1);

  removeFromArticleStore(id);
}

/**
 * Retire l'enrichissement de l'article courant
 */
function removeFromArticleStore(id: number) {
  let indexToSlice = articleForm.value.associatedArticles.findIndex(
    (p) => p.idToArticle == id
  );
  articleForm.value.associatedArticles.splice(indexToSlice, 1);
}
</script>

<style lang="scss">
.badge {
  justify-content: center;
}

.body--dark {
  .title-article {
    color: white;
    &a {
      &,
      &:visited {
        color: white;
      }
    }
  }
}
.title-article {
  font-family: "CooperHewitt-Semibold", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  color: $dark;
  text-decoration: none;
  &a {
    &:visited {
      color: $dark;
    }
    color: $dark;
  }
}

.icon-cart {
  margin-left: 10px;
  background-color: white !important;
}

.rounded-chip {
  border-radius: 50%;
  height: 3em;
  width: 3em;
  display: inline-block;
  text-align: center;
  line-height: 3em;
  font-size: 16px;
  border: 1px solid;
  border-color: var(--q-primary);
}
</style>
