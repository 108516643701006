import { createApp, markRaw } from "vue";
import { Quasar } from "quasar";
import "./datePrototype";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { PiniaSharedState } from "@/stores/piniaSharedState";
import router from "@/router";
import i18n, { i18n as i18nInstance } from "@/i18n";
import pinia from "@/stores";
import { quasarOptions } from "@/plugins/quasar";
import { apm } from "@elastic/apm-rum";
import { createKeycloak, vueKeycloak } from "@/plugins/keycloak";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import BusPlugin from "@/plugins/bus";

import { conf_facility_current } from "@/constants";

// Services
import { authService } from "@/services/AuthService";

// DevExtreme Theme
import "./vendors/devextreme-theme.css";
import "./vendors/devextreme-dark-theme.css";

// DevExtreme Config
import config from "devextreme/core/config";

// Import icon libraries
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";

// Import Quasar css
import "quasar/src/css/index.sass";
import "quasar/src/css/flex-addon.sass";
import Particles from "@tsparticles/vue3";
import { loadFull } from "tsparticles";

import { Bar, Line } from "vue-chartjs";

// Styles génériques
import "./styles.scss";

// Vue Components
import App from "./App.vue";

config({
  forceIsoDateParsing: false,
});

pinia.use(
  PiniaSharedState({
    enable: false,
    initialize: false,
  })
);
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

createKeycloak({
  onReady(keycloak) {
    authService
      .setup(keycloak)
      .then(() => {
        const app = createApp(App);
        app.config.performance = true;
        app
          .use(pinia)
          .use(CKEditor)
          .use(Quasar, quasarOptions)
          .use(i18n)
          .use(router)
          .use(vueKeycloak)
          .use(BusPlugin)
          .use(Particles as any, {
            init: async (engine) => {
              await loadFull(engine);
            },
          })
          .use(ApmVuePlugin, {
            router: router,
            config: {
              serviceName: "Elcano_website",
              serverUrl: "https://apmrum.indigo-net.com",
              serviceVersion: "1",
              environment: conf_facility_current,
              active: true,
              instrument: true,
            },
            captureEvents: true,
          })
          .mount("#app");

        app.component("BarChart", Bar);
        app.component("LineChart", Line);
        setAPMUserContext();
      })
      .catch(() => {
        alert(i18nInstance.t(`messageDialog.content.ApiUnavailable`));
        window.location.reload();
      });
  },
  onAuthRefreshSuccess() {
    authService.renewToken();
  },
});

/**
 * Set APM user context
 */
function setAPMUserContext() {
  console.log(apm);
  apm.setUserContext({
    id: authService.currentUser?.id.toString(),
    email: authService.currentUser?.email,
    username: authService.currentUser?.email,
  });
  apm.setCustomContext({
    ClientSessionID: generateGUID(),
  });
}

/**
 * Generate a GUID for the client session
 */
function generateGUID(): string {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);

      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}
