{
  "article": {
    "action": {
      "copyMainContent": "Coller le texte original",
      "notify": "Ajouter un commentaire",
      "removeMainMedia": "Supprimer l'illustration principale",
      "selectMainMedia": "Illustration principale",
      "setDefaultStandfirstFromFormat": "Remplacer le chapô par celui du format"
    },
    "dialogs": {
      "messages": {
        "btnNoReload": "Continuer mes modifications",
        "btnReload": "Charger les modifications",
        "btnSaveDraftAndReload": "Sauvegarder mon brouillon et charger les modifications",
        "cancel": "Annuler",
        "delete": "Voulez-vous vraiment supprimer cet article ?",
        "deleteDraftOnBtnCancel": "Vous avez un brouillon personnel en cours pour cet article. Voulez-vous le supprimer?",
        "error": "Essayer de rafraichir la page. Si le problème persiste, contacter le support.",
        "inactivityUnlock": "Vous ne semblez plus travailler sur cet article. Il a été déverrouillé.",
        "loadingDraft": "Une erreur s'est produite. Voulez-vous charger un brouillon ?",
        "loadingDraftOrArticle": "Vous avez certaines modifications en attente non sauvegardées sur cet article, souhaitez-vous les appliquer ou les supprimer ?",
        "loadingNoEditableDraftOrArticle": "Vous aviez des modifications en attente non sauvegardées sur cet article, malheureusement cet article a été modifié par quelqu'un d'autre entre temps et vous ne pouvez plus appliquer vos modifications.<br/><br/>Que souhaitez-vous faire de votre brouillon non sauvegardable ? <br/><br/> Date de votre brouillon: {draftDate}<br/>Date de la version sur le serveur:{articleDate} (il s'agit de la version à jour).",
        "save": "Sauvegarder",
        "unlock": "Cet article a été déverrouillé par {userName}. Rechargez la page pour accéder à la dernière version."
      },
      "titles": {
        "confirm": "Confirmation",
        "error": "Un problème est survenu au chargement de l'article",
        "loadingDraft": "Consulter ce brouillon personnel ?",
        "loadingDraftOrArticle": "Modifications en attente non sauvegardées",
        "loadingNoEditableDraftOrArticle": "Vous avez un brouillon non sauvegardable",
        "notifyArticleMustBeRefresh": "Cette langue a été modifiée par {userName}. Cliquez pour rafraichir l'article.",
        "unlock": "L'article a été déverrouillé.",
        "writeNow": "Voulez-vous écrire l'article maintenant ?"
      }
    },
    "disabledTriggers": {
      "Correction_RelireTraducteurReferent": "Aucun traducteur référent n'est assigné à cet article.",
      "Redaction_Relire": "Aucun relecteur n'est assigné à cet article.",
      "Relecture_RelireTraducteurReferent": "Aucun traducteur référent n'est assigné à cet article.",
      "rightMissing": "Vous n'avez pas le droit d'utiliser cette action."
    },
    "edit": {
      "titles": {
        "cms": "CMS",
        "contributions": "Contributions",
        "medias": "Illustrations",
        "parameters": "Paramètres de l'article",
        "settings": "Intérêts"
      }
    },
    "errors": {
      "getVersion": "Erreur lors de la récupération de la version de l'article.",
      "import": "Une erreur s'est produite lors de l'import, vérifier l'onglet log pour plus de détails.",
      "invalidDate": "Date invalide",
      "onRefresh": "Erreur lors du rafraichissement de l'article.",
      "onSave": "Une erreur s'est produite au moment de l'enregistrement. Cette version de l'article a été conservée dans les brouillons. Réessayez d'enregistrer cet article plus tard.",
      "onTrigger": "Erreur lors du changement de statut.",
      "shortOnSave": "Une erreur s'est produite au moment de l'enregistrement.",
      "takeLock": "Une erreur est survenue, nous n'avons pas pu verrouiller l'article pour vous. Nous vous conseillons de sauvegarder et de rafraichir votre page."
    },
    "fields": {
      "actions": "Actions",
      "articleUrl": "Url de l'article",
      "articleUrlShort": "URL",
      "articleUrlTooltip": "Url de l'article, cliquer pour copier",
      "associatedSerial": "Feuilleton associé",
      "contributions": "Contributions",
      "countArticles": "Articles: {0}",
      "countChars": "Signes: {0}",
      "countWords": "Mots: {0}",
      "countWordsReverse": "<b>{0}</b> mots",
      "countries": "Pays",
      "datePublication": "Date de publication",
      "datePublicationWithTime": "Date de publication (à {time})",
      "deadline": "Deadline",
      "event": "Evénement",
      "format": "Format",
      "formats": "Formats",
      "fridge": "Frigo",
      "fridgeTooltip": "Le mode Frigo permet de ne pas spécifier de date de publication sur cet article, vous retrouver ces articles dans la vue Frigo",
      "groups": "Groupes",
      "helpOpenEdition": "Ouvrir la liste d'articles de l'édition",
      "id": "ID",
      "idBackOffice": "ID Indigo",
      "idContentElcanoTooltip": "ID du contenu dans Elcano, cliquer pour copier",
      "idContentWorkflow": "ID contenu",
      "idIndigoTooltip": "ID de l'article back office (sites, magellan,etc...), cliquer pour copier",
      "idWorkflow": "ID article",
      "idWorkflowOrContent": "ID article ou contenu",
      "lastAssociatedArticle": "Dernier enrichissement",
      "lastModification": "Dernière modification",
      "mainSection": "Rubrique principale",
      "medias": "Illustrations",
      "nbAssociatedArticles": "Nombre d'enrichissements",
      "online": "En ligne",
      "period": "Définir une période",
      "pigiste": "Pigiste",
      "pigisteSelect": "Pigiste (sélection obligatoire)",
      "publication": "Publication",
      "publishedOn": "Date de publication",
      "publishedOnTranslation": "Date de publication de la traduction",
      "publishedOnTranslationAtSameDate": "Publier la traduction en même temps",
      "publishedOnTranslationLabel": "Date de publication de la traduction",
      "publishedOnTranslationWithTime": "Date de publication de la traduction (à {time})",
      "score": "Score",
      "secondariesSections": "Rubriques secondaires",
      "sections": "Rubriques",
      "sectionsAndCountries": "Rub. Pays",
      "seoTitle": "Titre SEO",
      "serialUrl": "Url du feuilleton",
      "shortEndDate": "Fin",
      "shortStartDate": "Debut",
      "shortTitle": "Titre court",
      "signAndWords": "Longueur",
      "standfirst": "Chapô",
      "state": "Etat",
      "status": "Status de l'article",
      "summary": "Résumé personnalisé",
      "summaryOf": "Date de sommaire par défaut",
      "summaryOfLabel": "Date de sommaire souhaitée",
      "summaryOfTooltip": "Permet de paramétrer une date d'apparition dans le sommaire différente de la date de publication (utile pour les alertes)",
      "summaryOfTranslation": "Date de sommaire traduction par défaut",
      "summaryOfTranslationLabel": "Date de sommaire souhaitée pour la traduction",
      "text": "Texte",
      "title": "Titre de l'article",
      "tweet": "Tweet"
    },
    "helpOnNewStates": {
      "Correction": "Un mail a été envoyé à l'auteur.",
      "Relecture": "Un mail a été envoyé au relecteur.",
      "RelectureTraducteurReferent": "Un mail a été envoyé au traducteur référent.",
      "RelectureWithoutNotification": "Le statut de l'article a été changé. N'oubliez pas de notifier un relecteur.",
      "Saved": "Le statut de l'article a été changé."
    },
    "helpStates": {
      "history": "Aucune actions possible sur la consultation d'un historique.",
      "locked": "Impossible de changer le statut de cet article. Il est en cours de modification par une autre personne.",
      "noInterest": "Il n'y a aucun intéret associé.",
      "notEnoughAssociatedArticles": "Il n'y a pas assez d'enrichissements associés.",
      "notSaved": "Impossible de changer le statut de cet article, vous devez le sauvegarder avant de changer le statut",
      "online": "L'article est en ligne."
    },
    "helpTriggers": {
      "DemanderTraduction": "Repasser le statut à 'En attente de traduction'."
    },
    "labels": {
      "articleCount": "entrée | entrée | entrées",
      "btnAddLang": "Ajouter une langue",
      "btnCancel": "Annuler",
      "btnChangeLang": "Changer la langue de l'article",
      "btnClose": "Fermer",
      "btnContinuePreview": "Poursuivre",
      "btnCreate": "Créer l'article",
      "btnDelete": "Supprimer",
      "btnDownloadPDF": "Télécharger",
      "btnDuplicate": "Dupliquer",
      "btnEdit": "Editer l'article",
      "btnGenerateEmail": "Envoyer par email",
      "btnGenerateMailToolTip": "Dernier envoi le {dateSend}",
      "btnGeneratePdfIssueToolTip": "Générer l'édition en PDF de cette édition",
      "btnLockIssueToolTip": "Bloquer cette édition, il ne sera plus possible d'ajouter ou supprimer des articles",
      "btnModeDaily": "Quotidien",
      "btnModeDailyTooltip": "Affiche les articles d'une édition journalière",
      "btnModeDates": "Dates personnalisées",
      "btnModeDatesTooltip": "Permet de sélectionner une date de début et une date de fin",
      "btnModeFride": "Frigo",
      "btnModeFrideTooltip": "Article non planifié et en attente",
      "btnModePlanning": "Planning",
      "btnModePlanningTooltip": "Affiche les articles sur la semaine",
      "btnNew": "Créer un article",
      "btnNextEditionToolTip": "Edition suivante",
      "btnNonValid": "Non",
      "btnOpenEditionOnWebsiteTooltip": "Voir l'édition sur le site",
      "btnPreview": "Preview",
      "btnPreviewPDF": "Prévisualiser",
      "btnPreviousEditionToolTip": "Edition précédente",
      "btnPrintArticlesTooltip": "Imprimer les articles",
      "btnReset": "Reset",
      "btnSave": "Enregistrer",
      "btnSettings": "Réglages",
      "btnShow": "Ouvrir l'article",
      "btnSplitView": "Affichage côte à côte",
      "btnStatus": "Changement de statut",
      "btnUnlockIssueToolTip": "Débloquer cette édition, il sera possible d'ajouter ou supprimer des articles",
      "btnValid": "Oui",
      "characterCount": "signe | signe | signes",
      "cmsWarnModifications": "En cas de modification sur la signature ou sur le paramètre 'Vient de paraître', réimporter l'autre langue.",
      "editionOf": "Edition du",
      "manageLanguage": "Gestion des langues",
      "missingMainSection": "Aucune rubrique principale",
      "notImportedYet": "La preview de ces articles n'est pas possible car ils n'ont pas été importés : {titles}",
      "publishedAndSummaryInfo": "Publié le {publishedOn} - Sommaire du {summaryOf}",
      "publishedInfo": "Publié le {publishedOn}",
      "selectionLength": "Sélection : {count} signe | Sélection : {count} signe | Sélection : {count} signes",
      "shortBtnDelete": "Supprimer",
      "shortBtnEdit": "Editer",
      "shortBtnNew": "Créer",
      "shortBtnStatus": "Statut",
      "summaryInfo": "Sommaire du {summaryOf}",
      "switchMainLanguage": "Intervertir la langue principale",
      "toggleAuthorSignature": "Faire apparaitre la signature de l'auteur",
      "toggleAuthorSignatureWarn": "En cas de modification sur la signature, réimporter l'autre langue.",
      "toggleFreelanceSignature": "Faire apparaitre la signature du pigiste",
      "wordCount": "mot | mot | mots"
    },
    "locks": {
      "errors": {
        "onRefresh": "Nous ne sommes par parvenus à savoir si d'autres utilisateurs travaillent actuellement sur cet article. Vous pouvez continuer de travailler."
      },
      "message": "Libérer cet article",
      "reasons": {
        "deleted": "Aucune actions possible sur la consultation d'un article supprimé.",
        "history": "Aucune actions possible sur la consultation d'un historique.",
        "lockedByAnotherUser": "L'article est en cours de modification par {0}.",
        "obsolete": "Vous n'avez pas la dernière version de l'article.",
        "reviewOnlyByRedactor": "Les relectures ne peuvent être réalisé que par un rédacteur de la publication ou un éditeur.",
        "rightToSave": "Vous n'avez pas le droit de sauvegarder ce contenu.",
        "translatorCannotEditMainLanguage": "Les traducteurs ne peuvent pas modifier l'article dans sa langue d'origine."
      }
    },
    "messages": {
      "addLangDisabledDraft": "Vous ne pouvez pas ajouter une langue sur un brouillon.",
      "addLangDisabledModifiedByAnother": "Vous ne pouvez pas ajouter une langue à cet article car des modifications sont en cours sur l'article par : {users}.",
      "addLangDisabledModifiedByYou": "Vous ne pouvez pas ajouter une langue à cet article car des modifications sont en cours sur l'article par vous.",
      "created": "Le nouvel article a bien été créé",
      "deleted": "L'article a bien été supprimé",
      "draftEditable": "Vous êtes sur un article avec des modifications non sauvegardées. Merci de les sauvegarder le plus rapidement possible avant qu'une personne n'édite cet article.",
      "draftNoEditable": "Vous êtes sur un article avec des modifications qui ne peuvent pas être enregistrées. Merci de copier vos modifications sur l'article à jour.",
      "hiddenPropWithValue": "Attention, le champ suivant n'apparaît pas sur Elcano, mais son contenu sera visible en ligne : {properties} | Attention, les champs suivants n'apparaissent pas sur Elcano, mais leurs contenus seront visibles en ligne : {properties}",
      "newNotifications": "Une nouvelle notification sur l'article a été publiée",
      "translationDoesNotExists": "La traduction de l'article n'existe pas",
      "updated": "L'article a bien été mis à jour",
      "warnLongFormatPublisedOn": "Pour modifier la date de parution de cet article, s'adresser aux responsables des formats longs."
    },
    "rules": {
      "title": "Veuillez saisir le titre de l'article"
    },
    "states": {
      "All": "Tous",
      "AttenteCorrectionSr": "SR Attente Correction",
      "AttenteTraduction": "Attente Traduction",
      "Correction": "Correction",
      "CorrectionSr": "SR Correction",
      "Importation": "Importation",
      "ImportationEnErreur": "Importation En Erreur",
      "Importe": "Importé",
      "Invalidation": "Invalidation",
      "InvalidationEnErreur": "Invalidation En Erreur",
      "Invalide": "Invalidé",
      "Pitch": "Pitch",
      "Redaction": "Rédaction",
      "Relecture": "Relecture",
      "RelectureTraducteurReferent": "Relecture Traducteur Referent",
      "Traduction": "En traduction"
    },
    "tabs": {
      "article": "article",
      "enrichissements": "intérêts ({interestCount}) et enrichissements ({associatedArticlesCount})",
      "historique": "historique",
      "logs": "logs d'import",
      "mainMedia": "Principale",
      "medias": "illustrations ({count})",
      "notifications": "Commentaires généraux ({count})",
      "parametres": "Réglages article"
    },
    "tags": {
      "deleted": "Supprimé",
      "draft": "Brouillon",
      "unchangeable": "Non modifiable"
    },
    "triggers": {
      "Corriger": "Renvoyer au traducteur/rédacteur pour modification",
      "CorrigerSr": "Commencer la correction/edition",
      "DemanderCorrectionSr": "Envoyer au staff édition",
      "DemanderTraduction": "Envoyer au staff traduction",
      "Importer": "Importer ou réimporter l'article",
      "Invalider": "Invalider l'article",
      "Pitcher": "Passer l'article en statut pitch",
      "Rediger": "Passer l'article en statut à rediger",
      "RejeterImportation": "Rejeter l'importation",
      "RejeterInvalidation": "Rejeter l'invalidation",
      "Relire": "Demander la relecture de l'article",
      "RelireTraducteurReferent": "Relire Traducteur Referent",
      "RenvoyerAuRelecteur": "Renvoyer au relecteur",
      "RenvoyerAuTraducteur": "Renvoyer au traducteur",
      "RenvoyerAuTraducteurReferent": "Renvoyer au traducteur référent",
      "Republier": "Republier cet article",
      "Rouvrir": "Rouvrir l'article et l'envoyer au rédacteur",
      "Traduire": "Commencer la traduction",
      "ValiderImportation": "Forcer l'importation en OK",
      "ValiderInvalidation": "Forcer l'invalidation"
    }
  },
  "articleContribution": {
    "errors": {
      "cannotEditLanguage": "Vous ne pouvez pas modifier cet article pour l'instant.",
      "cannotEditParams": "Les paramètres ne sont pas modifiable, impossible de vous ajouter en tant que relecteur traducteur référent.",
      "onSaveAmount": "Erreur lors de la modificaiton d'une contribution"
    },
    "fields": {
      "amount": "Montant",
      "contributionType": "Contribution",
      "contributor": "Contributeur",
      "contributors": "Contributeurs",
      "language": "Langue"
    },
    "labels": {
      "actionDisableBeforeSave": "Sauvegarder avant de pouvoir effectuer une action",
      "btnAccept": "Accepter",
      "btnAdd": "Ajouter",
      "btnAddAmount": "Entrer le montant",
      "btnDelete": "Supprimer",
      "btnEditorReviewOk": "Relecture bouclage Ok",
      "btnEditorReviewReset": "Réinitialiser relecture bouclage",
      "btnEditorReviewStart": "Démarrer la relecture bouclage",
      "btnNotify": "Notifier",
      "btnReject": "A retravailler",
      "btnReviewing": "Relecture en cours",
      "btnStart": "Je relis",
      "dontForgetToNotify": "N'oubliez pas de notifier l'un des relecteurs en cliquant sur son nom",
      "leadTranslatorToAssign": "Traducteur référent",
      "notified": "Le contributeur a été notifié"
    },
    "popupAmount": {
      "btnCancel": "Annuler",
      "btnSave": "Enregistrer",
      "title": "Veuillez saisir le montant de la pige:"
    }
  },
  "articleSetting": {
    "dialogs": {
      "messages": {
        "delete": "Êtes-vous sûre de vouloir supprimer le articleSetting ?"
      },
      "titles": {
        "barAutoInterest": "Voici les intérêts trouvés via le texte:",
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer le articleSetting",
      "delete": "Impossible de supprimer le articleSetting",
      "getInterestFromText": "Erreur lors de la récupération des intérêts via le texte.",
      "notFound": "Le articleSetting n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour le articleSetting"
    },
    "fields": {
      "active": "Paramètre actif",
      "cms": "Paramètres CMS",
      "id": "Id du paramètre article",
      "idType": "Type",
      "interest": "Intérêts",
      "labelEn": "Intitulé du paramètre en anglais",
      "labelFr": "Intitulé du paramètre en français",
      "mainLabel": "Intitulé principal du paramètre",
      "shortActive": "Actif",
      "shortId": "id",
      "shortKey1": "Clef 1",
      "shortLabelEn": "Nom en anglais",
      "shortLabelFr": "Nom en français",
      "shortMainLabel": "Nom principal",
      "shortSearch": "Recherche",
      "shortSubType": "Type d'intérêts",
      "shortType": "Type",
      "subType": "Sous type"
    },
    "labels": {
      "btnAutoInterest": "Intérêts auto.",
      "btnCreate": "Créer le paramètre",
      "btnDelete": "Supprimer le paramètre",
      "btnEdit": "Enregistrer le paramètre",
      "btnNew": "Créer un nouveau paramètre",
      "lblTitle": "Paramêtres et intérêts des articles",
      "shortBtnDelete": "Supprimer",
      "shortBtnNew": "Créer"
    },
    "messages": {
      "created": "Le nouveau articleSetting a bien été créé",
      "deleted": "Le articleSetting a bien été supprimé",
      "updated": "Le articleSetting a bien été mis à jour"
    }
  },
  "articleTweet": {
    "errors": {
      "onSaveTweet": "Erreur lors de la sauvegarde du tweet"
    },
    "labels": {
      "btnCancel": "Annuler",
      "btnSave": "Sauvegarder",
      "btnUpdate": "Modifier le tweet"
    },
    "popupUpdate": {
      "title": "Modifier le tweet"
    }
  },
  "assignment": {
    "dialogs": {
      "messages": {
        "delete": "Êtes-vous sûre de vouloir supprimer le assignment ?"
      },
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer le assignment",
      "delete": "Impossible de supprimer le assignment",
      "notFound": "Le assignment n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour le assignment"
    },
    "fields": {
      "editor": "Editeur",
      "freelance": "Pigiste",
      "id": "Id du assignment",
      "initialConfig": "Assignement intégré non modifiable",
      "labelEn": "Intitulé du assignment en anglais",
      "labelFr": "Intitulé du assignment en français",
      "redactor": "Rédacteur",
      "shortAssignments": "Assignments",
      "shortId": "id",
      "shortLabelEn": "Nom en anglais",
      "shortLabelFr": "Nom en français",
      "shortSearch": "Recherche",
      "translator": "Traducteur"
    },
    "labels": {
      "btnCancel": "Annuler",
      "btnDelete": "Supprimer le assignment",
      "btnEdit": "Enregistrer le assignment",
      "btnNew": "Créer un nouveau assignment",
      "btnOk": "OK"
    },
    "messages": {
      "created": "Le nouveau assignment a bien été créé",
      "deleted": "Le assignment a bien été supprimée",
      "updated": "Le assignment a bien été mis à jour"
    }
  },
  "associatedArticles": {
    "addAssociatedArticlesTitle": "Enrichissement(s) ajouté(s) lors de la sauvegarde:",
    "autoAssociation": "Enrich. auto",
    "currentAssociatedArticlesTitle": "Les enrichissements actuels:",
    "dialogs": {
      "btnAdd": "Ajouter",
      "btnCancel": "Annuler",
      "btnClose": "Fermer",
      "noSuggestedArticles": "Nous n'avons trouvé aucun nouvel enrichissement correspondant au texte de l'article",
      "noSuggestedInterest": "Nous n'avons trouvé aucun nouvel intérêt correspondant au texte de l'article",
      "suggestedAssociationArticles": "Voici les enrichissements trouvés:"
    },
    "labelBtnSearchArticles": "Rechercher des articles",
    "labelChipNoChosenArticles": "Aucun article sélectionné.",
    "labelStep2": "Enregistrer l'article pour que les intérêts soient pris en compte dans l'enrichissement auto",
    "tooltipNoFrenchContent": "Action possible uniquement sur le français"
  },
  "ckeditor": {
    "ai": {
      "commands": {
        "Journalism": "Journalisme",
        "improveWriting": "Améliorer l'écriture",
        "makeShorter": "Raccourcir",
        "translateToEnglish": "Traduire en anglais",
        "translateToFrench": "Traduire en français"
      },
      "groups": {
        "changeStyle": "Changer le style",
        "editOrReview": "Modifier ou réviser",
        "translate": "Traduire"
      }
    },
    "dialogs": {
      "titles": {
        "shortcuts": "Liste des raccourcis clavier"
      }
    },
    "shortcuts": {
      "labels": {
        "caps": "Transformer en capitale",
        "commentaire": "Ajouter un commentaire",
        "exposant": "Mettre en exposant",
        "gras": "Mise en gras",
        "indice": "Mettre en indice",
        "intertitre": "Appliquer le style intertitre",
        "italique": "Mise en italique",
        "lien": "Ajouter un lien",
        "lower": "Transformer en minuscule",
        "miseEnForme": "Retirer la mise en forme",
        "print": "Imprimer",
        "rayer": "Rayer",
        "retraitSurlignage": "Supprimer le surligner",
        "souligner": "Souligner",
        "surlignageBleu": "Surligner en bleu",
        "surlignageJaune": "Surligner en jaune",
        "surlignageRouge": "Surligner en rouge",
        "surlignageVert": "Surligner en vert",
        "switchDisplay": "Basculer entre les modes d'affichage",
        "text": "Appliquer le style texte simple",
        "trackchanges": "Activer / Desactiver le suivi de modification",
        "unbreakableSpace": "Espace insécable"
      }
    }
  },
  "common": {
    "dayOfWeek": {
      "1": "Lundi | Lundis",
      "2": "Mardi | Mardis",
      "3": "Mercredi | Mercredis",
      "4": "Jeudi | Jeudis",
      "5": "Vendredi | Vendredis",
      "6": "Samedi | Samedis",
      "7": "Dimanche | Dimanches"
    },
    "labels": {
      "autoRefresh": "Mise à jour auto ({seconds}s)",
      "btnDisconnect": "Se déconnecter",
      "friday": "Vendredi",
      "loading": "Chargement",
      "monday": "Lundi",
      "thursday": "Jeudi",
      "tuesday": "Mardi",
      "wednesday": "Mercredi"
    }
  },
  "contribution": {
    "freelances": {
      "buttons": {
        "cancelProcessed": "Annuler le traitement",
        "lock": "Verrouiller",
        "refreshLock": "Verrouiller piges manquantes",
        "save": "Enregistrer",
        "setProcessed": "Marquer comme traitées",
        "unlock": "Déverrouiller"
      },
      "labels": {
        "cancelDate": "Date d'annulation",
        "cancelProcessOnArticle": "Annuler le traitement sur l'article:",
        "inputDate": "Date limite de parution",
        "lock": "Vous avez verrouillé {0} piges pour une montant total de {1} euros.",
        "noLocks": "Aucune pige n'est verrouillée."
      },
      "notifications": {
        "canceled": "Le traitement de {0} piges pour un montant total de {1} euros a bien été annulé.",
        "errorCancel": "Erreur lors de l'annulation du traitement des piges.",
        "errorLock": "Erreur lors du verrouillage des piges.",
        "errorLockYear": "Erreur lors du verrouillage de l'année.",
        "errorOnSaveBudgets": "Erreur lors de la sauvegarde des budgets.",
        "errorProcessed": "Erreur lors de la modification des piges.",
        "errorUnlock": "Erreur lors du déverrouillage des piges.",
        "errorUnlockYear": "Erreur lors du déverrouillage de l'année.",
        "locked": "Les piges ont bien été verrouillées.",
        "newLock": "{nbPiges} piges supplémentaires ont été verrouillées.",
        "processed": "Les piges ont bien eté marquées traitées!",
        "savedBudgets": "Budgets sauvegardés!",
        "unlock": "Les piges ont bien été déverrouillées!"
      },
      "titles": {
        "actions": "Actions:",
        "budget": "Budget",
        "caseOfError": "En cas d'erreur",
        "consumption": "Consommation",
        "freelanceChoice": "(Optionnel) Choisir un pigiste:",
        "lockYear": "Verrouiller l'année :",
        "management": "Gestion",
        "parameters": "Paramètres de verrouillage:"
      }
    }
  },
  "contributionType": {
    "Author": "Auteur",
    "Editor": "Editeur",
    "Freelancer": "Pigiste",
    "LeadTranslator": "Traducteur référant",
    "Reviewer": "Relecteur",
    "Translator": "Traducteur",
    "Unknown": "Inconnu"
  },
  "country": {
    "dialogs": {
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer le pays",
      "notFound": "Le pays n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour le pays"
    },
    "fields": {
      "active": "Pays actif",
      "code": "Code du pays",
      "codeISO": "Code iso du pays",
      "hashtagEn": "Hashtag du pays en anglais",
      "hashtagFr": "Hashtag du pays en français",
      "labelEn": "Intitulé du pays en anglais",
      "labelFr": "Intitulé du pays en français",
      "realCountry": "Pays éxistant",
      "shortActive": "Actif",
      "shortCode": "Code",
      "shortCodeISO": "Code ISO",
      "shortId": "id",
      "shortLabelEn": "Nom en anglais",
      "shortLabelFr": "Nom en français",
      "shortSearch": "Recherche"
    },
    "labels": {
      "btnCreate": "Créer le pays",
      "btnEdit": "Enregistrer le pays",
      "btnNew": "Créer un nouveau pays",
      "lblTitle": "Listing des pays",
      "shortBtnNew": "Créer"
    },
    "messages": {
      "created": "Le nouveau pays a bien été créé",
      "updated": "Le pays a bien été mis à jour"
    }
  },
  "draft": {
    "dialogs": {
      "messages": {
        "delete": "Note: votre dernier brouillon supprimé peut être consulté dans la section 'Brouillon > Voir mes derniers brouillons supprimés'"
      },
      "titles": {
        "confirm": "Confirmer la suppression de votre brouillon"
      }
    },
    "fields": {
      "actions": "Actions",
      "id": "id",
      "lastModifications": "Dernières modifications",
      "publication": "Publication",
      "shortSearch": "Recherche",
      "title": "Titre"
    },
    "labels": {
      "btnDelete": "Non et supprimer ce brouillon",
      "btnDraftLoadLast": "Je souhaite ouvrir l'article mais garder mon brouillon",
      "btnDraftLoadLastAndRemove": "Je souhaite ouvrir la dernière version de l'article et supprimer mon vieux brouillon",
      "btnDraftLoadMyDraft": "Consulter le brouillon non sauvegardable",
      "btnLoadLast": "Ouvrir la version du serveur et supprimer mes modifs en attente",
      "btnLoadMyDraft": "Appliquer mes modifications en attente",
      "lblTitle": "Liste de mes brouillons:",
      "lbltgShowDeleted": "Voir mes derniers brouillons supprimés"
    },
    "messages": {
      "deleted": "Le brouillon a bien été supprimé"
    }
  },
  "exceptions": {
    "ArticleLongFormatException": {
      "AlreadyPublished": "L'article a déjà été publié.",
      "ArticleAlreadyPushedImpossibleToDeleteOrFreeze": "L'article a déjà été publié, il est impossible de le supprimer ou de le geler.",
      "ArticleLocked": "L'article est verrouillé par une autre personne.",
      "Conflict": "Le déplacement de l'article rentre en conflit avec la série.",
      "InvalidAuthorsNumber": "Le nombre d'article ne correspond pas au nombre d'auteurs.",
      "InvalidPublishedDate": "Vous essayez de déplacer un article dont la date de parution a déjà changé.",
      "NextDatePublishedError": "Le calcul de la prochaine date de parution a échoué.",
      "NoRights": "Adressez-vous aux responsables des formats longs de votre publication.",
      "default": "Erreur lors de la manipulation d'un article ou de la série."
    },
    "ArticleNotFoundException": {
      "NotFoundByUrl": "L'url {url} ne correspond à aucun article.",
      "TranslationInvalidated": "La traduction de l'article a été invalidée.",
      "TranslationNotFound": "L'article n'a pas été traduit.",
      "default": "L'article '{id}' n'existe pas."
    },
    "ArticleSaveException": {
      "ArticleLocked": "L'article est verrouillé par une autre personne.",
      "ContributionLangMissing": "La langue n'a pas été renseigné pour l'un des relecteurs.",
      "ContributionLocked": "Vous avez tenté de modifier une contribution qui ne peut plus l'être.",
      "ContributorMissing": "Il manque un contributeur sur une des contributions.",
      "EditionLocked": "L'édition est verrouillée",
      "Importing": "L'article est en cours d'importation.",
      "MainContentDoesntExist": "Le contenu a traduire n'existe pas.",
      "NotAuthor": "Vous n'êtes ni l'auteur de l'article ni redacteur chef",
      "NotLeadTranslator": "Vous n'êtes pas traducteur référent de cet article, vous ne pouvez pas effectuer de modifications.",
      "NotReviewer": "Vous n'êtes pas relecteur de cet article, vous ne pouvez pas effectuer de modifications.",
      "SaveError": "Une erreur est survenue lors de la sauvegarde de l'article.",
      "SwitchMainLanguageAlreadyHaveTwoContent": "L'article existe déjà dans les deux langues. Impossible de réaliser l'opération.",
      "TranslationExists": "La traduction existe déjà.",
      "TranslatorExists": "Un traducteur est déjà défini.",
      "UpdateByAnotherUser": "Vous avez modifié une version obsolète de l'article, un autre utilisateur a modifié cet article entretemps, vous ne pouvez donc pas sauvegarder/écraser ses modifications.",
      "UserDisconnected": "Vous êtes déconnecté."
    },
    "ContributionSaveException": {
      "CantChangeStateOnOtherUserContribution": "Seul le contributeur peut modifier le statut de sa contribution.",
      "DoesntExist": "La contribution n'existe pas.",
      "SaveError": "Erreur lors de la sauvegarde de la contribution.",
      "default": "Erreur lors de la sauvegarde de la contribution."
    },
    "GenerationEmailException": {
      "ArticleNotOnline": "La date de parution de l'article est ultérieure à la date de planification de l'alerte.",
      "Error": "Une erreur inconnue est survenue. Rapprochez vous du service informatique.",
      "HistoAlreadyExists": "L'historique existe déjà. Un autre envoi est peut être déjà planifié.",
      "InexistentArticle": "L'article a envoyé n'existe pas. Verifier qu'il a bien été importé.",
      "NoContacts": "Aucun contact n'a été trouvé pour cet envoi.",
      "NoMessage": "Il n'y a pas d'email disponible pour ce type d'envoi.",
      "NotAvailableAM": "La planification ne peut pas se faire car une autre planification est en cours. Veuillez réessayer dans quelques minutes.",
      "PlanifDateError": "La date de planification est incorrecte. Elle doit être supérieure à la date de parution de l'article.",
      "SaveFailed": "La sauvegarde de l'email a échoué",
      "TagAlreadyExists": "Le tag de tracking existe déjà. Rapprochez vous du service informatique.",
      "UpdateFailed": "La mise à jour de l'email a échoué.",
      "default": "Une erreur inconnue est survenue. Rapprochez vous du service informatique."
    },
    "MediaSaveException": {
      "default": "Impossible de sauvegarder l'illustration {filePath}"
    },
    "NotAllowedException": {
      "WithPublication": "Vous n'avez pas le droit '{right}' sur la publication {publication}.",
      "WithoutPublication": "Vous n'avez pas le droit '{right}'."
    },
    "UserNotFoundException": {
      "default": "L'utilisateur n'existe pas."
    },
    "UserSaveException": {
      "DuplicateLogin": "Le login est déjà utilisé par un autre utilisateur.",
      "SaveError": "Une erreur est survenue lors de la sauvegarde de l'utilisateur.",
      "UserNotFound": "L'utilisateur n'existe pas."
    },
    "WorkflowFactoryException": {
      "ArticleNotFound": "L'article n'existe pas",
      "UserDisconnected": "Vous n'êtes pas connecté.",
      "VersionMismatch": "Vous ne disposez pas de la dernière version de l'article.",
      "default": "Impossible de réaliser l'action sur l'article."
    },
    "WorkflowStateMachineException": {
      "ContentLocked": "Le contenu est verrouillé par un autre utilisateur : {user}.",
      "GuardNotValidForTrigger": "L'action '{trigger}' est valide pour l'état '{state}', mais la condition '{guard}' n'était pas rempli.",
      "InvalidLanguage": "La langue n'est pas valide ({language}).",
      "MissingParameter": "L'action '{trigger}' doit être accompagné d'un paramètre.",
      "TranslatorCannotEditMainLanguage": "L'action '{trigger}' ne peut pas être réalisé par un traducteur sur la langue principale.",
      "TriggerNotValidForState": "L'action '{trigger}' n'est valide pour l'état '{state}'.",
      "default": "Impossible de réaliser l'action sur l'article."
    }
  },
  "export": {
    "buttons": {
      "export": "Exporter"
    },
    "fields": {
      "endDate": "Date parution : fin",
      "freelancer": "Pigiste",
      "publication": "Publication",
      "startDate": "Date parution : début",
      "translator": "Traducteur"
    }
  },
  "flags": {
    "en-EN": "🇬🇧",
    "fr-FR": "🇫🇷"
  },
  "format": {
    "dialogs": {
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer le format",
      "notFound": "Le format n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour le format"
    },
    "fields": {
      "active": "Format actif",
      "id": "Id du format",
      "labelEn": "Intitulé du format en anglais",
      "labelFr": "Intitulé du format en français",
      "long": "Format long",
      "maxLength": "Nombre de caractères maximum",
      "shortActive": "Actif",
      "shortId": "id",
      "shortLabel": "Nom du format",
      "shortLabelEn": "Nom en anglais",
      "shortLabelFr": "Nom en français",
      "shortLong": "Long",
      "shortSearch": "Recherche"
    },
    "labels": {
      "btnAddPublication": "Ajouter une publication",
      "btnCreate": "Créer le format",
      "btnEdit": "Enregistrer le format",
      "btnNew": "Créer un nouveau format",
      "lblTitle": "Listing des formats d'article",
      "shortBtnNew": "Créer"
    },
    "messages": {
      "created": "Le nouveau format a bien été créé",
      "updated": "Le format a bien été mis à jour"
    },
    "short": {
      "Article": "Art",
      "Article leader": "Lead",
      "Breve": "Br",
      "Clin d'oeil": "Clin",
      "Dossier": "Doss",
      "EchoVillage": "Gaz",
      "Enquete": "Enq",
      "Entourage": "Ent",
      "Evenement": "Evé",
      "Feuilleton": "Feuill",
      "FrontRow": "FRow",
      "Gazette": "Gaz",
      "Insider": "Ins",
      "Maitre Espion": "ME",
      "Mouvement": "Mouv",
      "NouvelleGarde": "Gaz",
      "SecretsPalais": "Gaz",
      "Spy Way Of Life": "Swol",
      "Video": "Vid",
      "Watchlist": "Gaz"
    }
  },
  "help": {
    "labels": {
      "ask": "<p>Pour poser une question ou signaler un bug, écrivez à <a href=\"mailto:elcanohelp{'@'}indigo.fr\">elcanohelp{'@'}indigo.fr</a></p><p>Vous pouvez aussi consulter la <a href='https://indigonet.sharepoint.com/sites/IndigoSquare/redaction/edition/SitePages/FAQ_Elcano.aspx' target='_blank'>FAQ Elcano</a> sur notre intranet.</p>",
      "news": "Nouveautés"
    },
    "sections": {
      "0": {
        "questions": {
          "1": {
            "content": "",
            "question": "Quelles sont les dernières nouveautés de l'application ?"
          }
        },
        "titleHeader": "Dernières modifications"
      },
      "1": {
        "questions": {
          "1": {
            "content": "Le correcteur orthographique (Prolexis) est accessible en cliquant sur le bouton en forme de “prisme” dans l'éditeur de texte. Il corrige l'ensemble du texte, y compris le titre et le chapô.",
            "question": "Où trouver le correcteur orthographique ?"
          },
          "2": {
            "content": "",
            "question": "Quels sont les raccourcis clavier ?"
          }
        },
        "titleHeader": "Editeur de texte"
      },
      "2": {
        "questions": {
          "1": {
            "content": "Le passage d'un statut à un autre se fait via une “action” qui peut être effectuée par un utilisateur ayant les droits requis. Certaines opérations ne peuvent être exécutées que si un type de contributeur est indiqué (exemple : passer un article du statut “En rédaction” au statut “A relire” nécessite qu'un relecteur soit désigné).",
            "question": "Comment fonctionnent les statuts et comment passer de l'un à l'autre ?"
          },
          "2": {
            "content": "Voici la liste des différents statuts que peut prendre un article. Chacun d'eux a une couleur qui lui est propre :",
            "question": "Quels sont les différents statuts d'un article ?"
          },
          "3": {
            "content0": "<p>Lorsqu'un article passe au statut 'Relecture', s'il n'existe qu'un seul relecteur, celui-ci sera automatiquement notifié par email.</p><p>S'il existe plusieurs relecteurs, c'est la responsabilité de l'auteur de notifier le premier relecteur. Cela en cliquant simplement sur le nom du relecteur présent au-dessus du titre de l'article.</p>",
            "content1": "<img src='changelog/EL-556-3.png'>",
            "content2": "<p>Lorsque vous êtes relecteur d'un papier, vous avez trois options en cliquant sur votre nom, 'Je relis' pour verrouiller tout de suite le papier pendant votre relecture, 'Accepter' et 'A retravailler' pour donner votre avis sur la relecture. Après cela, à vous de notifier le relecteur suivant ou envoyer le papier à l'auteur ou aux SR en fonction du statut des différentes relectures.</p><p>Un petit oeil peut apparaitre à côté du nom d'un relecteur, si celui-ci est au même moment en train d'effectuer des modifications sur l'article.</p>",
            "content3": "<img src='changelog/EL-556-2.png'><img src='changelog/EL-556-1.png'>",
            "content4": "<p>Les statuts de relecture sont automatiquement remis à zéro à chaque nouvelle relecture.</p>",
            "question": "Comment fonctionne la relecture ?"
          }
        },
        "titleHeader": "Fonctionnement du workflow"
      },
      "3": {
        "questions": {
          "1": {
            "content0": "<p>Lorsque vous mettez plusieurs critères sur une même catégorie, on recherche la présence d'au moins l'un des critères.</p><p>Si vous souhaitez rechercher tous les critères en même temps, il faut ajouter chaque critères sur une ligne à part à l'aide du bouton '+'.</p><p>Une explication de la recherche est disponible au survol du bouton rechercher.</p><p><u>Exemple:</u></p><p>Si vous souhaitez faire une recherche avec le nom Sarkozy <b>OU</b> Hollande, vous devez mettre ces deux critères sur la même ligne.</p><p>Si vous souhaitez faire une recherche avec le nom Sarkozy <b>ET</b> Hollande, vous devez mettre ces deux critères sur deux lignes différentes.</p>",
            "content1": "<img src='changelog/EL-615.png'>",
            "question": "Comment marche la recherche"
          }
        },
        "titleHeader": "Fonctionnement de la recherche"
      },
      "4": {
        "questions": {
          "1": {
            "content": "<p>Nouvelle vue dans Elcano, le planning des formats longs permet :</p><ul><li>de voir, selon les publications, ces sujets sur six mois.</li><li>d'apporter facilement des modifications à ce planning (ajouter plusieurs articles d'un coup, en déplacer plusieurs d'un coup).</li><li>de détecter les conflits d'agenda.</li></ul><p class='help-error'>Il vaut mieux créer et gérer les formats longs depuis cette interface : elle permet de créer des séries et de les déplacer de façon groupée, tout en recalculant les deadlines. Modifier les dates depuis les paramètres reste possible, mais, dans ce cas, il n'y a pas d'impact sur les autres formats du même type.</p><p>Les formats longs sont les suivants :</p><ul><li>LL : Enquêtes, Entourages</li><li>AI : Enquêtes, Entourages</li><li>IO : Maître-espion, Spy Way of Life</li></ul>",
            "question": "Introduction"
          },
          "2": {
            "content": "<p>On accède au planning via le menu d'Elcano. </p><img src='changelog/EL-693-1.png'><p>Les sujets y apparaissent tous, dans leur langue de création. </p><p>On n'y voit que les articles programmés les jours normaux de parution d'un format long (ex : si AI fait paraître une enquête un jeudi, on ne la voit pas ici).</p><p>Tout le monde peut le consulter et seuls les rédacteurs de la publication peuvent créer un article.</p><p>Seuls les responsables des formats longs peuvent y intervenir pour créer une série d'articles ou modifier des dates de parution.</p>",
            "question": "Accéder au planning"
          },
          "3": {
            "content": "<p>Ce planning introduit la notion de deadline. Elle est créée par défaut en fonction des formats. </p><p>Elle peut être modifiée. Elle est indicative.</p><img src='changelog/EL-693-2.png'><img src='changelog/EL-693-3.png'><p>Quand la deadline est proche ou passée, la couleur de l'article change sur la page d'accueil du rédacteur concerné.</p><img src='changelog/EL-693-4.png'><p>Une alerte apparaît si deux deadlines sont trop proches.</p><img src='changelog/EL-693-5.png'><p class='help-error'>Attention, si un format long est mis au frigo, la deadline est désactivée. Lorsque l'article est remis dans le circuit, il faut en recréer une. Ce n'est pas automatisable.</p>",
            "question": "Fixer une deadline"
          },
          "4": {
            "content": "<p>Lorsqu'on crée un article dans cette interface, seule les dates possibles de parution sont proposées.</p><p>Dans l'exemple ci-dessous, il n'est pas possible de créer un entourage le 8 mars puisqu'il y en a déjà un.</p><img src='changelog/EL-693-6.png'><p>Si une édition a été bloquée par le pôle édition (jour férié par exemple), la date n'apparaît pas dans les propositions. </p>",
            "question": "Créer un article"
          },
          "5": {
            "content": "<p>Cette fonctionnalité est réservée aux responsables des formats longs.</p><p>Il est possible de créer en une action une série d'articles du même format. A chaque fois qu'on clique sur Ajouter un article à la série, un nom est ajouté à la série, dans l'ordre alphabétique des prénoms. Ce nom peut être modifié avant la création de la série. </p><img src='changelog/EL-693-7.png'><p>On peut créer ces articles dans la langue qu'on veut.</p><img src='changelog/EL-693-8.png'><p>Par défaut, les articles en série sont créés dans une seule rubrique, qui pourra être modifiée par la suite.</p><ul><li>LL: Action publique</li><li>AI: Le continent</li><li>IO: Renseignement d'État</li></ul><p>Si on veut indiquer qu'une date est à pourvoir, on peut créer l'article avec n'importe quel auteur et retirer ensuite son nom. La case devient alors rouge.</p><img src='changelog/EL-693-9.png'>",
            "question": "Créer une série d'articles"
          },
          "6": {
            "content": "<p>Ne procéder aux changements de date que dans ce module. Rien de vous empêche de le faire depuis l'article mais sachez que, dans ce cas, il n'y aura pas de modification en série.</p><img src='changelog/EL-693-10.png'><p>Si vous voulez changer une date de parution en même temps que la deadline, utiliser le clic droit sur la carte du format concerné. Si vous voulez faire une manipulation plus fine, passez par les paramètres de l'article. Ici, si vous modifiez une date, la deadline ne bouge pas, et inversement.</p><p>Vous pouvez alors :</p><ul><li>changer la date de parution, le panneau suivant vous propose les dates de ce format. Si la date de destination est occupée, l'article qui y est est repoussé ou avancé, selon les cas, et les séries sont affectées. Ex : Si on repousse un article 1 de deux semaines, l'article 2 qui était présent la semaine suivante prend la place de 1 et l'article 3 deux semaines plus tard prend la place de 2.</li><li>repousser la série d'une semaine* dans le futur </li><li>avancer la série d'une semaine* dans le passé </li><li>supprimer l'article et avancer la série des articles qui suivent </li><li>mettre l'article au frigo (enlever la date de parution) et avancer la série des articles qui suivent.</li></ul><p>*ou d'un mois, selon le format</p><img src='changelog/EL-693-11.png'><p>S'il y a deux mêmes formats à la même date, on peut confirmer la date de l'un des deux, ça repousse l'autre et la série.</p>",
            "question": "Modifier la date d'un format long"
          },
          "7": {
            "content": "<p>Pour insérer un format long dans une liste existante, il faut d'abord créer la place, en repoussant d'une semaine tous les articles qui vont suivre. Puis créer le format long à la date libérée. Le panneau de création d'article la propose en premier.</p>",
            "question": "Insérer un format long dans une liste existante"
          }
        },
        "titleHeader": "Planning des formats longs"
      }
    },
    "states": {
      "mainLanguage": "Version originale",
      "translation": "Traduction"
    }
  },
  "history": {
    "labels": {
      "advanced": "Mode avancé",
      "allModifications": "Toutes les modifications",
      "btnOpen": "Ouvrir",
      "modificationsByUser": "Modifications par utilisateur",
      "modified": "Article modifié",
      "needToBeImported": "Cet article necessite d'être réimporté.",
      "origin": "Origine",
      "params": "Paramètres",
      "subtype": "Sous type",
      "type": "Type"
    }
  },
  "home": {
    "actions": {
      "editArticle": "Modifier l'article",
      "editFreelancers": "Modifier les pigistes",
      "previewArticle": "Prévisualiser l'article",
      "printArticlesTooltip": "Imprimer les articles",
      "setFreelanceAmount": "Saisir le montant de la pige"
    },
    "articlesAwaitingEditorCorrection": "En attente de correction SR",
    "articlesInEditorCorrection": "Correction SR",
    "articlesInErrorEditor": "Articles en erreur",
    "articlesLongFormat": "Les articles long format",
    "articlesPublishedWithoutInterests": "Articles parus sans intérêt ou sans enrichissement",
    "freelanceConsumption": "Consommation des piges {id}",
    "freelanceConsumptionBudget": "Budget: {budget} €",
    "freelancersWithoutAmount": "Piges à remplir",
    "freelancersWithoutId": "Pigistes sans identifiant",
    "myArticles": "Mes articles",
    "myCorrections": "Mes corrections",
    "myFreelancersWithoutAmount": "Mes piges à remplir",
    "myNextLongFormat": "Mes prochains longs formats",
    "myOnlineArticles": "Mes articles en ligne",
    "myReviews": "Mes relectures",
    "myTranslations": "Mes traductions",
    "myTranslationsCompleted": "Mes dernières traductions terminées",
    "myTranslationsToReview": "Mes traductions à relire",
    "onlineArticlesNotInImportedStatus": "Articles en ligne, mais pas au statut 'importé'",
    "translationsForNextEditionToReview": "Traductions de la prochaine édition à relire"
  },
  "import": {
    "errors": {
      "failed": "Echec",
      "previewFailed": "Erreur lors de la demande de preview de l'article {0}. Voir logs d'import."
    },
    "fields": {
      "articleId": "Identifiant de l'article",
      "articleIds": "Identifiant des articles (séparé par des virgules)",
      "endDate": "Fin",
      "eventId": "Identifiant de l'evenement",
      "informations": "Informations",
      "onlyUpdatedArticles": "Uniquement les articles mis à jour",
      "publications": "Publications",
      "startDate": "Debut"
    },
    "labels": {
      "btnExport": "Exporter",
      "btnImport": "Importer",
      "btnInfos": "Récupération des informations",
      "btnPost": "Post",
      "btnRefresh": "Rafraichir",
      "btnReset": "Reset",
      "btnRetry": "Retry"
    },
    "messages": {
      "exportArticles": "Export des articles en cours",
      "importArticles": "Import des articles en cours",
      "importArticlesSettings": "Import en cours",
      "importAssociatedArticles": "Import enrichissements",
      "importIllustrations": "Import en cours",
      "loadingInfosSuccess": "Chargement des infos OK",
      "retryImport": "Retry OK"
    },
    "tabs": {
      "articles": "Articles",
      "articlesIds": "Articles par Ids",
      "associatedArticles": "Enrichissements",
      "exportArticle": "Export Article",
      "illustrations": "Illustrations",
      "sections": "Sections",
      "settings": "Paramètres article",
      "suivi": "Suivi import",
      "users": "Utilisateurs"
    },
    "titles": {
      "exportArticle": "Exporter un article",
      "importArticle": "Importer les articles",
      "importArticleSettings": "Importer les intérêts",
      "importAssociatedArticles": "Import des enrichissements",
      "importIllustrations": "Importer les illustrations",
      "importTrackEvent": "Suivi d'importation",
      "informations": "Informations"
    }
  },
  "languages": {
    "all": "Toutes les langues",
    "en-EN": "Anglais",
    "fields": {
      "add": "Nouvelle langue à ajouter"
    },
    "fr-FR": "Français",
    "labels": {
      "btnCancel": "Annuler",
      "btnOk": "OK"
    }
  },
  "liens": {
    "About": "A propos",
    "Admin": "Administration",
    "ArticleSettings": "Paramètres article",
    "Articles": "Liste des articles",
    "Countries": "Liste des pays",
    "CustomDates": "Vue intégrale",
    "Drafts": "Brouillons",
    "Editions": "Vue par édition",
    "EditionsLong": "Editions quotidiennes",
    "ExportFreelance": "Export piges",
    "ExportTranslation": "Export traductions",
    "Feuilletons": "Feuilletons",
    "FeuilletonsLong": "Liste de feuilletons",
    "Formats": "Liste des formats",
    "Freelances": "Gestion des piges",
    "Fridge": "Frigo",
    "FridgeLong": "Articles sans date parution (frigo)",
    "Help": "Aide",
    "Home": "Dashboard",
    "Import": "Import",
    "LongFormatPlanning": "Planning des formats longs",
    "Mails": "Mails",
    "Media": "Mediathèque",
    "Planning": "Planning hebdo",
    "Profiles": "Liste des profils",
    "Publications": "Liste des publications",
    "Search": "Recherche d'articles",
    "SearchAndReplace": "Harmonisation mot clés",
    "Sections": "Liste des rubriques",
    "Settings": "Paramètres personnels",
    "Users": "Liste des utilisateurs"
  },
  "longFormat": {
    "actions": {
      "choseDate": "Changer la date de parution",
      "confirmArticleAtDate": "Maintenir la date et repousser la série",
      "createArticle": "Créer un article",
      "createSeries": "Créer une série",
      "deleteArticle": "Supprimer l'article et avancer la série ←",
      "freezeArticle": "Mettre l'article au frigo et avancer la série ←",
      "pullSeries": "Avancer la série ←",
      "pushSeries": "Repousser la série →"
    },
    "deadline": "rendu {date}",
    "dialogs": {
      "confirmArticleText": "Voulez-vous maintenir l'article '{title}' à la date du {date} et repousser le reste de la série ?",
      "confirmArticleTitle": "Maintenir la date de l'article",
      "deleteArticleText": "Voulez-vous supprimer l'article et avancer la série ?",
      "deleteArticleTitle": "Supprimer l'article",
      "freezeArticleText": "Voulez-vous envoyer l'article au frigo et avancer la série ?",
      "freezeArticleTitle": "Envoyer l'article au frigo",
      "messages": {
        "addArticleOnSeries": "Ajouter un article à la série",
        "createSeries": "Créer la série",
        "createSeriesTitle": "Création d'une série : {format}",
        "errorArticleMissingAtDate": "Il manque 1 {format} le {dates}.|Il manque plusieurs {format} : {dates}.",
        "errorDeadlineTooClose": "L'auteur suivant doit rendre deux formats forts dans un délai trop court : {userNames}.| Les auteurs suivants doivent rendre deux formats forts dans un délai trop court : {userNames}.",
        "errorTwoArticlesAtSameDate": "Il y a deux {format} à la date du {dates}.|Il y a deux {format} aux dates suivantes : {dates}.",
        "labelCycleMonthly": "Valeur invalide | Tous les premiers {day} du mois | Tous les seconds {day} du mois | Tous les troisièmes {day} du mois | Tous les quatrièmes {day} du mois",
        "labelCycleWeekly": "Tous les {day}",
        "labelFormatSimple": "{format} du {day}",
        "seriesStartDate": "Première date de la série",
        "warningAskToManager": "Adressez-vous aux responsables des formats longs"
      },
      "pullSeriesText": "Voulez-vous avancer la série d'articles ?",
      "pullSeriesTitle": "Avancer la série",
      "pushSeriesText": "Voulez-vous repousser la série d'articles ?",
      "pushSeriesTitle": "Repousser la série"
    },
    "publishedOn": "Paraît {date}, "
  },
  "mailTemplate": {
    "fields": {
      "bodyEn": "Corps du mail en anglais",
      "bodyFr": "Corps du mail en français",
      "label": "Label",
      "subjectEn": "Sujet anglais",
      "subjectFr": "Sujet français"
    },
    "labels": {
      "btnEdit": "Sauvegarder"
    }
  },
  "mails": {
    "dialogs": {
      "buttons": {
        "btnGenerateEmail": "Générer le ou les emails",
        "btnModify": "Modifier",
        "btnNext": "Suivant",
        "btnPlanify": "Planifier",
        "btnQuit": "Quitter"
      },
      "errors": {
        "noMailAvailable": "Aucun type de mail disponible pour ce contenu."
      },
      "labels": {
        "mailSubject": "Sujet du ou des mail(s)",
        "planifyDate": "Date et heure de planification",
        "subjectBreakingNews": "Alerte - "
      },
      "messages": {
        "generateEmail": "L'email généré sera téléchargé. Veuillez le vérifier avant de continuer.",
        "modifiedSubject": "Le sujet du mail a bien été modifié.",
        "planifiedEmail": "L'email a été planifié pour {nbContacts} contacts estimés.",
        "warningNotAlertAndNotVdp": "Attention, cet article n'est ni une alerte ni un 'Vient de paraître'.",
        "warningNotPublished": "Attention, cet article n'est pas encore paru !"
      },
      "titles": {
        "generateEmail": "Générer l'email",
        "modifySubject": "Modifier le sujet du mail",
        "planifyEmail": "Planifier l'email"
      }
    },
    "types": {
      "Alert_abo": "Alerte aux abonnés",
      "Alert_prosp": "Alerte aux prospects",
      "Article_abo": "Article aux abonnés",
      "Dossier_abo": "Dossier aux abonnés",
      "Dossier_prosp": "Dossier aux prospects",
      "Entourage_abo": "Entourage aux abonnés",
      "Entourage_prosp": "Entourage aux prospects",
      "Feuilleton_abo": "Feuilleton aux abonnés",
      "Feuilleton_prosp": "Feuilleton aux prospects",
      "GrandeEnquete_abo": "Grande enquête aux abonnés",
      "GrandeEnquete_prosp": "Grande enquête aux prospects",
      "Insiders_abo": "Insiders/Entourage aux abonnés",
      "Insiders_prosp": "Insiders/Entourage aux prospects",
      "JustReleased_abo": "Vient de paraître aux abonnés",
      "JustReleased_prosp": "Vient de paraître aux prospects"
    }
  },
  "media": {
    "actions": {
      "btnSave": "Sauvegarder"
    },
    "exceptions": {
      "NotIdenticalName": "Le nom de l'image n'est pas identique à celui de l'image d'origine."
    },
    "fields": {
      "copyright": "Copyright",
      "legend": "Légende"
    },
    "labels": {
      "ChapoAn": "Chapô AN",
      "ChapoFr": "Chapô FR",
      "CopyrightsEn": "Copyrights EN",
      "CopyrightsFr": "Copyrights FR",
      "LegendeEn": "Légende EN",
      "LegendeFr": "Légende FR",
      "LinkedArticles": "Articles liés:",
      "NoMedias": "Il n'y a aucune illustration rattachée à cet article."
    },
    "notifications": {
      "errorOnSave": "Erreur lors de la sauvegarde du fichier {fileName}",
      "needReimport": "Réimporter l'article après une modification de légende.",
      "onSave": "Les modifications ont été enregistrées",
      "replaceImageError": "Erreur lors du remplacement de l'image",
      "replaceImageSuccess": "L'image a bien été remplacée"
    },
    "select": {
      "error": "Le media ne comprend pas de metadata."
    }
  },
  "messageDialog": {
    "actions": {
      "ApiUnavailableOkButton": "Réessayer",
      "updateElcanoOkButton": "Rafraichir"
    },
    "content": {
      "ApiUnavailable": "L'API Elcano n'est pas disponible, merci de vérifier votre connexion internet.",
      "updateElcano": "Une nouvelle version d'Elcano est disponible. Veuillez rafraichir la page pour mettre à jour l'application."
    },
    "title": {
      "ApiUnavailable": "API Elcano non disponible",
      "updateElcano": "Mise à jour Elcano"
    }
  },
  "messages": {
    "loading": "Chargement de l'application...",
    "notFound": "Cette page n'existe pas",
    "quit": "Attention, vous quittez la page sans avoir enregistré vos modifications. Êtes-vous sûr de vouloir quitter cette page ?",
    "quitTitle": "Modifications non enregistrées",
    "waiting": "Merci de patienter...",
    "welcome": "Bienvenue sur le workflow éditorial <br/>d'Indigo Publications"
  },
  "modificationTypes": {
    "Base": "Base",
    "EnglishContent": "Contenu anglais",
    "FrenchContent": "Contenu français",
    "None": "None"
  },
  "planning": {
    "actions": {
      "changeDate": "Déplacer l'article",
      "choseDate": "Choisir la date",
      "createTranslation": "Créer la traduction",
      "noAction": "Pas d'action disponible"
    }
  },
  "preview": {
    "labels": {
      "btnPrint": "Imprimer",
      "withMarkers": "Avec surlignages",
      "withMedias": "Avec images",
      "withSuggestions": "Avec suggestions"
    }
  },
  "profile": {
    "dialogs": {
      "messages": {
        "delete": "Voulez-vous vraiment supprimer le profile ?"
      },
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer le profile",
      "delete": "Impossible de supprimer le profile",
      "notFound": "Le profile n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour le profile"
    },
    "fields": {
      "id": "Id du profile",
      "initialConfig": "Profile intégré non modifiable",
      "labelEn": "Intitulé du profile en anglais",
      "labelFr": "Intitulé du profile en français",
      "shortId": "id",
      "shortLabelEn": "Nom en anglais",
      "shortLabelFr": "Nom en français",
      "shortSearch": "Recherche"
    },
    "labels": {
      "btnAddAssignment": "Ajouter un assignment",
      "btnDelete": "Supprimer le profile",
      "btnEdit": "Enregistrer le profile",
      "btnNew": "Créer un nouveau profile"
    },
    "messages": {
      "created": "Le nouveau profile a bien été créé",
      "deleted": "Le profile a bien été supprimée",
      "updated": "Le profile a bien été mis à jour"
    },
    "titles": {
      "assignments": "Assignments"
    }
  },
  "publication": {
    "dialogs": {
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer la publication",
      "notFound": "La publication n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour la publication"
    },
    "fields": {
      "active": "Publication actif",
      "id": "Id de la publication",
      "name": "Nom de la publication",
      "publications": "Publications",
      "shortActive": "Actif",
      "shortId": "Identifiant de la publication",
      "shortName": "Nom",
      "shortSearch": "Recherche"
    },
    "labels": {
      "btnCancel": "Annuler",
      "btnCreate": "Créer la publication",
      "btnEdit": "Enregistrer la publication",
      "btnNew": "Créer une nouvelle publication",
      "btnOk": "OK",
      "lblTitle": "Listing des publications",
      "shortBtnNew": "Créer"
    },
    "messages": {
      "created": "La nouvelle publication a bien été créée",
      "updated": "La publication a bien été mise à jour"
    }
  },
  "reviewerMessage": {
    "btnCancel": "Annuler",
    "btnOk": "Notifier",
    "message": "Message pour le relecteur",
    "title": "Notifier le relecteur"
  },
  "right": {
    "dialogs": {
      "messages": {
        "delete": "Voulez-vous vraiment supprimer le droit ?"
      },
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer le droit",
      "delete": "Impossible de supprimer le droit",
      "notFound": "Le droit n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour le droit"
    },
    "fields": {
      "shortId": "id",
      "shortLabelEn": "Nom en anglais",
      "shortLabelFr": "Nom en français",
      "shortRights": "Droits",
      "shortSearch": "Recherche"
    },
    "labels": {
      "btnCancel": "Annuler",
      "btnDelete": "Supprimer le droit",
      "btnNew": "Créer un nouveau droit",
      "btnOk": "OK"
    },
    "messages": {
      "created": "Le nouveau droit a bien été créé",
      "deleted": "Le droit a bien été supprimée",
      "updated": "Le droit a bien été mis à jour"
    }
  },
  "rightGroup": {
    "dialogs": {
      "messages": {
        "delete": "Voulez-vous vraiment supprimer le groupe ?"
      },
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer le groupe",
      "delete": "Impossible de supprimer le groupe",
      "notFound": "Le groupe de droit n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour le groupe"
    },
    "fields": {
      "initialConfig": "Groupe intégré non modifiable",
      "labelEn": "Intitulé du groupe en anglais",
      "labelFr": "Intitulé du groupe en français",
      "shortId": "id",
      "shortLabelEn": "Nom en anglais",
      "shortLabelFr": "Nom en français",
      "shortSearch": "Recherche"
    },
    "labels": {
      "btnDelete": "Supprimer le groupe",
      "btnEdit": "Enregistrer le groupe",
      "btnNew": "Créer un nouveau groupe"
    },
    "messages": {
      "created": "Le nouveau groupe a bien été créé",
      "deleted": "Le groupe a bien été supprimée",
      "updated": "Le groupe a bien été mis à jour"
    }
  },
  "rules": {
    "invalidCharacter": "Caractère invalide",
    "labels": {
      "btnStopShowing": "Ne plus afficher"
    },
    "maxlength": "longueur maximum de {0} caractères",
    "required": "Le champ est requis"
  },
  "search": {
    "action": {
      "closeSelectedArticles": "Fermer",
      "copyLinkArticleTooltip": "Copier le lien {link} vers le presse papier",
      "createTranslation": "Créer traduction",
      "openWebsiteLinkArticleTooltip": "Ouvrir l'article sur notre site",
      "printArticle": "Imprimer l'article",
      "printListArticle": "Previsualiser la liste",
      "searching": "Recherche en cours",
      "selectArticle": "Sélectionner",
      "unselectArticle": "Retirer de la selection",
      "validateSelectedArticles": "Valider ma sélection d'articles"
    },
    "errors": {
      "noResult": "Désolé, il n'y a aucun résultat avec ces critères",
      "serverError": "Erreur lors de la récupération des résultats, vérifiez votre connexion internet et/ou contacter le service informatique"
    },
    "fields": {
      "and": "et",
      "or": "ou",
      "publicationInfo": "{publication} du {date}",
      "searchResult": "résultats pour votre recherche"
    },
    "labels": {
      "btnSearch": "Rechercher",
      "searchCaption": "Saisissez vos critères de recherche ici",
      "searchInfoSpinner": "Recherche en cours...",
      "tgOnlyEnglish": "En anglais",
      "tgOnlyFrench": "Articles français",
      "tgOnlyImported": "Articles \"Importé\"",
      "tgOnlyMyArticle": "Mes contributions",
      "tgOnlyShowTitleStandFirst": "Voir titres/chapô seuls",
      "tgOnlySpotlights": "Feuilleton",
      "tgOnlyTitleStandFirst": "Chercher sur titre/chapô",
      "tgTodayOnly": "Articles du jour"
    },
    "type": {
      "cms": "CMS",
      "country": "Pays",
      "format": "Format",
      "formatExcluded": "Exclure format",
      "from": "Du",
      "idBackOffice": "ID BackOffice Indigo",
      "idContentWorkflow": "ID de contenu",
      "idWorkflow": "ID Article",
      "interest": "Intérêt",
      "language": "Langue",
      "publication": "Publication",
      "publishedAt": "Publié le",
      "section": "Rubrique",
      "state": "Statut",
      "text": "Texte",
      "to": "Au",
      "user": "Utilisateur"
    }
  },
  "searchAndReplace": {
    "columns": {
      "actions": "Actions",
      "author": "Auteur",
      "createdAt": "Créé le",
      "description": "Description",
      "totalImpactedArticles": "Articles impactés",
      "totalOccurences": "Occurences"
    },
    "labels": {
      "btnCreate": "Créer",
      "btnProcessArticlesInError": "Relancer les articles en erreur",
      "btnProcessReport": "Lancer le rapport",
      "btnViewReport": "Consulter",
      "country": "Pays",
      "endingDate": "Date de fin de recherche",
      "language": "Langue",
      "mainSection": "Rubrique principale",
      "noResults": "Aucun résultat",
      "publications": "Publications",
      "replacedText": "Remplacé par",
      "searchedText": "Texte recherché",
      "secondarySection": "Rubrique secondaire",
      "startingDate": "Date de début de recherche",
      "toggleToReplace": "A remplacer"
    },
    "status": {
      "ANePasRemplacer": "Ne pas remplacer",
      "ARemplacer": "A remplacer",
      "EnAttenteExport": "En attente d'export",
      "Erreur": "En erreur",
      "Exporte": "Exporté",
      "NonTraite": "Non traité",
      "Remplace": "Remplacé"
    }
  },
  "section": {
    "dialogs": {
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Impossible de créer la rubrique",
      "notFound": "La rubrique n'a pas été trouvé ou n'éxiste pas",
      "update": "Impossible de mettre à jour la rubrique"
    },
    "fields": {
      "active": "Rubrique actif",
      "backgroundColor": "Couleur du fond",
      "fontColor": "Couleur du texte",
      "idBackOffice": "Id Indigo",
      "inactive": "Afficher les rubriques inactives",
      "labelEn": "Intitulé de la rubrique en anglais",
      "labelFr": "Intitulé de la rubrique en français",
      "main": "Rubrique principale",
      "parent": "Rubrique parente",
      "publication": "Publication",
      "shortActive": "Actif",
      "shortId": "id",
      "shortLabelEn": "Nom en anglais",
      "shortLabelFr": "Nom en français",
      "shortMain": "Principale",
      "shortSearch": "Recherche",
      "shortWeight": "Classement",
      "weight": "Poids"
    },
    "labels": {
      "btnAddPublication": "Ajouter une publication",
      "btnCreate": "Créer la rubrique",
      "btnEdit": "Enregistrer la rubrique",
      "btnNew": "Créer une nouvelle rubrique",
      "lblTitle": "Listing des rubriques",
      "shortBtnNew": "Créer"
    },
    "messages": {
      "created": "La nouvelle rubrique a bien été créée",
      "updated": "La rubrique a bien été mise à jour"
    }
  },
  "setting": {
    "fields": {
      "cache": "Cache",
      "darkMode": "Mode sombre",
      "darkModeInfo": "Attention mode sombre est en preview et ne peut pas faire l'objet de bug.",
      "language": "Choix de la langue de l'application",
      "listArticle": "Vue liste articles",
      "refreshToken": "Rafraichir mon token d'authentification"
    },
    "headers": {
      "application": "Application",
      "article": "Article",
      "profile": "Profile"
    },
    "labels": {
      "btnClearCache": "Supprimer le cache",
      "btnRefreshToken": "Rafraichir",
      "btnResetListeArticleSettings": "Supprimer ma configuration"
    }
  },
  "titles": {
    "About": "A propos",
    "ArticleSettings": "Paramètres article",
    "Articles": "Liste des articles",
    "Assignments": "Page des assignments utilisateur",
    "Countries": "Page pays",
    "CreateArticle": "Création d'un article",
    "CreateCountry": "Création d'un pays",
    "CreateFormat": "Création d'un nouveau format",
    "CreatePublication": "Création d'une publication",
    "CreateSection": "Création d'une rubrique",
    "Drafts": "Mes brouillons",
    "EditArticle": "Edition d'un article",
    "EditCountry": "Edition d'un pays",
    "EditFormat": "Edition d'un format",
    "EditPublication": "Edition d'une publication",
    "EditSection": "Edition d'une rubrique",
    "EditUser": "Edition d'un utilisateur",
    "ExportFreelance": "Export des piges",
    "ExportTranslation": "Export des traductions",
    "Formats": "Page formats",
    "FreelanceBudget": "Budgets des piges",
    "FreelanceConsumption": "Consommation des piges",
    "FreelanceManagement": "Gestion des piges",
    "Help": "Besoin d'aide ?",
    "Home": "Elcano",
    "Import": "Import",
    "ListReport": "Liste des rapports",
    "LongFormatPlanning": "Planning des formats longs",
    "MailTemplate": "Mails",
    "Media": "Media",
    "NotFound": "Page non trouvée",
    "Planning": "Planning hebdomadaire",
    "Profiles": "Page des profils utilisateur",
    "Publications": "Page publications",
    "Report": "Rapport",
    "RightGroups": "Page des groupes de droit",
    "Rights": "Page des droits utilisateurs",
    "Search": "Rechercher",
    "SearchAndReplace": "Harmonisation des mots clés",
    "Sections": "Page rubriques",
    "Settings": "Paramètres de l'application",
    "Users": "Page utilisateurs",
    "ttt": "Tic Tac Toe"
  },
  "triggerMessage": {
    "btnCancel": "Annuler",
    "btnOk": "Ok",
    "message": "Message accompagnant la notification",
    "title": "Changement de statut"
  },
  "user": {
    "actions": {
      "create": "Créer un nouvel utilisateur",
      "save": "Sauvegarder"
    },
    "deleted": "Utilisateur supprimé",
    "errors": {
      "addProfile": "Impossible d'ajouter un profile pour l'utilisateur",
      "insert": "L'utilisateur n'a pas pu être créé",
      "notFound": "L'utilisateur n'a pas été trouvé ou n'éxiste pas",
      "removeProfile": "Impossible de retirer un profile pour l'utilisateur",
      "update": "L'utilisateur n'a pas pu être mis à jour"
    },
    "fields": {
      "addDate": "Date création",
      "deleted": "Supprimé",
      "disabled": "Désactivé",
      "enabled": "Activé",
      "firstName": "Prénom",
      "freelancerWithoutId": "Pigiste sans Id",
      "idBackOffice": "Id Access",
      "lastName": "Nom",
      "login": "Login",
      "loginHint": "Format : Prenom.NOM",
      "profilesUsed": "Liste des profils utilisé",
      "shortEmail": "Email",
      "shortFirstname": "Prénom",
      "shortId": "id",
      "shortIdBackOffice": "Id Access",
      "shortLastname": "Nom",
      "shortLogin": "Login",
      "shortName": "Nom complet",
      "shortProfiles": "Profils",
      "shortSearch": "Recherche",
      "shortSignature": "Signature"
    },
    "shortDeleted": " (SUPPR)",
    "shortDisabled": " (DESAC)",
    "tabs": {
      "assignments": "Assignments",
      "profiles": "Profils",
      "rightGroups": "Groupes de droit",
      "rights": "Droits",
      "users": "Utilisateurs"
    }
  }
}
