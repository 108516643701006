<template>
  <q-menu touch-position context-menu class="menu-above-tooltip">
    <q-list dense style="min-width: 100px">
      <q-item
        v-if="getActions(article, articleLocks).canTranslate"
        v-close-popup
        clickable
        @click="handleCreateTranslation(article)"
      >
        <q-item-section>{{
          $t("planning.actions.createTranslation")
        }}</q-item-section>
      </q-item>
      <q-item v-if="getActions(article, articleLocks).changeDate" clickable>
        <q-item-section>{{ $t("planning.actions.changeDate") }}</q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <q-menu anchor="top end" self="top start">
          <q-list>
            <q-item
              v-for="n in getChangePublicationDateOptions(
                article,
                languageFilter
              )"
              :key="n.label"
              v-close-popup
              dense
              clickable
              @click="handleChangeDate(article, n.date, languageFilter)"
            >
              <q-item-section style="text-transform: capitalize">{{
                n.label
              }}</q-item-section>
            </q-item>
            <q-item
              v-close-popup
              clickable
              dense
              @click="
                handleShowDialogChangePublishedOn(article, languageFilter)
              "
            >
              <q-item-section>{{
                $t("planning.actions.choseDate")
              }}</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
      <q-item
        v-if="getActions(article, articleLocks).noAction"
        v-close-popup
        clickable
      >
        <q-item-section>{{ $t("planning.actions.noAction") }}</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script setup lang="ts">
import type { ArticleDto, ArticleLockDto } from "@/types/api";
import { computed, toRefs } from "vue";
import { EnglishLanguage, FrenchLanguage } from "@/constants";
import { useArticleActionMenu } from "@/composables/useArticleActionMenu";

const props = defineProps<{
  article: ArticleDto;
  lang: string;
  locks: ArticleLockDto[];
}>();
const emits = defineEmits<{
  (e: "update:working", value: boolean): void;
  (e: "update:showDialogAddLanguage", value: boolean): void;
  (e: "update:needReload"): void;
  (
    e: "update:showDialogChangePublishedOn",
    value: {
      article: ArticleDto;
      date: string;
    }
  ): void;
}>();

const {
  getActions,
  getChangePublicationDateOptions,
  handleShowDialogChangePublishedOn,
  handleChangeDate,
  handleCreateTranslation,
} = useArticleActionMenu({
  onLoading: (loading: boolean) => emits("update:working", loading),
  onReload: () => emits("update:needReload"),
  onShowDialogChangePublishedOn: (value: {
    article: ArticleDto;
    date: string;
  }) => emits("update:showDialogChangePublishedOn", value),
  onShowDialogAddLanguage: () => emits("update:showDialogAddLanguage", true),
});

const { article, locks: articleLocks, lang } = toRefs(props);

const languageFilter = computed(() =>
  lang.value === EnglishLanguage ? EnglishLanguage : FrenchLanguage
);
</script>
