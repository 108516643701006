{
  "article": {
    "action": {
      "copyMainContent": "Paste the original text",
      "notify": "Notifiy",
      "removeMainMedia": "Delete the Main Illustration",
      "selectMainMedia": "Main media",
      "setDefaultStandfirstFromFormat": "Replace the standfirst by that of the format"
    },
    "dialogs": {
      "messages": {
        "btnNoReload": "Continue my edits",
        "btnReload": "Reload article",
        "btnSaveDraftAndReload": "Save my draft and reload the article",
        "cancel": "Cancel",
        "delete": "Are you sure you want to delete the article?",
        "deleteDraftOnBtnCancel": "You have an ongoing personnal draft for this article. Do you want to delete it?",
        "error": "Try refreshing the page. If the problem persists, contact support.",
        "inactivityUnlock": "You no longer appear to be working on this article. It is now unlocked.",
        "loadingDraft": "An error occurred. Do you want to load a draft?",
        "loadingDraftOrArticle": "You have some unsaved pending modifications on this article. Do you want to apply or discard them?",
        "loadingNoEditableDraftOrArticle": "You had some unsaved pending modifications on this article. Unfortunately, this article has been modified by someone else in the meantime, and you can no longer apply your modifications.<br/><br/>What would you like to do with your unsavable draft? <br/><br/>Your draft date: {draftDate}<br/>Server version date: {articleDate} (this is the up-to-date version).",
        "save": "Save",
        "unlock": "This article was unlocked by {userName}. Reload the page to access the latest version."
      },
      "titles": {
        "confirm": "Confirmation",
        "error": "An error occurred while loading the article.",
        "loadingDraft": "Consult this personal draft?",
        "loadingDraftOrArticle": "Unsaved modifications found",
        "loadingNoEditableDraftOrArticle": "Unsaved Draft found",
        "notifyArticleMustBeRefresh": "This article language content was edited by {userName}. Do you want to update it?",
        "unlock": "This article was unlocked.",
        "writeNow": "Do you want to write the article now?"
      }
    },
    "disabledTriggers": {
      "Correction_RelireTraducteurReferent": "No lead translator is assigned to this article.",
      "Redaction_Relire": "No reviewer is assigned to this article.",
      "Relecture_RelireTraducteurReferent": "No lead translator is assigned to this article.",
      "rightMissing": "You do not have the right to use this action."
    },
    "edit": {
      "titles": {
        "cms": "CMS",
        "contributions": "Contributions",
        "medias": "Illustrations",
        "parameters": "Article settings",
        "settings": "Interests"
      }
    },
    "errors": {
      "getVersion": "Error retrieving article version.",
      "import": "An error occurred during import, check the log tab for more details.",
      "invalidDate": "Invalid date",
      "onRefresh": "Error while refreshing the article.",
      "onSave": "An error occurred while saving. This version of the article has been kept in the drafts. Try again to save this article later.",
      "onTrigger": "Error when changing status.",
      "shortOnSave": "An error occurred while saving.",
      "takeLock": "An error occurred, we were unable to lock the article for you. We advise you to save and refresh your page."
    },
    "fields": {
      "actions": "Actions",
      "articleUrl": "Article URL",
      "articleUrlShort": "URL",
      "articleUrlTooltip": "Article URL, click to copy",
      "associatedSerial": "Associated serial",
      "contributions": "Contributions",
      "countArticles": "Articles: {0}",
      "countChars": "Signs: {0}",
      "countWords": "Words: {0}",
      "countWordsReverse": "<b>{0}</b> words",
      "countries": "Country",
      "datePublication": "Publication date",
      "datePublicationWithTime": "Publication date (at {time})",
      "deadline": "Deadline",
      "event": "Event",
      "format": "Format",
      "formats": "Formats",
      "fridge": "Fridge",
      "fridgeTooltip": "The fridge mode allows you to not specify a publication date on this article, you find these articles in the fridge view",
      "groups": "Groups",
      "helpOpenEdition": "Open the edition's article list",
      "id": "ID",
      "idBackOffice": "ID Indigo",
      "idContentElcanoTooltip": "Elcano's content ID, clic to copy",
      "idContentWorkflow": "Content ID",
      "idIndigoTooltip": "Back office article ID (website, magellan,etc...), clic to copy",
      "idWorkflow": "Article ID",
      "idWorkflowOrContent": "Article or content ID",
      "lastAssociatedArticle": "Last enrichment",
      "lastModification": "Last modification",
      "mainSection": "Main section",
      "medias": "Illustrations",
      "nbAssociatedArticles": "Number of enrichments",
      "online": "On line",
      "period": "Set interval period",
      "pigiste": "Freelance",
      "pigisteSelect": "Freelancer (mandatory selection)",
      "publication": "Publication",
      "publishedOn": "Publication date",
      "publishedOnTranslation": "Translation publication date",
      "publishedOnTranslationAtSameDate": "Publish the translation at the same time",
      "publishedOnTranslationLabel": "Translation publication date",
      "publishedOnTranslationWithTime": "Translation publication date (at {time})",
      "score": "Score",
      "secondariesSections": "Secondary sections",
      "sections": "Sections",
      "sectionsAndCountries": "Sec. Countries",
      "seoTitle": "SEO Title",
      "serialUrl": "Serial Url",
      "shortEndDate": "End",
      "shortStartDate": "Beginning",
      "shortTitle": "Short title",
      "signAndWords": "Length",
      "standfirst": "Standfirst",
      "state": "State",
      "status": "Article Status",
      "summary": "Custom Summary",
      "summaryOf": "Summary of",
      "summaryOfLabel": "Desired summary date",
      "summaryOfTooltip": "Allows you to configure a date of appearance in the summary different from the date of publication (useful for alerts)",
      "summaryOfTranslation": "Summary of translation",
      "summaryOfTranslationLabel": "Desired summary date",
      "text": "Text",
      "title": "Article title",
      "tweet": "Tweet"
    },
    "helpOnNewStates": {
      "Correction": "An email has been sent to the author.",
      "Relecture": "An email has been sent to the reviewer.",
      "RelectureTraducteurReferent": "An email has been sent to the lead translator.",
      "RelectureWithoutNotification": "The status of the article has been changed. Don't forget to notify a reviewer.",
      "Saved": "The article's status has been changed."
    },
    "helpStates": {
      "history": "No actions possible on the consultation of a history.",
      "locked": "Unable to change the status of this article. It is being edited by another person.",
      "noInterest": "There is no interest associated with this article.",
      "notEnoughAssociatedArticles": "There is not enough associated articles.",
      "notSaved": "Unable to change the status of this article, you must save it before changing the status",
      "online": "The article is online."
    },
    "helpTriggers": {
      "DemanderTraduction": "Change the status back to 'Awaiting translation'."
    },
    "labels": {
      "articleCount": "story | story | stories",
      "btnAddLang": "Add a language",
      "btnCancel": "Cancel",
      "btnChangeLang": "Change article language",
      "btnClose": "Close",
      "btnContinuePreview": "Pursue",
      "btnCreate": "Create article",
      "btnDelete": "Delete",
      "btnDownloadPDF": "Download",
      "btnDuplicate": "Duplicate",
      "btnEdit": "Edit article",
      "btnGenerateEmail": "Envoyer par email",
      "btnGenerateMailToolTip": "Last mailing: {dateSend}",
      "btnGeneratePdfIssueToolTip": "Generate the PDF edition of this edition",
      "btnLockIssueToolTip": "Block this edition, it will no longer be possible to add or delete articles",
      "btnModeDaily": "Daily",
      "btnModeDailyTooltip": "Displays the articles of a daily edition",
      "btnModeDates": "Custom Dates",
      "btnModeDatesTooltip": "Allows you to select a start date and an end date",
      "btnModeFride": "Fridge",
      "btnModeFrideTooltip": "Unplanned and pending article",
      "btnModePlanning": "Schedule",
      "btnModePlanningTooltip": "Displays articles about the week",
      "btnNew": "Create an article",
      "btnNextEditionToolTip": "Next edition",
      "btnNonValid": "No",
      "btnOpenEditionOnWebsiteTooltip": "View the edition on the website",
      "btnPreview": "Preview",
      "btnPreviewPDF": "Preview",
      "btnPreviousEditionToolTip": "Previous edition",
      "btnPrintArticlesTooltip": "Print articles",
      "btnReset": "Reset",
      "btnSave": "Save",
      "btnSettings": "Settings",
      "btnShow": "Open the article",
      "btnSplitView": "Side-by-side display",
      "btnStatus": "Status change",
      "btnUnlockIssueToolTip": "Unlock this edition, it will be possible to add or remove articles",
      "btnValid": "Yes",
      "characterCount": "sign | sign | signs",
      "cmsWarnModifications": "If you make a change to the signature or to the parameter 'Vient de paraître', please import the other language again.",
      "editionOf": "",
      "manageLanguage": "Language management",
      "missingMainSection": "No main section",
      "notImportedYet": "The preview of these articles is not possible because they have not been imported: {titles}",
      "publishedAndSummaryInfo": "Published on {publishedOn} - Summary of {summaryOf}",
      "publishedInfo": "Published on {publishedOn}",
      "selectionLength": "Selection: {count} sign | Selection: {count} sign | Selection: {count} signs",
      "shortBtnDelete": "Delete",
      "shortBtnEdit": "Edit",
      "shortBtnNew": "Create",
      "shortBtnStatus": "Status",
      "summaryInfo": "Summary of {summaryOf}",
      "switchMainLanguage": "Switch primary language",
      "toggleAuthorSignature": "Show the author's signature",
      "toggleAuthorSignatureWarn": "If you make a change to the signature, please import the other language again.",
      "toggleFreelanceSignature": "Show the signature of the freelancer",
      "wordCount": "word | word | words"
    },
    "locks": {
      "errors": {
        "onRefresh": "We have not been able to determine if other users are currently working on this article. You can keep working."
      },
      "message": "Free this article",
      "reasons": {
        "deleted": "No actions possible on the consultation of a deleted article.",
        "history": "No actions possible on the consultation of a history.",
        "lockedByAnotherUser": "The article is being edited by {0}.",
        "obsolete": "You don't have the latest version of the article.",
        "reviewOnlyByRedactor": "Proofreading can only be done by an editor of the publication or an editor.",
        "rightToSave": "You do not have the right to save this content.",
        "translatorCannotEditMainLanguage": "Translators cannot edit the article in its original language."
      }
    },
    "messages": {
      "addLangDisabledDraft": "You cannot add a language to a draft.",
      "addLangDisabledModifiedByAnother": "You cannot add a language to this article because the article is being edited by: {users}.",
      "addLangDisabledModifiedByYou": "You cannot add a language to this article because edits are being made to the article by you.",
      "created": "The new article has been created",
      "deleted": "The article has been deleted",
      "draftEditable": "You are on an article with unsaved modifications. Please save them as soon as possible before someone else edits this article.",
      "draftNoEditable": "You are on an article with modifications that cannot be saved. Please copy your modifications to the up-to-date article.",
      "hiddenPropWithValue": "Please note the following field doesn't appear on Elcano, but its content will be visible online: {properties} | Please note, the following fields don't appear on Elcano, but their contents will be visible online: {properties}",
      "newNotifications": "A new notification on the article has been published",
      "translationDoesNotExists": "The translation of the article does not exist",
      "updated": "The article has been updated",
      "warnLongFormatPublisedOn": "To change the publication date of this article, contact those responsible for long formats."
    },
    "rules": {
      "title": "Please enter the title of the article"
    },
    "states": {
      "All": "All",
      "AttenteCorrectionSr": "Wait Correction Sr",
      "AttenteTraduction": "Expectation Translation",
      "Correction": "Correction",
      "CorrectionSr": "Correction Sr",
      "Importation": "Import",
      "ImportationEnErreur": "Import In Error",
      "Importe": "Imported",
      "Invalidation": "Invalidation",
      "InvalidationEnErreur": "Invalidation In Error",
      "Invalide": "Invalid",
      "Pitch": "Pitch",
      "Redaction": "Writing",
      "Relecture": "Proofreading",
      "RelectureTraducteurReferent": "Proofreading Lead Translator",
      "Traduction": "Translation"
    },
    "tabs": {
      "article": "article",
      "enrichissements": "interests ({interestCount}) and associated articles ({associatedArticlesCount})",
      "historique": "history",
      "logs": "import logs",
      "mainMedia": "Main",
      "medias": "illustrations ({count})",
      "notifications": "General notifications ({count})",
      "parametres": "settings"
    },
    "tags": {
      "deleted": "Deleted",
      "draft": "Draft",
      "unchangeable": "Unchangeable"
    },
    "triggers": {
      "Corriger": "To correct",
      "CorrigerSr": "Correct Sr",
      "DemanderCorrectionSr": "Request Correction Sr",
      "DemanderTraduction": "Request Translation",
      "Importer": "Import",
      "Invalider": "Invalidate",
      "Pitcher": "Pitcher",
      "Rediger": "Write",
      "RejeterImportation": "Reject Import",
      "RejeterInvalidation": "Reject Invalidate",
      "Relire": "Proofread",
      "RelireTraducteurReferent": "Proofread Lead Translator",
      "RenvoyerAuRelecteur": "Send to proofreader",
      "RenvoyerAuTraducteur": "Send to translator",
      "RenvoyerAuTraducteurReferent": "Send to Lead Translator",
      "Republier": "Republish this article",
      "Rouvrir": "Reopen",
      "Traduire": "Translate",
      "ValiderImportation": "Validate Import",
      "ValiderInvalidation": "Validate Invalidate"
    }
  },
  "articleContribution": {
    "errors": {
      "cannotEditLanguage": "You cannot edit this article at this time.",
      "cannotEditParams": "The parameters cannot be modified, it is impossible to add you as a lead translator reviewer.",
      "onSaveAmount": "Error when modifying a contribution"
    },
    "fields": {
      "amount": "Amount",
      "contributionType": "Contribution",
      "contributor": "Contributor",
      "contributors": "Contributors",
      "language": "Language"
    },
    "labels": {
      "actionDisableBeforeSave": "Save before you can perform an action",
      "btnAccept": "Accept",
      "btnAdd": "Add",
      "btnAddAmount": "Enter amount",
      "btnDelete": "Delete",
      "btnEditorReviewOk": "Editor review Ok",
      "btnEditorReviewReset": "Reset editor review",
      "btnEditorReviewStart": "Start editor review",
      "btnNotify": "Notify",
      "btnReject": "To be reworked",
      "btnReviewing": "Proofreading in progress",
      "btnStart": "I start the proofreading",
      "dontForgetToNotify": "Don't forget to notify one of the reviewers by clicking on their name",
      "leadTranslatorToAssign": "Lead translator",
      "notified": "Contributor has been notified"
    },
    "popupAmount": {
      "btnCancel": "Cancel",
      "btnSave": "Save",
      "title": "Please enter the freelancing amount:"
    }
  },
  "articleSetting": {
    "dialogs": {
      "messages": {
        "delete": "Are you sure you want to delete the parameter?"
      },
      "titles": {
        "barAutoInterest": "Here are the interests found via the text:",
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Unable to create the parameter",
      "delete": "Unable to delete parameter",
      "getInterestFromText": "Error retrieving interest via text.",
      "notFound": "The articleSetting was not found or does not exist",
      "update": "Unable to update the parameter"
    },
    "fields": {
      "active": "Active parameter",
      "cms": "CMS Parameters",
      "id": "Article Parameter ID",
      "idType": "Type",
      "interest": "Interests",
      "labelEn": "Parameter title in English",
      "labelFr": "Title of the parameter in French",
      "mainLabel": "Main title of the parameter",
      "shortActive": "Active",
      "shortId": "id",
      "shortKey1": "Key 1",
      "shortLabelEn": "English name",
      "shortLabelFr": "French name",
      "shortMainLabel": "Main name",
      "shortSearch": "Research",
      "shortSubType": "Type of interests",
      "shortType": "Kind",
      "subType": "Sub type"
    },
    "labels": {
      "btnAutoInterest": "Self interest.",
      "btnCreate": "Create the parameter",
      "btnDelete": "Remove Parameter",
      "btnEdit": "Save the parameter",
      "btnNew": "Create a new parameter",
      "lblTitle": "Parameters and Article Interests",
      "shortBtnDelete": "Delete",
      "shortBtnNew": "Create"
    },
    "messages": {
      "created": "The new articleSetting has been successfully created",
      "deleted": "The articleSetting has been successfully deleted",
      "updated": "The articleSetting has been successfully updated"
    }
  },
  "articleTweet": {
    "errors": {
      "onSaveTweet": "Error saving tweet"
    },
    "labels": {
      "btnCancel": "Save",
      "btnSave": "Save",
      "btnUpdate": "Update"
    },
    "popupUpdate": {
      "title": "Update tweet"
    }
  },
  "assignment": {
    "dialogs": {
      "messages": {
        "delete": "Are you sure you want to delete the assignment?"
      },
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Unable to create assignment",
      "delete": "Unable to delete assignment",
      "notFound": "The assignment was not found or does not exist",
      "update": "Unable to update assignment"
    },
    "fields": {
      "editor": "Editor",
      "freelance": "Freelance",
      "id": "Id",
      "initialConfig": "Readonly Built-in Assignement",
      "labelEn": "Label EN",
      "labelFr": "Label FR",
      "redactor": "Redactor",
      "shortAssignments": "Assignments",
      "shortId": "id",
      "shortLabelEn": "English name",
      "shortLabelFr": "French name",
      "shortSearch": "Research",
      "translator": "Translator"
    },
    "labels": {
      "btnCancel": "Cancel",
      "btnDelete": "Delete assignment",
      "btnEdit": "Save assignment",
      "btnNew": "Create a new assignment",
      "btnOk": "OK"
    },
    "messages": {
      "created": "The new assignment has been created successfully",
      "deleted": "The assignment has been successfully deleted",
      "updated": "The assignment has been successfully updated"
    }
  },
  "associatedArticles": {
    "addAssociatedArticlesTitle": "Associated articles to add:",
    "autoAssociation": "Auto associa.",
    "currentAssociatedArticlesTitle": "Current associated articles:",
    "dialogs": {
      "btnAdd": "Add",
      "btnCancel": "Cancel",
      "btnClose": "Close",
      "noSuggestedArticles": "We did not find any enrichments corresponding to the text of the article",
      "noSuggestedInterest": "We found no new interests matching the text of the article",
      "suggestedAssociationArticles": "Suggested associated articles"
    },
    "labelBtnSearchArticles": "Search articles",
    "labelChipNoChosenArticles": "No selected articles.",
    "labelStep2": "Save the article for the interests to be taken account for the associated articles process.",
    "tooltipNoFrenchContent": "Action possible only on french"
  },
  "ckeditor": {
    "ai": {
      "commands": {
        "Journalism": "Journalism",
        "improveWriting": "Improve writing",
        "makeShorter": "Make shorter",
        "translateToEnglish": "Translate in English",
        "translateToFrench": "Translate in French"
      },
      "groups": {
        "changeStyle": "Change style",
        "editOrReview": "Edit or review",
        "translate": "Translate"
      }
    },
    "dialogs": {
      "titles": {
        "shortcuts": "List of keyboard shortcuts"
      }
    },
    "shortcuts": {
      "labels": {
        "caps": "Transform into capital",
        "commentaire": "Add a comment",
        "exposant": "Superscript",
        "gras": "Bolding",
        "indice": "Put in index",
        "intertitre": "Apply heading style",
        "italique": "Italicize",
        "lien": "Add link",
        "lower": "Transform into lowercase",
        "miseEnForme": "Remove formatting",
        "print": "To print",
        "rayer": "Strikethrough",
        "retraitSurlignage": "Remove Highlight",
        "souligner": "Underline",
        "surlignageBleu": "Highlight in blue",
        "surlignageJaune": "Highlight in yellow",
        "surlignageRouge": "Highlight in red",
        "surlignageVert": "Highlight in green",
        "switchDisplay": "Switch between display modes",
        "text": "Apply simple text style",
        "trackchanges": "Enable / Disable track changes",
        "unbreakableSpace": "Non-breaking space"
      }
    }
  },
  "common": {
    "dayOfWeek": {
      "1": "Monday | Mondays",
      "2": "Tuesday | Tuesdays",
      "3": "Wednesday | Wednesdays",
      "4": "Thursday | Thursdays",
      "5": "Friday | Fridays",
      "6": "Saturday | Saturdays",
      "7": "Sunday | Sundays"
    },
    "labels": {
      "autoRefresh": "Auto update ({seconds}s)",
      "btnDisconnect": "Disconnect",
      "friday": "Friday",
      "loading": "Loading",
      "monday": "Monday",
      "thursday": "Thursday",
      "tuesday": "Tuesday",
      "wednesday": "Wednesday"
    }
  },
  "contribution": {
    "freelances": {
      "buttons": {
        "cancelProcessed": "Cancel processing",
        "lock": "Lock",
        "refreshLock": "Lock missing freelance",
        "save": "Save",
        "setProcessed": "Mark as processed",
        "unlock": "Unlock"
      },
      "labels": {
        "cancelDate": "Cancellation date",
        "cancelProcessOnArticle": "Cancel processing on the article:",
        "inputDate": "Processing deadline",
        "lock": "You have locked {0} pins for a total amount of {1} Euros.",
        "noLocks": "No freelance is locked."
      },
      "notifications": {
        "canceled": "The processing of {0} freelances for a total amount of {1} euros has been cancelled.",
        "errorCancel": "Error during the cancellation of the processing of the freelances.",
        "errorLock": "Error when locking freelance.",
        "errorLockYear": "Error when locking the year.",
        "errorOnSaveBudgets": "Error saving budgets.",
        "errorProcessed": "Error when modifying freelance.",
        "errorUnlock": "Error when unlocking freelance.",
        "errorUnlockYear": "Error when unlocking the year.",
        "locked": "The freelance have been locked.",
        "newLock": "{nbPiges} additional freelance have been locked.",
        "processed": "The freelance have been marked treated!",
        "savedBudgets": "Budgets saved!",
        "unlock": "The freelance have been unlocked!"
      },
      "titles": {
        "actions": "Actions:",
        "budget": "Budget",
        "caseOfError": "In case of error",
        "consumption": "Consumption",
        "freelanceChoice": "(Optional) Choose a freelancer:",
        "lockYear": "Lock the year:",
        "management": "Management",
        "parameters": "Lock settings:"
      }
    }
  },
  "contributionType": {
    "Author": "Author",
    "Editor": "Editor",
    "Freelancer": "Freelancer",
    "LeadTranslator": "LeadTranslator",
    "Reviewer": "Reviewer",
    "Translator": "Translator",
    "Unknown": "Unknown"
  },
  "country": {
    "dialogs": {
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Unable to create country",
      "notFound": "The country was not found or does not exist",
      "update": "Unable to update country"
    },
    "fields": {
      "active": "Active country",
      "code": "Country code",
      "codeISO": "ISO country code",
      "hashtagEn": "Country hashtag in English",
      "hashtagFr": "Country hashtag in French",
      "labelEn": "Country name in English",
      "labelFr": "Country name in French",
      "realCountry": "Existing country",
      "shortActive": "Asset",
      "shortCode": "Coded",
      "shortCodeISO": "ISO code",
      "shortId": "id",
      "shortLabelEn": "English name",
      "shortLabelFr": "French name",
      "shortSearch": "Research"
    },
    "labels": {
      "btnCreate": "Create country",
      "btnEdit": "Save country",
      "btnNew": "Create a new country",
      "lblTitle": "List of countries",
      "shortBtnNew": "Create"
    },
    "messages": {
      "created": "The new country has been created",
      "updated": "The country has been successfully updated"
    }
  },
  "draft": {
    "dialogs": {
      "messages": {
        "delete": "Your last deleted draft can be viewed in the 'Drafts > View my last deleted drafts' section'"
      },
      "titles": {
        "confirm": "Confirm the deletion of your draft"
      }
    },
    "fields": {
      "actions": "Actions",
      "id": "id",
      "lastModifications": "Latest changes",
      "publication": "Publication",
      "shortSearch": "Search",
      "title": "Title"
    },
    "labels": {
      "btnDelete": "No and delete this draft",
      "btnDraftLoadLast": "I want to open the article but keep my draft",
      "btnDraftLoadLastAndRemove": "I want to open the latest version of the article and delete my old draft",
      "btnDraftLoadMyDraft": "View the unsavable draft",
      "btnLoadLast": "Open the server version and delete my pending modifications",
      "btnLoadMyDraft": "Apply my pending modification",
      "lblTitle": "My Draft:",
      "lbltgShowDeleted": "View my recently deleted drafts"
    },
    "messages": {
      "deleted": "The draft has been successfully deleted."
    }
  },
  "exceptions": {
    "ArticleLongFormatException": {
      "AlreadyPublished": "The article has already been published.",
      "ArticleAlreadyPushedImpossibleToDeleteOrFreeze": "The article has already been published, it cannot be deleted or frozen.",
      "ArticleLocked": "The article is locked by another person.",
      "Conflict": "Moving the article conflicts with the series.",
      "InvalidAuthorsNumber": "The number of articles does not correspond to the number of authors.",
      "InvalidPublishedDate": "You are trying to move an article whose publication date has already changed.",
      "NextDatePublishedError": "Calculation of the next release date failed.",
      "NoRights": "Contact those responsible for the long formats of your publication.",
      "default": "Error handling an article or series."
    },
    "ArticleNotFoundException": {
      "NotFoundByUrl": "The url {url} does not match any article.",
      "TranslationInvalidated": "The translation of the article has been invalidated.",
      "TranslationNotFound": "The article has not been translated.",
      "default": "Article '{id}' does not exist."
    },
    "ArticleSaveException": {
      "ArticleLocked": "The article is locked by someone else.",
      "ContributionLangMissing": "The language was not entered for one of the proofreaders.",
      "ContributionLocked": "You tried to edit a contribution that can no longer be edited.",
      "ContributorMissing": "A contributor is missing on one of the contributions.",
      "EditionLocked": "Editing is locked",
      "Importing": "The article is being imported.",
      "MainContentDoesntExist": "The content to be translated does not exist.",
      "NotAuthor": "You are neither the author of the article nor the editor-in-chief",
      "NotLeadTranslator": "You are not the lead translator of this article, you cannot make changes.",
      "NotReviewer": "You are not a reviewer of this article, you cannot make changes.",
      "SaveError": "An error occurred while saving the article.",
      "SwitchMainLanguageAlreadyHaveTwoContent": "The article already exists in both languages. Unable to perform the operation.",
      "TranslationExists": "The translation already exists.",
      "TranslatorExists": "A translator is already defined.",
      "UpdateByAnotherUser": "You don't have the latest version of the article, so you can't make changes.",
      "UserDisconnected": "You are logged out."
    },
    "ContributionSaveException": {
      "CantChangeStateOnOtherUserContribution": "Only the contributor can modify the status of their contribution.",
      "DoesntExist": "Contribution does not exist.",
      "SaveError": "Error saving contribution.",
      "default": "Error saving contribution."
    },
    "GenerationEmailException": {
      "ArticleNotOnline": "The published date of the article is after the planification date of the alert.",
      "Error": "Une erreur inconnue est survenue. Warn the IT staff.",
      "HistoAlreadyExists": "The history comment already exists. Another sending may be already planified.",
      "InexistentArticle": "The sent article does not exists. Check that this article has been imported.",
      "NoContacts": "No contact have been found to send the article to.",
      "NoMessage": "There is no email available for this kind of sending.",
      "NotAvailableAM": "The planification cannot be made because another one may be already planified. Please try again later.",
      "PlanifDateError": "The planification date is incorrect. It has to be greater than the published date of the article.",
      "SaveFailed": "The save of the email has failed.",
      "TagAlreadyExists": "The tracking ID already exists. Warn the IT staff.",
      "UpdateFailed": "The update of the email has failed.",
      "default": "An error has occurred. Warn the IT staff."
    },
    "MediaSaveException": {
      "default": "Unable to save media {filePath}"
    },
    "NotAllowedException": {
      "WithPublication": "You do not have the '{right}' right on the publication {publication}.",
      "WithoutPublication": "You don't have the '{right}' right."
    },
    "UserNotFoundException": {
      "default": "The user does not exist."
    },
    "UserSaveException": {
      "DuplicateLogin": "The login is already used by another user.",
      "SaveError": "An error occurred while saving the user.",
      "UserNotFound": "The user does not exist."
    },
    "WorkflowFactoryException": {
      "ArticleNotFound": "The article does not exist.",
      "UserDisconnected": "You are not connected.",
      "VersionMismatch": "You do not have the latest version of the article.",
      "default": "Unable to perform the action on the article."
    },
    "WorkflowStateMachineException": {
      "ContentLocked": "The content is locked by another user: {user}.",
      "GuardNotValidForTrigger": "Action '{trigger}' is valid for state '{state}', but condition '{guard}' was not met.",
      "InvalidLanguage": "Invalid language ({language}).",
      "MissingParameter": "The action '{trigger}' must be accompanied by a parameter.",
      "TranslatorCannotEditMainLanguage": "The action '{trigger}' cannot be performed by a translator on the main language.",
      "TriggerNotValidForState": "Action '{trigger}' is only valid for state '{state}'.",
      "default": "Unable to perform the action on the article."
    }
  },
  "export": {
    "buttons": {
      "export": "Export"
    },
    "fields": {
      "endDate": "Publication date: end",
      "freelancer": "Freelance",
      "publication": "Publication",
      "startDate": "Publication date: start",
      "translator": "Translator"
    }
  },
  "flags": {
    "en-EN": "🇬🇧",
    "fr-FR": "🇫🇷"
  },
  "format": {
    "dialogs": {
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Unable to create format",
      "notFound": "The format was not found or does not exist",
      "update": "Unable to update format"
    },
    "fields": {
      "active": "Active format",
      "id": "Format ID",
      "labelEn": "Title of the format in English",
      "labelFr": "Title of the format in French",
      "long": "Long format",
      "maxLength": "Maximum number of characters",
      "shortActive": "Asset",
      "shortId": "id",
      "shortLabel": "Format name",
      "shortLabelEn": "English name",
      "shortLabelFr": "French name",
      "shortLong": "Long",
      "shortSearch": "Research"
    },
    "labels": {
      "btnAddPublication": "Add a post",
      "btnCreate": "Create format",
      "btnEdit": "Save format",
      "btnNew": "Create a new format",
      "lblTitle": "Article's format list",
      "shortBtnNew": "Create"
    },
    "messages": {
      "created": "The new format has been successfully created",
      "updated": "The format has been successfully updated"
    },
    "short": {
      "Article": "Art",
      "Article leader": "Lead",
      "Breve": "Br",
      "Clin d'oeil": "Clin",
      "Dossier": "Doss",
      "EchoVillage": "Gaz",
      "Enquete": "Foc",
      "Entourage": "Inn",
      "Evenement": "Sp",
      "Feuilleton": "Feuill",
      "FrontRow": "FRow",
      "Gazette": "Gaz",
      "Insider": "Ins",
      "Maitre Espion": "SM",
      "Mouvement": "Mouv",
      "NouvelleGarde": "Gaz",
      "SecretsPalais": "Gaz",
      "Spy Way Of Life": "Swol",
      "Video": "Vid",
      "Watchlist": "Gaz"
    }
  },
  "help": {
    "labels": {
      "ask": "<p>Ask for help? Report a bug? Please mail us: <a href=\"mailto:elcanohelp{'@'}indigo.fr\">elcanohelp{'@'}indigo.fr</a></p><p>You may also check out the <a href='https://indigonet.sharepoint.com/sites/IndigoSquare/redaction/edition/SitePages/FAQ_Elcano.aspx' target='_blank'>Elcano FAQ</a> on our intranet.</p>",
      "news": "What's new"
    },
    "sections": {
      "0": {
        "questions": {
          "1": {
            "content": "",
            "question": "What's the latest in the app?"
          }
        },
        "titleHeader": "Latest changes"
      },
      "1": {
        "questions": {
          "1": {
            "content": "The spell checker (Prolexis) is accessible by clicking on the “prism” shaped button in the text editor. He corrects all the text, including the title and the standfirst.",
            "question": "Where can I find the spell checker?"
          },
          "2": {
            "content": "",
            "question": "What are the keyboard shortcuts?"
          }
        },
        "titleHeader": "text editor"
      },
      "2": {
        "questions": {
          "1": {
            "content": "The transition from one status to another is done via an “action” that can be performed by a user with the required rights. Certain operations can only be executed if a type of contributor is indicated (example: changing an article from “In writing” status to “To be proofread” status requires that a proofreader be appointed).",
            "question": "How do statuses work and how do I switch from one to another?"
          },
          "2": {
            "content": "Here is the list of the different statuses that an article can take. Each of them has its own color:",
            "question": "What are the different statuses of an article?"
          },
          "3": {
            "content0": "<p>When an article changes to 'Review' status, if there is only one reviewer, it will be automatically notified by email.</p><p>If there are multiple reviewers, it is the author's responsibility to notify the first reviewer. This by simply clicking on the name of the reviewer present above the title of the article.</p>",
            "content1": "<img src='changelog/EL-556-3.png'>",
            "content2": "<p>When you are the proofreader of a paper, you have three options by clicking on your name, 'I start the proofreading' to immediately lock the paper during your proofreading, 'Accept' and 'To be reworked' to give your opinion on proofreading. After that, it's up to you to notify the next reviewer or send the paper to the author or to the SRs depending on the status of the different reviews.</p><p>A small eye may appear next to the name of a reviewer, if it is at the same time making changes to the article.</p>",
            "content3": "<img src='changelog/EL-556-2.png'><img src='changelog/EL-556-1.png'>",
            "content4": "<p>The review statuses are automatically reset to zero with each new review.</p>",
            "question": "How proofreading works ?"
          }
        },
        "titleHeader": "How the workflow works"
      },
      "3": {
        "questions": {
          "1": {
            "content0": "<p>When you put several criteria on the same category, we search for the presence of at least one of the criteria.</p><p>If you want to search for all the criteria at the same time, you must add each criterion on a separate line using the '+' button.</p><p>An explanation of the search is available when hovering over the search button.</p><p><u>Example:</u> </p><p>If you want to do a search with the name Sarkozy <b>OR</b> Hollande, you must put these two criteria on the same line.</p><p>If you want to do a search with the name Sarkozy <b>AND</b> Holland, you must put these two criteria on two different lines.</p>",
            "content1": "<img src='changelog/EL-615.png'>",
            "question": "How the search works"
          }
        },
        "titleHeader": "How Search Works"
      },
      "4": {
        "questions": {
          "1": {
            "content": "<p>Nouvelle vue dans Elcano, le planning des formats longs permet :</p><ul><li>de voir, selon les publications, ces sujets sur six mois.</li><li>d'apporter facilement des modifications à ce planning (ajouter plusieurs articles d'un coup, en déplacer plusieurs d'un coup).</li><li>de détecter les conflits d'agenda.</li></ul><p class='help-error'>Il vaut mieux créer et gérer les formats longs depuis cette interface : elle permet de créer des séries et de les déplacer de façon groupée, tout en recalculant les deadlines. Modifier les dates depuis les paramètres reste possible, mais, dans ce cas, il n'y a pas d'impact sur les autres formats du même type.</p><p>Les formats longs sont les suivants :</p><ul><li>LL : Enquêtes, Entourages</li><li>AI : Enquêtes, Entourages</li><li>IO : Maître-espion, Spy Way of Life</li></ul>",
            "question": "Introduction"
          },
          "2": {
            "content": "<p>On accède au planning via le menu d'Elcano. </p><img src='changelog/EL-693-1.png'><p>Les sujets y apparaissent tous, dans leur langue de création. </p><p>On n'y voit que les articles programmés les jours normaux de parution d'un format long (ex : si AI fait paraître une enquête un jeudi, on ne la voit pas ici).</p><p>Tout le monde peut le consulter et seuls les rédacteurs de la publication peuvent créer un article.</p><p>Seuls les responsables des formats longs peuvent y intervenir pour créer une série d'articles ou modifier des dates de parution.</p>",
            "question": "Accéder au planning"
          },
          "3": {
            "content": "<p>Ce planning introduit la notion de deadline. Elle est créée par défaut en fonction des formats. </p><p>Elle peut être modifiée. Elle est indicative.</p><img src='changelog/EL-693-2.png'><img src='changelog/EL-693-3.png'><p>Quand la deadline est proche ou passée, la couleur de l'article change sur la page d'accueil du rédacteur concerné.</p><img src='changelog/EL-693-4.png'><p>Une alerte apparaît si deux deadlines sont trop proches.</p><img src='changelog/EL-693-5.png'><p class='help-error'>Attention, si un format long est mis au frigo, la deadline est désactivée. Lorsque l'article est remis dans le circuit, il faut en recréer une. Ce n'est pas automatisable.</p>",
            "question": "Fixer une deadline"
          },
          "4": {
            "content": "<p>Lorsqu'on crée un article dans cette interface, seule les dates possibles de parution sont proposées.</p><p>Dans l'exemple ci-dessous, il n'est pas possible de créer un entourage le 8 mars puisqu'il y en a déjà un.</p><img src='changelog/EL-693-6.png'><p>Si une édition a été bloquée par le pôle édition (jour férié par exemple), la date n'apparaît pas dans les propositions. </p>",
            "question": "Créer un article"
          },
          "5": {
            "content": "<p>Cette fonctionnalité est réservée aux responsables des formats longs.</p><p>Il est possible de créer en une action une série d'articles du même format. A chaque fois qu'on clique sur Ajouter un article à la série, un nom est ajouté à la série, dans l'ordre alphabétique des prénoms. Ce nom peut être modifié avant la création de la série. </p><img src='changelog/EL-693-7.png'><p>On peut créer ces articles dans la langue qu'on veut.</p><img src='changelog/EL-693-8.png'><p>Par défaut, les articles en série sont créés dans une seule rubrique, qui pourra être modifiée par la suite.</p><ul><li>LL: Action publique</li><li>AI: Le continent</li><li>IO: Renseignement d'État</li></ul><p>Si on veut indiquer qu'une date est à pourvoir, on peut créer l'article avec n'importe quel auteur et retirer ensuite son nom. La case devient alors rouge.</p><img src='changelog/EL-693-9.png'>",
            "question": "Créer une série d'articles"
          },
          "6": {
            "content": "<p>Ne procéder aux changements de date que dans ce module. Rien de vous empêche de le faire depuis l'article mais sachez que, dans ce cas, il n'y aura pas de modification en série.</p><img src='changelog/EL-693-10.png'><p>Si vous voulez changer une date de parution en même temps que la deadline, utiliser le clic droit sur la carte du format concerné. Si vous voulez faire une manipulation plus fine, passez par les paramètres de l'article. Ici, si vous modifiez une date, la deadline ne bouge pas, et inversement.</p><p>Vous pouvez alors :</p><ul><li>changer la date de parution, le panneau suivant vous propose les dates de ce format. Si la date de destination est occupée, l'article qui y est est repoussé ou avancé, selon les cas, et les séries sont affectées. Ex : Si on repousse un article 1 de deux semaines, l'article 2 qui était présent la semaine suivante prend la place de 1 et l'article 3 deux semaines plus tard prend la place de 2.</li><li>repousser la série d'une semaine* dans le futur </li><li>avancer la série d'une semaine* dans le passé </li><li>supprimer l'article et avancer la série des articles qui suivent </li><li>mettre l'article au frigo (enlever la date de parution) et avancer la série des articles qui suivent.</li></ul><p>*ou d'un mois, selon le format</p><img src='changelog/EL-693-11.png'><p>S'il y a deux mêmes formats à la même date, on peut confirmer la date de l'un des deux, ça repousse l'autre et la série.</p>",
            "question": "Modifier la date d'un format long"
          },
          "7": {
            "content": "<p>Pour insérer un format long dans une liste existante, il faut d'abord créer la place, en repoussant d'une semaine tous les articles qui vont suivre. Puis créer le format long à la date libérée. Le panneau de création d'article la propose en premier.</p>",
            "question": "Insérer un format long dans une liste existante"
          }
        },
        "titleHeader": "Planning des formats longs"
      }
    },
    "states": {
      "mainLanguage": "Original version",
      "translation": "Translation"
    }
  },
  "history": {
    "labels": {
      "advanced": "advanced mode",
      "allModifications": "All changes",
      "btnOpen": "Open",
      "modificationsByUser": "Changes by user",
      "modified": "Modified article",
      "needToBeImported": "This article needs to be re-imported.",
      "origin": "Origin",
      "params": "Settings",
      "subtype": "Sub type",
      "type": "Kind"
    }
  },
  "home": {
    "actions": {
      "editArticle": "Edit article",
      "editFreelancers": "Edit Freelancers",
      "previewArticle": "Preview the article",
      "printArticlesTooltip": "Print articles",
      "setFreelanceAmount": "Enter the amount of the freelance"
    },
    "articlesAwaitingEditorCorrection": "Waiting for SR correction",
    "articlesInEditorCorrection": "SR correction",
    "articlesInErrorEditor": "Articles in error",
    "articlesLongFormat": "Long format articles",
    "articlesPublishedWithoutInterests": "Articles published without interest or enrichment",
    "freelanceConsumption": "Consumption of freelance",
    "freelanceConsumptionBudget": "Budget: {budget} €",
    "freelancersWithoutAmount": "Freelancer to fill",
    "freelancersWithoutId": "Freelancers without ID",
    "myArticles": "My articles",
    "myCorrections": "My corrections",
    "myFreelancersWithoutAmount": "Freelancer without amount",
    "myNextLongFormat": "My next long formats",
    "myOnlineArticles": "My online articles",
    "myReviews": "My reviews",
    "myTranslations": "My translations",
    "myTranslationsCompleted": "My latest completed translations",
    "myTranslationsToReview": "My translations to proofread",
    "onlineArticlesNotInImportedStatus": "Online articles but not in status 'imported'",
    "translationsForNextEditionToReview": "Translations for the next edition to review"
  },
  "import": {
    "errors": {
      "failed": "Failure",
      "previewFailed": "Error requesting preview of article {0}. See import logs."
    },
    "fields": {
      "articleId": "Article ID",
      "articleIds": "Articles IDs (comma separated)",
      "endDate": "End",
      "eventId": "Event ID",
      "informations": "Information",
      "onlyUpdatedArticles": "Only updated articles",
      "publications": "Publications",
      "startDate": "Beginning"
    },
    "labels": {
      "btnExport": "Export",
      "btnImport": "Import",
      "btnInfos": "Information retrieval",
      "btnPost": "Post",
      "btnRefresh": "Refresh",
      "btnReset": "Reset",
      "btnRetry": "Retry"
    },
    "messages": {
      "exportArticles": "Export of current articles",
      "importArticles": "Import of current articles",
      "importArticlesSettings": "Import in progress",
      "importAssociatedArticles": "Import associated articles",
      "importIllustrations": "Import in progress",
      "loadingInfosSuccess": "Loading info OK",
      "retryImport": "Retry OK"
    },
    "tabs": {
      "articles": "Articles",
      "articlesIds": "Articles by Ids",
      "associatedArticles": "Enrichments",
      "exportArticle": "Article export",
      "illustrations": "Medias",
      "sections": "Sections",
      "settings": "Article Parameters",
      "suivi": "Import tracking",
      "users": "Users"
    },
    "titles": {
      "exportArticle": "Export an article",
      "importArticle": "Import articles",
      "importArticleSettings": "Import interest",
      "importAssociatedArticles": "Import associated articles",
      "importIllustrations": "Import illustrations",
      "importTrackEvent": "Import Tracking",
      "informations": "Information"
    }
  },
  "languages": {
    "all": "All languages",
    "en-EN": "English",
    "fields": {
      "add": "New language to add"
    },
    "fr-FR": "French",
    "labels": {
      "btnCancel": "Cancel",
      "btnOk": "OK"
    }
  },
  "liens": {
    "About": "About",
    "Admin": "Admin",
    "ArticleSettings": "Article settings",
    "Articles": "Articles",
    "Countries": "Countries",
    "CustomDates": "Full view",
    "Drafts": "Drafts",
    "Editions": "View by edition",
    "EditionsLong": "Daily editions",
    "ExportFreelance": "Export freelance",
    "ExportTranslation": "Export translations",
    "Feuilletons": "Serial",
    "FeuilletonsLong": "List of serials",
    "Formats": "Formats",
    "Freelances": "Freelance management",
    "Fridge": "Fridge",
    "FridgeLong": "Articles without publication date (fridge)",
    "Help": "Help",
    "Home": "Dashboard",
    "Import": "Import",
    "LongFormatPlanning": "Long format planning",
    "Mails": "Emails",
    "Media": "Media",
    "Planning": "Planning",
    "Profiles": "List of profiles",
    "Publications": "Publications list",
    "Search": "Search",
    "SearchAndReplace": "Keywords cleanse",
    "Sections": "Sections",
    "Settings": "Personal settings",
    "Users": "Users list"
  },
  "longFormat": {
    "actions": {
      "choseDate": "Change release date",
      "confirmArticleAtDate": "Maintain the date and push back the series",
      "createArticle": "Create an article",
      "createSeries": "Create a series",
      "deleteArticle": "Delete article and advance the series ←",
      "freezeArticle": "Send article to the fridge and advance the series ←",
      "pullSeries": "Advance the series ←",
      "pushSeries": "Push back the series →"
    },
    "deadline": "delivered {date}",
    "dialogs": {
      "confirmArticleText": "Do you want to keep article '{title}' as of {date} and push back the rest of the series?",
      "confirmArticleTitle": "Maintain article date",
      "deleteArticleText": "Do you want to delete the article and move forward with the series?",
      "deleteArticleTitle": "Delete article",
      "freezeArticleText": "Do you want to send the article to the fridge and advance the series?",
      "freezeArticleTitle": "Send the article to the fridge",
      "messages": {
        "addArticleOnSeries": "Add an article to the series",
        "createSeries": "Create the series",
        "createSeriesTitle": "Creating a series: {format}",
        "errorArticleMissingAtDate": "There is one {format} missing on {dates}.|There are several {formats} missing: {dates}.",
        "errorDeadlineTooClose": "The following author must render two strong formats in too short a time: {userNames}.| The following authors must render two strong formats in too short a time: {userNames}.",
        "errorTwoArticlesAtSameDate": "There are two {formats} on the date of {dates}.|There are two {formats} on the following dates: {dates}.",
        "labelCycleMonthly": "Invalid value | Every first {day} of the month | Every second {day} of the month | Every third {day} of the month | Every fourth {day} of the month",
        "labelCycleWeekly": "Every {day}",
        "labelFormatSimple": "{format} of {day}",
        "seriesStartDate": "First date of the series",
        "warningAskToManager": "Contact those responsible for long formats"
      },
      "pullSeriesText": "Do you want to advance the series of articles?",
      "pullSeriesTitle": "Advance the series",
      "pushSeriesText": "Do you want to postpone the series of articles?",
      "pushSeriesTitle": "Push back the series"
    },
    "publishedOn": "Published on {date}, "
  },
  "mailTemplate": {
    "fields": {
      "bodyEn": "Email body in English",
      "bodyFr": "Email body in French",
      "label": "Label",
      "subjectEn": "English subject",
      "subjectFr": "French subject"
    },
    "labels": {
      "btnEdit": "Save"
    }
  },
  "mails": {
    "dialogs": {
      "buttons": {
        "btnGenerateEmail": "Generate email(s)",
        "btnModify": "Modify",
        "btnNext": "Next",
        "btnPlanify": "Planify",
        "btnQuit": "Quit"
      },
      "errors": {
        "noMailAvailable": "Aucun type de mail disponible pour ce contenu."
      },
      "labels": {
        "mailSubject": "Subject of the mail(s)",
        "planifyDate": "Date and hour of planification",
        "subjectBreakingNews": "Breaking news - "
      },
      "messages": {
        "generateEmail": "Email will be generated. Verify it before continue.",
        "modifiedSubject": "The subject of the mail has been modified.",
        "planifiedEmail": "Email has been planified for {nbContacts} estimated contacts.",
        "warningNotAlertAndNotVdp": "Warning, this article is neither an alert nor a 'Just Released'.",
        "warningNotPublished": "Be careful, this article is not published yet !"
      },
      "titles": {
        "generateEmail": "Generate email",
        "modifySubject": "Modify the subject of the mail",
        "planifyEmail": "Planify email"
      }
    },
    "types": {
      "Alert_abo": "Alert for subscribers",
      "Alert_prosp": "Alert for prospects",
      "Article_abo": "Article for subscribers",
      "Dossier_abo": "Dossier for subscribers",
      "Dossier_prosp": "Dossier for prospects",
      "Entourage_abo": "Entourage for subscribers",
      "Entourage_prosp": "Entourage for prospects",
      "Feuilleton_abo": "Running story for subscribers",
      "Feuilleton_prosp": "Running story for prospects",
      "GrandeEnquete_abo": "In Focus for subscribers",
      "GrandeEnquete_prosp": "In Focus for prospects",
      "Insiders_abo": "Insiders/Inner circle for subscribers",
      "Insiders_prosp": "Insiders/Inner circle for prospects",
      "JustReleased_abo": "Just released for subscribers",
      "JustReleased_prosp": "Just released for prospects"
    }
  },
  "media": {
    "actions": {
      "btnSave": "Save"
    },
    "exceptions": {
      "NotIdenticalName": "The name of the image is not the same as the replaced one."
    },
    "fields": {
      "copyright": "Copyright",
      "legend": "Legend"
    },
    "labels": {
      "ChapoAn": "Standfirst EN",
      "ChapoFr": "Standfirst FR",
      "CopyrightsEn": "Copyrights EN",
      "CopyrightsFr": "Copyrights FR",
      "LegendeEn": "Legend EN",
      "LegendeFr": "Legend FR",
      "LinkedArticles": "Linked articles :",
      "NoMedias": "There are no illustrations attached to this article."
    },
    "notifications": {
      "errorOnSave": "Error saving file {fileName}",
      "needReimport": "If you change the caption, please import the article again.",
      "onSave": "Modifications have been saved",
      "replaceImageError": "Error during the replacement process. Please contact the IT staff.",
      "replaceImageSuccess": "The picture has been replaced."
    },
    "select": {
      "error": "The media does not include metadata."
    }
  },
  "messageDialog": {
    "actions": {
      "ApiUnavailableOkButton": "Try again",
      "updateElcanoOkButton": "Refresh"
    },
    "content": {
      "ApiUnavailable": "The Elcano API is not available, please check your internet connection.",
      "updateElcano": "A new version of Elcano is available. Please refresh the page to update the app."
    },
    "title": {
      "ApiUnavailable": "Elcano API not available",
      "updateElcano": "Elcano Update"
    }
  },
  "messages": {
    "loading": "Loading...",
    "notFound": "This page does not exist",
    "quit": "Please note that you are leaving the page without saving your changes. Are you sure you want to leave the page?",
    "quitTitle": "Unsaved changes",
    "waiting": "Please wait...",
    "welcome": "Welcome to Indigo Publications <br/>editorial workflow"
  },
  "modificationTypes": {
    "Base": "Base",
    "EnglishContent": "English content",
    "FrenchContent": "French content",
    "None": "None"
  },
  "planning": {
    "actions": {
      "changeDate": "Move article",
      "choseDate": "Choose date",
      "createTranslation": "Create the translation",
      "noAction": "No action available"
    }
  },
  "preview": {
    "labels": {
      "btnPrint": "Print",
      "withMarkers": "With highlights",
      "withMedias": "With pictures",
      "withSuggestions": "With suggestions"
    }
  },
  "profile": {
    "dialogs": {
      "messages": {
        "delete": "Are you sure you want to delete the profile?"
      },
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Unable to create profile",
      "delete": "Unable to delete profile",
      "notFound": "The profile was not found or does not exist",
      "update": "Unable to update profile"
    },
    "fields": {
      "id": "Profile ID",
      "initialConfig": "Readonly Built-in Profile",
      "labelEn": "Profile title in English",
      "labelFr": "Profile title in French",
      "shortId": "id",
      "shortLabelEn": "English name",
      "shortLabelFr": "French name",
      "shortSearch": "Research"
    },
    "labels": {
      "btnAddAssignment": "Add an assignment",
      "btnDelete": "Delete profile",
      "btnEdit": "Save profile",
      "btnNew": "Create a new profile"
    },
    "messages": {
      "created": "The new profile has been successfully created",
      "deleted": "The profile has been successfully deleted",
      "updated": "The profile has been successfully updated"
    },
    "titles": {
      "assignments": "Assignments"
    }
  },
  "publication": {
    "dialogs": {
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Unable to create publication",
      "notFound": "The publication was not found or does not exist",
      "update": "Unable to update publication"
    },
    "fields": {
      "active": "Publication active",
      "id": "Publication ID",
      "name": "Name",
      "publications": "Publications",
      "shortActive": "Active",
      "shortId": "Publication Id",
      "shortName": "Name",
      "shortSearch": "Research"
    },
    "labels": {
      "btnCancel": "Cancel",
      "btnCreate": "Create publication",
      "btnEdit": "Save publication",
      "btnNew": "Create a new publication",
      "btnOk": "OK",
      "lblTitle": "Publication list",
      "shortBtnNew": "Create"
    },
    "messages": {
      "created": "The new publication has been successfully created",
      "updated": "The publication has been successfully updated."
    }
  },
  "reviewerMessage": {
    "btnCancel": "Cancel",
    "btnOk": "Notify",
    "message": "Message for the reviewer",
    "title": "Notify reviewer"
  },
  "right": {
    "dialogs": {
      "messages": {
        "delete": "Are you sure you want to delete the right?"
      },
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Failed to create right",
      "delete": "Unable to delete right",
      "notFound": "The right was not found or does not exist",
      "update": "Failed to update right"
    },
    "fields": {
      "shortId": "id",
      "shortLabelEn": "English name",
      "shortLabelFr": "French name",
      "shortRights": "Rights",
      "shortSearch": "Research"
    },
    "labels": {
      "btnCancel": "Cancel",
      "btnDelete": "Delete right",
      "btnNew": "Create a new right",
      "btnOk": "OK"
    },
    "messages": {
      "created": "The new right has been created",
      "deleted": "The right has been deleted",
      "updated": "The right has been updated"
    }
  },
  "rightGroup": {
    "dialogs": {
      "messages": {
        "delete": "Are you sure you want to delete the group?"
      },
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Unable to create group",
      "delete": "Unable to delete group",
      "notFound": "The rights group was not found or does not exist",
      "update": "Unable to update group"
    },
    "fields": {
      "initialConfig": "Built-in right group",
      "labelEn": "Group title in English",
      "labelFr": "Group title in French",
      "shortId": "id",
      "shortLabelEn": "English name",
      "shortLabelFr": "French name",
      "shortSearch": "Research"
    },
    "labels": {
      "btnDelete": "Delete group",
      "btnEdit": "Save group",
      "btnNew": "Create a new group"
    },
    "messages": {
      "created": "The new group has been created",
      "deleted": "The group has been successfully deleted",
      "updated": "The group has been successfully updated"
    }
  },
  "rules": {
    "invalidCharacter": "Invalid character",
    "labels": {
      "btnStopShowing": "Hide"
    },
    "maxlength": "maximum length of {0} characters",
    "required": "The field is required"
  },
  "search": {
    "action": {
      "closeSelectedArticles": "Close",
      "copyLinkArticleTooltip": "Copy the link '{link}' to the clipboard",
      "createTranslation": "Create translation",
      "openWebsiteLinkArticleTooltip": "Open this article on our Website",
      "printArticle": "Print this article",
      "printListArticle": "Preview the list",
      "searching": "Searching...",
      "selectArticle": "Select",
      "unselectArticle": "Unselect",
      "validateSelectedArticles": "Validate selected articles"
    },
    "errors": {
      "noResult": "Sorry, no match for your request",
      "serverError": "Unable to get the search result, please check your internet connexion and/or contact the IT departement"
    },
    "fields": {
      "and": "and",
      "or": "or",
      "publicationInfo": "{publication} from {date}",
      "searchResult": "results for your search query"
    },
    "labels": {
      "btnSearch": "Search",
      "searchCaption": "Add your search criteria here",
      "searchInfoSpinner": "Searching...",
      "tgOnlyEnglish": "English articles",
      "tgOnlyFrench": "In French",
      "tgOnlyImported": "“Imported” articles",
      "tgOnlyMyArticle": "My work",
      "tgOnlyShowTitleStandFirst": "Show title/standfirst only",
      "tgOnlySpotlights": "Running story",
      "tgOnlyTitleStandFirst": "Search on title/standfirst",
      "tgTodayOnly": "Today's stories"
    },
    "type": {
      "cms": "CMS",
      "country": "Country",
      "format": "Format",
      "formatExcluded": "Excluded format",
      "from": "From",
      "idBackOffice": "Indigo Article ID",
      "idContentWorkflow": "Content ID",
      "idWorkflow": "Elcano Article ID",
      "interest": "Interest",
      "language": "Language",
      "publication": "Publication",
      "publishedAt": "Published at",
      "section": "Section",
      "state": "State",
      "text": "Text",
      "to": "To",
      "user": "User"
    }
  },
  "searchAndReplace": {
    "columns": {
      "actions": "Actions",
      "author": "Author",
      "createdAt": "Created at",
      "description": "Description",
      "totalImpactedArticles": "Impacted articles",
      "totalOccurences": "Occurences"
    },
    "labels": {
      "btnCreate": "Create",
      "btnProcessArticlesInError": "Reprocess articles in error",
      "btnProcessReport": "Process the report",
      "btnViewReport": "View",
      "country": "Countries",
      "endingDate": "Until",
      "language": "Language",
      "mainSection": "Main section",
      "noResults": "No results",
      "publications": "Publications",
      "replacedText": "Replaced by",
      "searchedText": "Searched text",
      "secondarySection": "Secondary section",
      "startingDate": "Starting date",
      "toggleToReplace": "To replace"
    },
    "status": {
      "ANePasRemplacer": "Not to replace",
      "ARemplacer": "To replace",
      "EnAttenteExport": "Waiting for export",
      "Erreur": "Error",
      "Exporte": "Exported",
      "NonTraite": "Not processed",
      "Remplace": "Replaced"
    }
  },
  "section": {
    "dialogs": {
      "titles": {
        "confirm": "Confirmation"
      }
    },
    "errors": {
      "create": "Unable to create section",
      "notFound": "Section was not found or does not exist",
      "update": "Unable to update topic"
    },
    "fields": {
      "active": "Active section",
      "backgroundColor": "background color",
      "fontColor": "Text color",
      "idBackOffice": "ID Indigo",
      "inactive": "Show inactive topics",
      "labelEn": "Heading in English",
      "labelFr": "Heading in French",
      "main": "Main section",
      "parent": "Parent section",
      "publication": "Publication",
      "shortActive": "Active",
      "shortId": "id",
      "shortLabelEn": "English name",
      "shortLabelFr": "French name",
      "shortMain": "Main",
      "shortSearch": "Research",
      "shortWeight": "Ranking",
      "weight": "Weight"
    },
    "labels": {
      "btnAddPublication": "Add a publication",
      "btnCreate": "Create section",
      "btnEdit": "Save section",
      "btnNew": "Create a new section",
      "lblTitle": "Sections list",
      "shortBtnNew": "Create"
    },
    "messages": {
      "created": "The new section has been created",
      "updated": "The section has been successfully updated."
    }
  },
  "setting": {
    "fields": {
      "cache": "Cache",
      "darkMode": "Dark mode",
      "darkModeInfo": "Please note, dark mode is in preview and cannot be subject to bugs.",
      "language": "Choice of application language",
      "listArticle": "Article list view",
      "refreshToken": "Refresh my authentication token"
    },
    "headers": {
      "application": "Application",
      "article": "Article",
      "profile": "Profile"
    },
    "labels": {
      "btnClearCache": "Delete cache",
      "btnRefreshToken": "Refresh",
      "btnResetListeArticleSettings": "Delete my configuration"
    }
  },
  "titles": {
    "About": "About",
    "ArticleSettings": "Article Parameters",
    "Articles": "Articles page",
    "Assignments": "User assignments page",
    "Countries": "Country page",
    "CreateArticle": "Creation an article",
    "CreateCountry": "Creation of a country",
    "CreateFormat": "Creation of a new format",
    "CreatePublication": "Creation of a publication",
    "CreateSection": "Creation of a section",
    "Drafts": "Drafts page",
    "EditArticle": "Editing an article",
    "EditCountry": "Editing a country",
    "EditFormat": "Editing a format",
    "EditPublication": "Editing a publication",
    "EditSection": "Editing a section",
    "EditUser": "Editing a user",
    "ExportFreelance": "Export freelance",
    "ExportTranslation": "Export translations",
    "Formats": "Formats page",
    "FreelanceBudget": "Freelance budgets",
    "FreelanceConsumption": "Consumption of freelance",
    "FreelanceManagement": "Freelance management",
    "Help": "Need help ?",
    "Home": "Home page",
    "Import": "Import",
    "ListReport": "Liste des rapports",
    "LongFormatPlanning": "Long format planning",
    "MailTemplate": "Emails",
    "Media": "Media",
    "NotFound": "Page not found",
    "Planning": "Planning",
    "Profiles": "User Profiles Page",
    "Publications": "Publications page",
    "Report": "Rapport",
    "RightGroups": "Right groups page",
    "Rights": "User rights page",
    "Search": "Search",
    "SearchAndReplace": "Keywords cleanse",
    "Sections": "Sections page",
    "Settings": "App settings",
    "Users": "Users page",
    "ttt": "Tic Tac Toe"
  },
  "triggerMessage": {
    "btnCancel": "Cancel",
    "btnOk": "OK",
    "message": "Message accompanying the notification",
    "title": "Status change"
  },
  "user": {
    "actions": {
      "create": "Create a new user",
      "save": "Save"
    },
    "deleted": "Deleted user",
    "errors": {
      "addProfile": "Unable to add profile for user",
      "insert": "User could not be created",
      "notFound": "User not found or does not exist",
      "removeProfile": "Unable to remove a profile for the user",
      "update": "User could not be updated"
    },
    "fields": {
      "addDate": "Created on",
      "deleted": "Deleted",
      "disabled": "Disabled",
      "enabled": "Enabled",
      "firstName": "First name",
      "freelancerWithoutId": "Freelancer without ID",
      "idBackOffice": "ID Access",
      "lastName": "Last name",
      "login": "Login",
      "loginHint": "Format: FirstName.LASTNAME",
      "profilesUsed": "List of profiles used",
      "shortEmail": "Email",
      "shortFirstname": "First",
      "shortId": "id",
      "shortIdBackOffice": "ID Access",
      "shortLastname": "Last",
      "shortLogin": "Login",
      "shortName": "Name",
      "shortProfiles": "Profiles",
      "shortSearch": "Search",
      "shortSignature": "Signature"
    },
    "shortDeleted": " (DEL)",
    "shortDisabled": " (DISAB)",
    "tabs": {
      "assignments": "Assignments",
      "profiles": "Profiles",
      "rightGroups": "Right groups",
      "rights": "Rights",
      "users": "Users"
    }
  }
}
